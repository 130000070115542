import {formatName} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

export const getFormattedMessage = ({businessName} = {}, user = {}) =>
  `Please direct your financing related questions to RenoFi and all other project related questions to us at ${businessName}.


We look forward to working with you and RenoFi on your renovation project.

Thanks,

${formatName(user)}
${businessName}
${user?.email}`;

export const hasMissingProductValues = ({propertyZipCode, renovationCost}) =>
  !propertyZipCode || !renovationCost;

export const trackShareableLinkCopied = (estimate) => {
  sendEvent('Contractors/Shareable-Link-Copied', {
    clientEmail: estimate.email,
    scenarioId: estimate.scenarioId,
  });
};
