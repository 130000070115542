import {useState} from 'react';

import {Flex, Text} from 'rebass';
import {head, remove, noop, isArray} from 'lodash';

import {gray} from '@renofi/utils';

import DataInputContainer from './DataInputContainer';
import {Option} from './styled';

const SelectInput = ({
  multiselect,
  label,
  options = [],
  value,
  onChange = noop,
  children,
}) => {
  const [selections, setSelections] = useState(() => {
    if (!(value || head(value))) {
      return [];
    }

    return value;
  });

  const onClick = (value) => {
    if (
      (isArray(selections) && selections.includes(value)) ||
      (!isArray(selections) && selections === value)
    ) {
      const updatedSelections = remove(
        selections,
        (selection) => selection !== value,
      );
      setSelections(updatedSelections);

      if (multiselect) {
        onChange(updatedSelections);
      } else {
        onChange(head(updatedSelections));
      }
      return;
    }

    if (multiselect) {
      const updatedSelections = [...selections, value];
      setSelections(updatedSelections);
      onChange(updatedSelections);
      return;
    }
    setSelections([value]);
    onChange(value);
  };

  return (
    <DataInputContainer label={label}>
      <Flex flexWrap="wrap" css={{gap: 8}}>
        {options.map(({label, value}) => (
          <Option
            key={label}
            selected={
              isArray(selections)
                ? selections.includes(value)
                : selections === value
            }
            onClick={() => onClick(value)}>
            <Text color={gray}>{label}</Text>
          </Option>
        ))}
      </Flex>
      {children}
    </DataInputContainer>
  );
};

export default SelectInput;
