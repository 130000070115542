import {makeVar, useReactiveVar} from '@apollo/client';

const showFinancingReferralVar = makeVar(false);

const useShowFinancingReferral = () => {
  const showFinancingReferral = useReactiveVar(showFinancingReferralVar);

  const setShowFinancingReferral = (show) => {
    showFinancingReferralVar(show);
  };

  return {
    setShowFinancingReferral,
    showFinancingReferral,
  };
};

export default useShowFinancingReferral;
