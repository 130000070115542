import {sortBy} from 'lodash';

import {
  darkGreen,
  darkRed,
  darkYellow,
  fadeGreen,
  fadeRed,
  fadeYellow,
  formatAddress,
  formatName,
} from '@renofi/utils';

export const getBorrowerAddress = ({
  borrowersAndPropertyInformation = {},
} = {}) => {
  const {
    propertyCity,
    propertyState,
    propertyStreetAddressOne,
    propertyZipCode,
  } = borrowersAndPropertyInformation;
  return formatAddress({
    city: propertyCity,
    streetAddress: propertyStreetAddressOne,
    state: propertyState,
    zipCode: propertyZipCode,
  });
};

export const getProjectName = ({borrowersAndPropertyInformation} = {}) => {
  const {
    coborrowerFirstName,
    coborrowerIsPresent,
    coborrowerLastName,
    firstName,
    lastName,
  } = borrowersAndPropertyInformation || {};
  const borrowerName = formatName(borrowersAndPropertyInformation);

  if (!coborrowerIsPresent) {
    return borrowerName;
  }

  const lastNamesMatch =
    coborrowerLastName?.toLowerCase() === lastName?.toLowerCase();

  return lastNamesMatch
    ? `${firstName} and ${coborrowerFirstName} ${lastName}`
    : `${borrowerName} and ${formatName({
        firstName: coborrowerFirstName,
        lastName: coborrowerLastName,
      })}`;
};

export const getProjectDocuments = (docs = []) => {
  return sortBy(
    [...docs].filter(({source}) => source === 'contractor'),
    'createdAt',
  );
};

export const getFeasibilityStatus = (status, documents = []) => {
  const hasUnclassified = documents.some(
    ({documentType}) => documentType === 'unclassified',
  );

  return status === 'new' && Boolean(documents.length) && !hasUnclassified
    ? 'submitted'
    : status;
};

export const getQuestionnairePathFromStep = (step) => {
  switch (step) {
    case 1:
      return '/contact-details';
    case 2:
      return '/company-details';
    case 3:
      return '/eligibility';
    case 4:
      return '/experience-1';
    case 5:
      return '/references';
    case 6:
      return '/sign';
    default:
      return '';
  }
};

export const getCddReviewStatusVariant = ({
  contractor = {},
  gcddReview = {},
} = {}) => {
  const {assignedAt, cancelledAt, completedAt, overallScore} = gcddReview || {};
  const {latestQuestionnaire} = contractor || {};
  const {returnedAt, submittedStep} = latestQuestionnaire || {};
  const isQuestionnaireReturned = Boolean(returnedAt);

  const isCompleted = Boolean(completedAt && overallScore === 'pass');
  const isCancelled = Boolean(cancelledAt || overallScore === 'consider');
  const isIncompleted = Boolean(completedAt && overallScore === 'fail');
  const isAssigned = Boolean(assignedAt);

  switch (true) {
    case isCompleted:
      return [darkGreen, 'white', 'completed', completedAt];
    case isIncompleted:
      return [fadeRed, darkRed, 'incomplete', completedAt];
    case isCancelled:
      return [fadeGreen, darkGreen, 'cancelled', cancelledAt || completedAt];
    case isAssigned:
      return [fadeGreen, darkGreen, 'in_review', assignedAt];
    case isQuestionnaireReturned:
      return [fadeGreen, darkGreen, 'submitted', returnedAt];
    case submittedStep > 0:
      return [fadeYellow, darkYellow, 'started', null];
    default:
      return [fadeYellow, darkYellow, 'not_started', null];
  }
};
