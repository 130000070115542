import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {SUBMIT_CONTRACTOR_PROJECT_DOCUMENT} from '../mutations';

export default function useSubmitContractorProjectDocument({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [submitContractorProjectDocument, {data, loading, error}] = useMutation(
    SUBMIT_CONTRACTOR_PROJECT_DOCUMENT,
    {onCompleted, refetchQueries, options},
  );
  const response = data?.submitContractorProjectDocument;
  return {response, loading, submitContractorProjectDocument, error};
}
