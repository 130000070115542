import {useMemo, useState} from 'react';

import {Flex, Text} from 'rebass';

import {useProductMatchesForEstimate} from '@renofi/api';
import {
  Alert,
  InfoIcon,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@renofi/components';
import {
  useScreenSize,
  blueGray,
  darkBlue,
  gray,
  lightGray,
  formatIsoDate,
  formatAMPM,
} from '@renofi/utils';
import {useFlags} from '@renofi/utils/src/feature-flags';

import {Layout} from '../../../components';

import {TABS} from './constants';
import {hasMissingProductValues} from './utils';

const ShareEstimate = ({estimate, estimateCompleted}) => {
  const featureFlags = useFlags();
  const {isMobile} = useScreenSize();
  const {productMatches} = useProductMatchesForEstimate({
    skip: hasMissingProductValues(estimate),
    variables: {
      zipCode: estimate?.propertyZipCode,
      loanAmount: estimate?.renovationCost,
      estimatedHomeValue: estimate?.estimatedCurrentPropertyValue,
    },
  });

  const dashboardLink = estimate?.scenarioDashboardLink;
  const [productMatch] = productMatches || [];

  const activeTabKeys = useMemo(() => {
    return Object.keys(TABS).reduce((arr, key) => {
      const {flag} = TABS[key];
      if (!flag) {
        return arr.concat(key);
      }

      const remoteFlag = featureFlags.find((f) => f.name === flag);
      return remoteFlag?.enabled ? arr.concat(key) : arr;
    }, []);
  }, [JSON.stringify(featureFlags)]);

  const [tab, setTab] = useState(activeTabKeys[0]);

  return (
    <Layout.Panel contentCss={{paddingBottom: 0}}>
      <Layout.PanelTitleV2>Share estimate</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper
        padding={['20px 12px 12px 12px', '20px 40px']}
        css={{
          borderTop: `1px solid ${blueGray}`,
        }}>
        {estimateCompleted && !estimate.estimateEmailLastSharedAt && (
          <Alert successIcon success css={{fontSize: [12, 14]}}>
            The estimate has been generated and you can now share it via SMS,
            email or alternatively you can download a PDF version. The estimate
            contains a link to the RenoFi financing page where your client can
            explore their financing options.
          </Alert>
        )}
        {estimate.estimateEmailLastSharedAt && (
          <Alert icon success css={{fontSize: [12, 14]}}>
            Last shared with {estimate.email} on{' '}
            {formatIsoDate(estimate.estimateEmailLastSharedAt, {
              separator: '.',
            })}{' '}
            {formatAMPM(estimate.estimateEmailLastSharedAt, {
              separator: '.',
              upper: true,
            })}
          </Alert>
        )}
      </Layout.PanelInnerWrapper>
      <Layout.PanelInnerWrapper>
        {estimateCompleted ? (
          <Tabs
            style={{marginTop: -8}}
            role="share_estimate_tabs"
            onSelect={(v) => {
              setTab(activeTabKeys[v]);
            }}
            selectedIndex={activeTabKeys.indexOf(tab)}>
            <Flex
              css={{gap: 8, borderBottom: `1px solid ${blueGray}`}}
              justifyContent={['space-between', 'flex-start']}
              px={[12, 40]}
              mt={0}>
              <TabList style={{borderBottom: 'none'}}>
                {activeTabKeys.map((key) => {
                  const active = key === tab;
                  const {icon: Icon, label} = TABS[key];
                  const color = active ? darkBlue : lightGray;
                  const isLabelArray = Array.isArray(label);
                  const displayValue = isLabelArray
                    ? label[isMobile ? 0 : 1]
                    : label;

                  return (
                    <Tab
                      style={{
                        display: 'flex',
                        padding: isMobile ? 8 : '16px 20px',
                        alignItems: 'center',
                        borderWidth: 4,
                        fontWeight: 'normal',
                      }}
                      key={key}>
                      <Icon color={color} />
                      <Text
                        ml="8px"
                        fontSize={[12, 16]}
                        display="inline"
                        color={color}>
                        {displayValue}
                      </Text>
                    </Tab>
                  );
                })}
              </TabList>
            </Flex>

            {activeTabKeys.map((key) => {
              const {component: TabComponent} = TABS[key];
              return (
                <TabPanel key={key}>
                  <TabComponent
                    dashboardLink={dashboardLink}
                    estimate={estimate}
                    productMatch={productMatch}
                  />
                </TabPanel>
              );
            })}
          </Tabs>
        ) : (
          <Flex padding={[50, 100]} flexDirection="column" alignItems="center">
            <InfoIcon color={gray} />
            <Text mt={12} color={gray} textAlign="center">
              Add the Client’s details above to enable sharing.
            </Text>
          </Flex>
        )}
      </Layout.PanelInnerWrapper>
    </Layout.Panel>
  );
};

export default ShareEstimate;
