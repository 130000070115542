import {Button, Flex} from 'rebass';

import {useLongButtonPress, blue, lightGray} from '@renofi/utils';

const SliderButton = ({children, onChange}) => {
  const {multiplier, longPressHandlers} = useLongButtonPress(onChange);

  return (
    <Button
      size={42}
      backgroundColor="#fff"
      css={{borderRadius: '100%', border: `1px solid ${lightGray}`}}
      {...longPressHandlers}
      onClick={() => onChange(multiplier)}>
      <Flex
        justifyContent="center"
        fontSize={24}
        textAlign="center"
        color={blue}>
        {children}
      </Flex>
    </Button>
  );
};

export default SliderButton;
