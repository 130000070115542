const EmailIcon = ({size = 20, color = '#212F49'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none">
    <mask
      id="mask0_1500_25052"
      style={{maskType: 'alpha'}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={size}
      height={size}>
      <rect width={size} height={size} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1500_25052)">
      <path
        d="M3.33329 16.6663C2.87496 16.6663 2.4826 16.5031 2.15621 16.1768C1.82982 15.8504 1.66663 15.458 1.66663 14.9997V4.99967C1.66663 4.54134 1.82982 4.14898 2.15621 3.82259C2.4826 3.4962 2.87496 3.33301 3.33329 3.33301H16.6666C17.125 3.33301 17.5173 3.4962 17.8437 3.82259C18.1701 4.14898 18.3333 4.54134 18.3333 4.99967V14.9997C18.3333 15.458 18.1701 15.8504 17.8437 16.1768C17.5173 16.5031 17.125 16.6663 16.6666 16.6663H3.33329ZM9.99996 10.833L3.33329 6.66634V14.9997H16.6666V6.66634L9.99996 10.833ZM9.99996 9.16634L16.6666 4.99967H3.33329L9.99996 9.16634ZM3.33329 6.66634V4.99967V14.9997V6.66634Z"
        fill={color}
      />
    </g>
  </svg>
);

export default EmailIcon;
