import {Flex, Image} from 'rebass';

import {LinkShareStepWrapper} from './styled';

const LinkShareStep = ({icons, children, css}) => (
  <LinkShareStepWrapper css={css}>
    <Flex css={{gap: 16}}>
      {icons.map((icon, i) => (
        <Image key={i} src={icon} size={64} />
      ))}
    </Flex>
    {children}
  </LinkShareStepWrapper>
);

export default LinkShareStep;
