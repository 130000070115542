import {useQuery} from '@apollo/client';

import {GET_DAAS_CLIENTS} from '../queries';

export default function useDaasClients({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useQuery(GET_DAAS_CLIENTS, {
    fetchPolicy,
    ...options,
  });
  const {collection = [], metadata} = data?.daasClients || {};

  return {collection, fetch, loading, metadata, refetch};
}
