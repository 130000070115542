import EmailIcon from '../EmailIcon';
import PDFIcon from '../PDFIcon';
import CopyIcon from '../CopyIcon';

import EmailTab from './EmailTab';
import PdfTab from './PdfTab';
import ShareableLinkTab from './ShareableLinkTab';

export const TABS = {
  link: {
    icon: CopyIcon,
    label: ['Copy Link', 'Copy Shareable Link'],
    component: ShareableLinkTab,
  },
  pdf: {
    icon: PDFIcon,
    label: ['Get PDF', 'Download PDF'],
    component: PdfTab,
  },
  email: {
    icon: EmailIcon,
    label: 'Email',
    component: EmailTab,
  },
};
