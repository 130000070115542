import {useHistory} from 'react-router-dom';

import {formatIsoDate, humanizeCase} from '@renofi/utils';

import {Tr, Td} from '../../components';
import {NO_STATUS} from '../constants';

const DesignRequestRow = ({daasClient}) => {
  const history = useHistory();
  return (
    <Tr
      data-testid={`daas_client_row_${daasClient.id}`}
      onClick={() => history.push(`/financing/client/${daasClient.id}/design`)}>
      <Td>
        {daasClient?.firstName
          ? `${daasClient?.firstName} ${daasClient?.lastName}`
          : '-'}
      </Td>
      <Td>{formatIsoDate(daasClient.createdAt)}</Td>
      <Td>{humanizeCase(daasClient.designStatus, NO_STATUS)}</Td>
      <Td>{formatIsoDate(daasClient.updatedAt)}</Td>
    </Tr>
  );
};

export default DesignRequestRow;
