import React, {useState} from 'react';

import {Flex, Link, Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {useValidateEmail} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {Button, Form, PasswordField} from '@renofi/components';
import {useLogin} from '@renofi/modules';
import {isRenofiPreview, useWatchApolloQueries} from '@renofi/utils';

import {EmailField} from '../../components';

import UserRegistration from './UserRegistration';
import ForgotPasswordModal from './ForgotPasswordModal';

const SHOW_USER_REG =
  process.env.NODE_ENV === 'development' || isRenofiPreview();

const AuthLogin = ({flexDirection = 'column'}) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showForgot, setShowForgot] = useState(false);
  const history = useHistory();
  const {loading: loadingLogin, login} = useLogin();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();

  const {queries} = useWatchApolloQueries();
  const isDisabled = !password || !email || invalidEmail;
  const isLoading =
    loadingLogin ||
    loadingValidateEmail ||
    queries.includes('authenticateByLoginToken');

  const onClickForgot = (e) => {
    e.preventDefault();
    setShowForgot(true);
    sendEvent('Contractors/Auth/ForgotPassword');
  };

  const onAuthSuccess = () => {
    history.push('/financing');
  };

  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    await login({email, password, onAuthSuccess});
  };

  return (
    <>
      <Form id="auth-login" onSubmit={onSubmit}>
        <Flex
          flexDirection={flexDirection}
          css={{gap: 16}}
          p={3}
          mb={3}
          alignItems="center">
          <EmailField
            id="email"
            wrapperCss={{width: '100%'}}
            onChange={(email) => {
              setEmail(email);
              setInvalidEmail(false);
            }}
            invalidEmail={invalidEmail}
            value={email}
          />

          <PasswordField
            id="password"
            label="Password"
            onChange={setPassword}
            placeholder="password"
            value={password}
            wrapperCss={{width: '100%'}}
          />
          <Button
            css={{width: '100%'}}
            disabled={isDisabled || isLoading}
            loading={isLoading}
            role="submit_auth_login"
            small>
            Submit
          </Button>
        </Flex>
      </Form>

      <Flex mb={3} justifyContent="center" textAlign="center">
        <Link role="forgot_password_link" href="" onClick={onClickForgot}>
          Forgot password?
          <br />
          Never set a password?
        </Link>
      </Flex>

      {showForgot ? (
        <ForgotPasswordModal onClose={() => setShowForgot(false)} />
      ) : null}

      {SHOW_USER_REG ? (
        <>
          <Text px={3}>Or, register for a FREE account...</Text>
          <UserRegistration />
        </>
      ) : null}
    </>
  );
};

export default AuthLogin;
