import styled from '@emotion/styled';
import {Button} from 'rebass';

import {lightGray, blue, fadeBlue} from '@renofi/utils';

export const Option = styled(Button)(({selected}) => ({
  padding: '12px 16px',
  fontSize: 16,
  color: {lightGray},
  lineHeight: '18px',
  backgroundColor: selected ? fadeBlue : '#fff',
  border: selected ? `2px solid ${blue}` : `1px solid ${lightGray}`,
  borderRadius: 4,
  '&:hover': {
    cursor: 'pointer',
  },
}));
