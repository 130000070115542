import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {COMPLETE_CONTRACTOR_REGISTRATION} from '../mutations';

export default function useCompleteContractorRegistration({
  awaitRefetchQueries = false,
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [completeContractorRegistration, {data, loading, error}] = useMutation(
    COMPLETE_CONTRACTOR_REGISTRATION,
    {
      awaitRefetchQueries,
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.completeContractorRegistration;

  return {completeContractorRegistration, response, loading, error};
}
