import {Flex, Text, Image} from 'rebass';

import {Modal, Button, renofiLogoDarkIconSrc} from '@renofi/components';

import {useMyAccount} from '../../api';
import Footer from '../../components/layout/Footer';
import estimatePreviewFooter from '../img/estimate-preview-footer.svg';

import EstimatePreviewBanner from './EstimatePreviewBanner';
import EstimateLoanOption from './EstimateLoanOption';
import EstimateRenoFiBenefits from './EstimateRenoFiBenefits';
import EstimateReviews from './EstimateReviews';
import EstimateLegalWarning from './EstimateLegalWarning';

const EstimateEmailPreviewModal = ({estimate, productMatch, show, onClose}) => {
  const {user, contractor} = useMyAccount();

  return (
    <Modal
      show={show}
      onClose={onClose}
      light
      width={920}
      contentCss={{
        display: 'flex',
        padding: [0, '40px 100px'],
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#F3FBFD',
        height: '100%',
      }}>
      <Flex height="100%" backgroundColor="#fff" flexDirection="column">
        <Image my={[24, 32]} ml={47} src={renofiLogoDarkIconSrc} width={151} />
        <EstimatePreviewBanner estimate={estimate} option={productMatch} />
        <Flex
          backgroundColor="#fff"
          padding={['24px 20px', '32px 47px']}
          flexDirection="column">
          <Text fontSize={24} lineHeight="normal">
            Hi {estimate?.firstName}! Here’s a renovation financing estimate
            from our friends at RenoFi for your project at{' '}
            {estimate?.propertyAddress}.
          </Text>
          <EstimateLoanOption
            loanAmount={estimate?.renovationCost}
            option={productMatch}
          />
          <EstimateRenoFiBenefits />

          <EstimateLegalWarning />

          <Button margin="auto" css={{width: '100%', maxWidth: 420}}>
            Explore your financing options
          </Button>
          <Text textAlign="center" my={20}>
            Or
          </Text>
          <Button margin="auto" secondary css={{width: '100%', maxWidth: 420}}>
            Schedule a call with an Advisor
          </Button>

          <EstimateReviews />
          <Text mt={32}>
            Please direct your financing related questions to RenoFi and all
            other project related questions to us at {contractor?.businessName}.
          </Text>
          <Text mt={32}>
            We look forward to working with you and RenoFi on your renovation
            project.
          </Text>
          <Text mt={24}>Thanks,</Text>
          <Text mt={24}>
            {user?.firstName} {user?.lastName}
          </Text>
          <Text>{contractor?.businessName}</Text>
          <Text>{user?.email}</Text>
        </Flex>
        <Image
          width="100%"
          src={estimatePreviewFooter}
          css={{position: 'relative', top: 10}}
        />
        <Footer />
      </Flex>
    </Modal>
  );
};

export default EstimateEmailPreviewModal;
