import {useEffect} from 'react';

import {isEmpty} from 'lodash';

import {useContractorById, useCurrentUser} from '@renofi/api';
import {getItem} from '@renofi/utils';
import {useLogin} from '@renofi/modules';

const getIsContractor = (user) =>
  user?.accountCategories?.includes('contractor');

export default () => {
  const {
    fetch,
    loading: isFetchingUser,
    refetch,
    removeUser,
    user,
  } = useCurrentUser({lazy: true});
  const {logout} = useLogin();
  const {
    contractor,
    loading: isFetchingContractor,
    refetch: refetchContractor,
  } = useContractorById({
    skip: !user?.contractorId,
    variables: {id: user?.contractorId},
  });
  const jwt = getItem('jwt');
  const refreshToken = getItem('refreshToken');
  const isLoggedIn = jwt && refreshToken && !isEmpty(user);
  const isContractor = getIsContractor(user);
  const isRegistrationComplete = Boolean(user?.contractorId);

  const loading = isFetchingUser || isFetchingContractor;
  const isOnboardingRequired =
    !loading && isLoggedIn && !isRegistrationComplete;

  useEffect(() => {
    if (jwt && refreshToken) {
      fetch();
    } else {
      logout();
    }
  }, [jwt]);

  return {
    contractor,
    fetch,
    isLoggedIn,
    isContractor,
    isOnboardingRequired,
    isRegistrationComplete,
    loading,
    refetchContractor,
    refetch,
    removeUser,
    user,
  };
};
