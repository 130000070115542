import {Flex, Text} from 'rebass';

const SelectInput = ({label, children}) => (
  <Flex
    flexDirection="column"
    padding={20}
    backgroundColor="#fff"
    css={{borderRadius: 12}}>
    <Text fontSize={16} lineHeight="19px" mb={17}>
      {label}
    </Text>
    {children}
  </Flex>
);

export default SelectInput;
