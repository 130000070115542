import {Flex} from 'rebass';
import {useLocation} from 'react-router-dom';

import {useScreenSize} from '@renofi/utils';

import {AUTH_PATHS, ACCOUNT_SETUP_PATHS} from '../../constants';

import OnboardingHeader from './OnboardingHeader';
import Footer from './Footer';
import {AUTH_SIDE_PANEL_CONTENT} from './constants';
import {Container, InnerContainer, SidePanel} from './styled';
import TopBar from './TopBar/TopBar';

export default ({children}) => {
  const {isDesktop} = useScreenSize();
  const location = useLocation();

  const isSignupFlow = Object.values({
    ...AUTH_PATHS,
    ...ACCOUNT_SETUP_PATHS,
  }).includes(location.pathname);

  if (!isSignupFlow) {
    return (
      <>
        <TopBar />
        <Container>{children}</Container>
      </>
    );
  }

  const SidePanelContent = AUTH_SIDE_PANEL_CONTENT[location.pathname];

  return (
    <Flex>
      <Flex
        backgroundColor="#F7FCFF"
        minHeight="100vh"
        flex={2}
        flexDirection="column">
        <OnboardingHeader />
        <InnerContainer>{children}</InnerContainer>
        <Footer />
      </Flex>
      {isDesktop && (
        <SidePanel flex={1}>
          <SidePanelContent />
        </SidePanel>
      )}
    </Flex>
  );
};
