import useToggledQuery from '../../useToggledQuery';
import {GET_CONTRACTOR_PROJECTS_V2} from '../queries';

export default function useContractorProjectsV2({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useToggledQuery(
    GET_CONTRACTOR_PROJECTS_V2,
    {
      lazy,
      fetchPolicy,
      ...options,
    },
  );
  const projects = data?.contractorProjectsV2 || [];

  return {fetch, loading, projects, refetch};
}
