const AGREEMENT_ELECTRONIC_SIG = 'electronic_signature';
const AGREEMENT_CONTRACTOR_TOS = 'contractor_terms_of_service';

export const AUTH_EMAIL_SESSION_KEY = 'contractors:auth-email';

export const CONTRACTOR_CACHE_KEY = 'renofi:cached-contractor';

export const REQUIRED_AGREEMENTS = [
  AGREEMENT_ELECTRONIC_SIG,
  AGREEMENT_CONTRACTOR_TOS,
];

export const AUTH_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  LOGIN_SUCCESS: '/login/success',
  SIGNUP: '/register', // Temp paths to avoid overlap while developing the new signup flow
  SIGNUP_SUCCESS: '/register/success', // while developing the new signup flow
};

export const ACCOUNT_SETUP_PATHS = {
  BUSINESS_DETAILS: '/business-details',
  BUSINESS_INFO: '/business-info',
  BUSINESS_CONTACT: '/business-contact',
};

export const PUBLIC_PATHS = ['/signup', ...Object.values(AUTH_PATHS)];

export const ONBOARDING_PATH = ACCOUNT_SETUP_PATHS.BUSINESS_DETAILS;

export const MAX_FILE_SIZE = 150 * 1e6;

export const EMAIL_ADDRESS = 'serviceproviders@renofi.com';
export const PHONE_NUMBER = '267-817-4776';

export const CLIENT_TYPES = {
  CONTRACTOR_CLIENT: 'contractor_client',
  DAAS_CLIENT: 'daas_client',
  PROJECT: 'project',
};
