import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';

import {blue, copyToClipboard} from '@renofi/utils';

import {toast} from './Toast';

const CopyToClipboard = ({
  children,
  showToast = true,
  successMessage = 'Copied',
  textToCopy,
  toastMessage = 'Text copied',
  onClick,
  ...props
}) => {
  const [timeoutId, setTimeoutId] = useState(false);
  const success = Boolean(timeoutId);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    copyToClipboard(textToCopy);

    onClick();

    if (showToast) {
      toast.success(toastMessage);
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let newId = setTimeout(() => setTimeoutId(false), 1000);
    setTimeoutId(newId);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box
      p={0}
      color={blue}
      onClick={handleClick}
      style={{textDecoration: 'none'}}
      {...props}>
      {success ? successMessage : children}
    </Box>
  );
};

CopyToClipboard.propTypes = {
  children: PropTypes.any,
  showToast: PropTypes.bool,
  successMessage: PropTypes.any,
  textToCopy: PropTypes.string,
  toastMessage: PropTypes.string,
};

export default CopyToClipboard;
