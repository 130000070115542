import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REQUEST_FINANCING_ON_CLIENT_BEHALF} from '../mutations';

export default function useRequestFinancingOnClientBehalf({
  awaitRefetchQueries = false,
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [requestFinancingOnClientBehalf, {data, loading, error}] = useMutation(
    REQUEST_FINANCING_ON_CLIENT_BEHALF,
    {
      awaitRefetchQueries,
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.requestFinancingOnClientBehalf;

  return {requestFinancingOnClientBehalf, response, loading, error};
}
