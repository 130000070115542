import {CLIENT_STATUS_LABELS} from './constants';

export default function getStatusLabel(status) {
  if (!status) return null;

  return (
    Object.keys(CLIENT_STATUS_LABELS).find((key) =>
      CLIENT_STATUS_LABELS[key].includes(status),
    ) || 'Unknown'
  );
}
