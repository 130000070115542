import {useQuery} from '@apollo/client';

import {GET_CONTRACTOR_BY_ID} from '../queries';

export default function useContractorById({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useQuery(GET_CONTRACTOR_BY_ID, {
    fetchPolicy,
    ...options,
  });
  const contractor = data?.contractorById;

  return {contractor, fetch, loading, refetch};
}
