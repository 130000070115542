import {breakpoint} from '@renofi/utils';

import {AUTH_PATHS, ACCOUNT_SETUP_PATHS} from '../../constants';
import harthLogo from '../../img/contractor-logos/harth.svg';
import homeRemodeling from '../../img/contractor-logos/home-remodeling.svg';
import greatAdditions from '../../img/contractor-logos/great-additions.svg';
import coreyAvatar from '../../img/testimonial-clients/corey-pic.png';

import Testimonials from './Testimonials';
import TrustedBy from './TrustedBy';

export const MID_BREAKPOINT = `@media (min-width: ${breakpoint}px) and (max-width: 1580px)`;
export const NAVBAR_DESKTOP_WIDTH = 300;
export const NAVBAR_DESKTOP_MID_WIDTH = 96;

const HARTH_TESTIMONIAL = {
  img: harthLogo,
  quote: `
    What I appreciate most about RenoFi is the simplicity of their solution.
    For my clients RenoFi has created an easy online application and for me,
    the contractor, there is no burdensome inspection and draw schedule.
  `,
  author: 'Greg Harth',
  details: 'President',
};

const GREAT_ADDITION_TESTIMONIAL = {
  img: greatAdditions,
  quote: `
    Being a company that only does larger builds like dormers & additions,
    most of our clients need to borrow some or all of the costs. Since they
    also add squarefeet & significant added value, we have done programs like
    203-K and Renovation Loans for many years. These days, people don't want to
    touch their primary mortgage with a rate from years ago, so RenoFi being able
    to lend in 2nd position has been a tremendous help! Many projects would not
    have otherwise been doable if not for our clients working with them!
  `,
  author: 'Nick Drasby',
  details: 'Great Additions Construction Co.',
};

const HOME_REMODELING_TESTIMONIAL = {
  img: homeRemodeling,
  quote: `
    I was introduced to RenoFi through my client who was using them to finance an
    addition I was putting on his house. The process with RenoFi was very easy and
    I look forward to working with them again to finance future client projects.
  `,
  author: 'Glen Jones',
  details: 'Owner, CMW Home Remodeling',
};

const COREY_CUSIMANO_TESTIMONIAL = {
  isClient: true,
  img: coreyAvatar,
  quote: `
    The cost estimate really helped us determine what we should
    prioritize and how we could maximize our budget for our renovation.
  `,
  author: 'Corey Cusimano',
  details: 'Homeowner, New Haven, CT',
};

export const TESTIMONIALS = [
  HARTH_TESTIMONIAL,
  GREAT_ADDITION_TESTIMONIAL,
  HOME_REMODELING_TESTIMONIAL,
];

export const DESIGN_TESTIMONIALS = [
  COREY_CUSIMANO_TESTIMONIAL,
  HARTH_TESTIMONIAL,
];

export const AUTH_SIDE_PANEL_CONTENT = {
  [AUTH_PATHS.SIGNUP]: TrustedBy,
  [AUTH_PATHS.SIGNUP_SUCCESS]: Testimonials,
  [ACCOUNT_SETUP_PATHS.BUSINESS_INFO]: Testimonials,
  [ACCOUNT_SETUP_PATHS.BUSINESS_DETAILS]: Testimonials,
  [ACCOUNT_SETUP_PATHS.BUSINESS_CONTACT]: Testimonials,
  [AUTH_PATHS.HOME]: Testimonials,
  [AUTH_PATHS.LOGIN]: Testimonials,
  [AUTH_PATHS.LOGIN_SUCCESS]: Testimonials,
};
