import {Route, Switch} from 'react-router-dom';

import EstimatesDashboard from './EstimatesDashboard';
import EstimateDetails from './EstimateDetails';

const FinancingEstimates = () => {
  return (
    <Switch>
      <Route exact path="/financing-estimates/:estimateId">
        <EstimateDetails />
      </Route>

      <Route>
        <EstimatesDashboard />
      </Route>
    </Switch>
  );
};

export default FinancingEstimates;
