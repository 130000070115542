import {makeVar, useReactiveVar} from '@apollo/client';

const isQuestionnaireFinishedVar = makeVar(false);

const useIsQuestionnaireFinished = () => {
  const isQuestionnaireFinished = useReactiveVar(isQuestionnaireFinishedVar);

  const setIsQuestionnaireFinished = (finished) => {
    isQuestionnaireFinishedVar(finished);
  };

  return {
    isQuestionnaireFinished,
    setIsQuestionnaireFinished,
  };
};

export default useIsQuestionnaireFinished;
