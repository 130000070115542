import {useMutation} from '@apollo/client';

import {SHARE_FINANCING_ESTIMATE_VIA_EMAIL} from '../mutations';

export default function useShareFinancingEstimteViaEmail({
  refetchQueries,
  ...options
}) {
  const [shareFinancingEstimateViaEmail, {data, loading, error}] = useMutation(
    SHARE_FINANCING_ESTIMATE_VIA_EMAIL,
    {
      refetchQueries,
      ...options,
    },
  );
  const response = data;
  return {shareFinancingEstimateViaEmail, response, loading, error};
}
