import {useState, useEffect} from 'react';

import {Box, Text} from 'rebass';
import {filter} from 'lodash';
import * as Sentry from '@sentry/react';

import {useSupportedMimeTypes, useUploadMetadata} from '@renofi/api';
import {Label, FileInput} from '@renofi/components';
import {blue, mediaQuery, uploadFileToCloud} from '@renofi/utils';

import {MAX_FILE_SIZE} from '../../../../constants';
import {useOnRejectedFiles} from '../../../../utils';
import {FileUploadError} from '../../../../components';

import UploadedFile from './UploadedFile';

const FAILED_PATH = ['success', false];

const EmailAttachments = ({
  isSubmitting,
  onAddAttachments,
  onRemoveAttachment,
}) => {
  const {supportedMimeTypes} = useSupportedMimeTypes();
  const {fetchFilesMetadata, loading: isUploading} = useUploadMetadata({
    lazy: true,
  });
  const onRejectFiles = useOnRejectedFiles({
    eventName: 'Contractors/Estimate-Email-Attachments-Failed-To-Upload',
  });
  const [failed, setFailed] = useState([]);
  const [uploaded, setUploaded] = useState([]);

  useEffect(() => {
    setUploaded([]);
  }, [isSubmitting]);

  const onAcceptFiles = async (files = []) => {
    if (!files.length) {
      return false;
    }

    setFailed([]);
    const metadata = await fetchFilesMetadata({files});

    const cloudStorageFiles = await Promise.all(
      metadata.map(
        async (data) => {
          const {file, url} = data;
          try {
            const rsp = await uploadFileToCloud(url, file);
            const success = rsp.status >= 200 && rsp.status < 300;
            return {...data, success, rsp};
          } catch (err) {
            return {...data, err, success: false};
          }
        },
        [[], []],
      ),
    );

    setUploaded([...uploaded, ...cloudStorageFiles]);

    const objectNames = cloudStorageFiles.map(({objectName}) => objectName);

    onAddAttachments(objectNames);

    // Finally, filter out those files that didn't upload / submit
    const failedItems = [...filter(cloudStorageFiles, FAILED_PATH)];
    setFailed(failedItems);
    if (Boolean(failedItems?.length)) {
      Sentry.captureException(new Error('Documents upload error'), {
        extra: failedItems.map(({err, file}) => ({err, file})),
      });
    }
  };

  const onRemoveFile = (file) => {
    const updatedFiles = filter(
      uploaded,
      ({recordId}) => recordId !== file.recordId,
    );
    setUploaded(updatedFiles);
    onRemoveAttachment(file.objectName);
  };

  return (
    <Box p={[3, 4]}>
      <Text fontSize={18} color={blue}>
        Attachments
      </Text>

      <Box mt={16}>
        <Label small>Attach other documents relating to this project.</Label>
        <FileInput
          accept={supportedMimeTypes}
          maxSize={MAX_FILE_SIZE}
          disabled={isSubmitting || isUploading}
          css={mediaQuery({height: [300, 200]})}
          p={0}
          text={
            <Text fontSize={16}>Drop files here to upload or browse files</Text>
          }
          onAcceptFiles={onAcceptFiles}
          onRejectFiles={onRejectFiles}
        />
        {uploaded.map((file) => (
          <UploadedFile
            key={file.recordId}
            file={file}
            onRemove={onRemoveFile}
          />
        ))}
        {Boolean(failed?.length) ? (
          <FileUploadError failedFiles={failed} />
        ) : null}
      </Box>
    </Box>
  );
};

export default EmailAttachments;
