import {last} from 'lodash';

const baseUrl =
  process.env.NODE_ENV === 'test'
    ? 'https://www.renofidesign.test/'
    : process.env.REACT_APP_RENOFI_DESIGN_URL;

export const getUrlBySlug = ({slug, short = false} = {}) => {
  if (short) {
    return `${last(baseUrl.split('//'))}${slug}`;
  }

  return `${baseUrl}${slug}`;
};
