import {Text} from 'rebass';

import {Td, Th} from '@renofi/components';

import {Layout} from '../';

import DocumentRow from './DocumentRow';

const DocumentsTable = ({documents = [], onDelete, wrapperCss, css}) => {
  return (
    <Layout.Table wrapperCss={wrapperCss} css={{td: {border: 'none'}, ...css}}>
      <thead>
        <tr>
          <Th>Uploaded</Th>
          <Th>Uploaded by</Th>
          <Th>Document type</Th>
          <Th>Document</Th>
          <Th width={50}>Delete</Th>
        </tr>
      </thead>
      <tbody>
        {!documents.length ? (
          <tr>
            <Td colSpan={4} css={{border: 'none'}}>
              <Text p={3} textAlign="center">
                Upload a copy of the contract, plans, and a detailed cost
                estimate breakdown
              </Text>
            </Td>
          </tr>
        ) : null}
        {documents.map(({id, ...doc}) => {
          return (
            <DocumentRow key={id} id={id} onClickDelete={onDelete} {...doc} />
          );
        })}
      </tbody>
    </Layout.Table>
  );
};

export default DocumentsTable;
