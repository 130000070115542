import styled from '@emotion/styled';
import {Box, Flex, Text} from 'rebass';
import {Link} from 'react-router-dom';

import {
  Table as CommonTable,
  Th as CommonTh,
  Td as CommonTd,
  TextField,
} from '@renofi/components';
import {blue, blueGray, darkBlue, gray, mediaQuery} from '@renofi/utils';

export const Table = styled(CommonTable)(
  mediaQuery({
    paddingBottom: 0,
    fontSize: 14,
    'td, tr': {
      borderColor: blueGray,
    },
    'tr:last-of-type': {
      border: 'none',
      td: {
        border: 'none',
      },
    },
  }),
);

export const Tr = styled.tr({
  '&:hover': {
    backgroundColor: '#D9E7F033',
    cursor: 'pointer',
  },
});

export const Th = styled(CommonTh)(
  mediaQuery({
    background: 'none',
    color: darkBlue,
    border: 'none',
  }),
);

export const Td = styled(CommonTd)(
  mediaQuery({
    borderColor: blueGray,
  }),
);

export const SearchField = styled(TextField)(
  mediaQuery({
    width: ['auto', 400],
    input: {fontSize: 16, padding: 0},
  }),
);

export const FilterButton = styled(Flex)(({open}) => ({
  padding: '8px 16px 8px 16px',
  background: '#fff',
  border: `1px solid ${blueGray}`,
  borderRadius: 4,
  ...(open
    ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    : {}),
  cursor: 'pointer',
}));

export const FilterDropdown = styled(Box)({
  position: 'absolute',
  top: 'calc(100% - 1px)',
  right: 0,
  zIndex: 2,
  width: 350,
  padding: 8,
  background: '#fff',
  border: `1px solid ${blueGray}`,
  borderRadius: 4,
  borderTopRightRadius: 0,
});

export const Sorter = styled(Text)({
  cursor: 'pointer',
  userSelect: 'none',
  ':hover': {
    color: blue,
  },
  ':active': {
    color: darkBlue,
  },
});

export const DateSuffix = styled.div({
  marginTop: 2,
  fontSize: 12,
  color: gray,
});

export const UploadLink = styled(Link)({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  color: darkBlue,
});
