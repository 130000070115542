import {Flex} from 'rebass';

import {Layout} from '../../components';

import DownloadAssets from './DownloadAssets';
import UploadLogo from './UploadLogo';

const MarketingAssets = () => {
  return (
    <>
      <Layout.Panel css={{overflow: 'hidden'}} contentCss={{padding: 0}}>
        <Flex flexDirection={['column', 'row']}>
          <DownloadAssets />
          <UploadLogo />
        </Flex>
      </Layout.Panel>
    </>
  );
};

export default MarketingAssets;
