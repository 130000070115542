import React, {useState} from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const QuoteIcon = ({
  hoverColor = lightGray,
  onClick,
  color = lightGray,
  width = 25,
  height = 15,
  css,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      onClick={onClick ? onClick : noop}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto', ...css}}
      width={width}
      height={height}
      viewBox="0 0 25 15"
      fill={hover ? hoverColor : color}
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.1"
        d="M9.61882 1.02118C7.9498 1.70196 6.52235 2.72314 5.33647 4.08471C4.76549 4.74353 4.48 5.5451 4.48 6.48941C4.48 6.92863 4.5349 7.29098 4.64471 7.57647C4.71059 7.7302 4.9851 8.10353 5.46824 8.69647C5.97333 9.26745 6.29176 9.69569 6.42353 9.98118C6.57725 10.3545 6.65412 10.7278 6.65412 11.1012C6.65412 12.0235 6.32471 12.8361 5.66588 13.5388C5.00706 14.2196 4.21647 14.56 3.29412 14.56C2.39373 14.56 1.61412 14.2086 0.955294 13.5059C0.318431 12.7812 0 11.8369 0 10.6729C0 9.37725 0.340392 8.04863 1.02118 6.68706C1.70196 5.32549 2.71216 4.08471 4.05176 2.96471C4.97412 2.19608 6.57726 1.20784 8.86118 0L9.61882 1.02118ZM24.7388 1.02118C22.5208 2.25098 21.1043 3.27216 20.4894 4.08471C19.8745 4.87529 19.5671 5.64392 19.5671 6.39059C19.5671 6.85176 19.6439 7.24706 19.7976 7.57647C19.8635 7.75216 20.138 8.12549 20.6212 8.69647C21.1263 9.26745 21.4337 9.66275 21.5435 9.88235C21.6973 10.2557 21.7741 10.64 21.7741 11.0353C21.7741 12.0016 21.4447 12.8361 20.7859 13.5388C20.1271 14.2196 19.3365 14.56 18.4141 14.56C17.4918 14.56 16.7012 14.2196 16.0424 13.5388C15.4055 12.8361 15.0871 11.8918 15.0871 10.7059C15.0871 8.81726 15.7678 6.97255 17.1294 5.17176C18.491 3.37098 20.8078 1.72392 24.08 0.230588L24.7388 1.02118Z"
        fill={hover ? hoverColor : color}
      />
    </svg>
  );
};

export default QuoteIcon;
