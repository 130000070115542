import {useMemo} from 'react';

import {Flex, Text} from 'rebass';

import {useContractorEstimates, uselinkAnalyticsSummaries} from '@renofi/api';
import {InfoIcon, Loader, Pagination} from '@renofi/components';
import {gray, red} from '@renofi/utils';

import {Layout, Table, Th} from '../../../components';
import {ESTIMATES_PER_PAGE} from '../../constants';

import EstimateRow from './EstimateRow';

const SavedEstimates = () => {
  const {
    estimates,
    metadata,
    refetch: fetchEstimates,
    loading,
  } = useContractorEstimates({
    variables: {limit: ESTIMATES_PER_PAGE},
  });

  const extractShortCode = (url) => {
    const match = url ? url.match(/https:\/\/[^/]+\/([a-zA-Z0-9]+)/) : null;
    return match ? match[1] : null;
  };

  const shortCodes = useMemo(() => {
    return estimates
      .map((estimate) => extractShortCode(estimate.scenarioDashboardLink))
      .filter((shortcode) => shortcode !== null);
  }, [estimates]);

  const {linkAnalyticsSummaries} = uselinkAnalyticsSummaries({
    variables: {shortCodes},
    skip: shortCodes.length === 0,
  });

  const estimatesWithClickCount = useMemo(() => {
    return estimates.map((estimate) => {
      const shortCode = extractShortCode(estimate.scenarioDashboardLink);
      const linkSummaryForEstimate = linkAnalyticsSummaries.find(
        (linkSummary) => linkSummary.shortCode === shortCode,
      );
      return {
        ...estimate,
        totalClicks: linkSummaryForEstimate
          ? linkSummaryForEstimate.totalClicks
          : 0,
      };
    });
  }, [estimates, linkAnalyticsSummaries]);

  return (
    <Flex flexDirection="column" alignItems="center" css={{gap: 30}}>
      <Layout.Panel css={{width: '100%'}} contentCss={{paddingBottom: 0}}>
        <Layout.PanelTitleV2
          data-testid="saved-estimates-title"
          css={{textAlign: 'left'}}>
          Saved estimates
        </Layout.PanelTitleV2>
        <Layout.PanelInnerWrapper>
          {!!estimates.length && !loading && (
            <Table>
              <thead>
                <tr>
                  <Th>Client</Th>
                  <Th>Address</Th>
                  <Th>Created</Th>
                  <Th>Loan amount</Th>
                  <Th>Status</Th>
                  <Th>Copy link</Th>
                  <Th></Th>
                </tr>
              </thead>
              <tbody>
                {estimatesWithClickCount.map((estimate) => (
                  <EstimateRow key={estimate.id} estimate={estimate} />
                ))}
              </tbody>
            </Table>
          )}
          {!estimates.length && (
            <Flex
              padding={30}
              justifyContent="center"
              css={{borderTop: '1px solid #D9E7F0'}}>
              <Flex flexDirection="column" alignItems="center">
                <InfoIcon color={gray} />
                <Text color={gray}>Saved estimates will appear here.</Text>
              </Flex>
            </Flex>
          )}
          {loading && (
            <Flex padding={30} justifyContent="center" alignItems="center">
              <Loader color={red} />
            </Flex>
          )}
        </Layout.PanelInnerWrapper>
      </Layout.Panel>
      <Pagination
        full
        current={metadata.currentPage}
        max={metadata.totalPages}
        total={metadata.totalPages}
        onChange={(page) =>
          fetchEstimates({page: page, limit: ESTIMATES_PER_PAGE})
        }
      />
    </Flex>
  );
};

export default SavedEstimates;
