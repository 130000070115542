import {useEffect, useRef, useState} from 'react';

import {Box, Flex, Image, Link, Text} from 'rebass';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';

import {sendEvent} from '@renofi/analytics';
import {
  Button,
  Loader,
  renofiLogoDarkIconSrc,
  Toggle,
} from '@renofi/components';
import {darkBlue, darkGreen, fadeGreen} from '@renofi/utils';

import billion from '../../img/1-billion.svg';
import googleRating from '../../img/google-rating.png';
import footerBg from '../../img/pdf-footer-bg.svg';
import EstimatePreviewBanner from '../EstimatePreviewBanner';
import EstimateLoanOption from '../EstimateLoanOption';
import EstimateRenoFiBenefits from '../EstimateRenoFiBenefits';
import EstimateReviews from '../EstimateReviews';

import {PdfImage, PdfPreview, PdfWrapper} from './styled';

const A4_WIDTH_MM = 210;
const A4_HEIGHT_MM = 297;

const PdfTab = ({dashboardLink, estimate, productMatch}) => {
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    if (estimate?.scenarioDashboardLinkQrCodeUrl) {
      saveAsDataURL();
    }
  }, [estimate?.scenarioDashboardLinkQrCodeUrl]);

  const onClickDownload = async () => {
    setLoading(true);

    sendEvent('Contractors/Create-PDF', {
      clientEmail: estimate.email,
      scenarioId: estimate.scenarioId,
    });

    // jsPDF already _has_ support for html2canvas & ingesting HTML .. but it's
    // super buggy and just produces "Blank" PDFs, so just load a canvas image directly
    const fileName = `estimate-${estimate?.propertyAddress.replace(/[\W_]+/g, '-')}`;
    const doc = new jsPDF();
    doc.addImage(dataUrl, 'PNG', 0, 0, A4_WIDTH_MM, A4_HEIGHT_MM);
    setLoading(false);
    doc.save(`${fileName}.pdf`);
  };

  const saveAsDataURL = async () => {
    setDataUrl(null);
    const block = document.getElementById('content-for-pdf');
    const canvas = await html2canvas(block, {
      allowTaint: true,
      useCORS: true,
    });

    setDataUrl(canvas.toDataURL('image/png'));
  };

  if (!estimate?.scenarioDashboardLinkQrCodeUrl) {
    return <Loader />;
  }

  return (
    <Box textAlign="center">
      <Box px={4}>
        <Button
          role="download_pdf_btn"
          disabled={loading}
          onClick={onClickDownload}>
          Download PDF
        </Button>
      </Box>

      <Toggle show={Boolean(dataUrl)}>
        <PdfImage src={dataUrl} />
      </Toggle>

      <Toggle show={!dataUrl}>
        <Loader />
      </Toggle>

      <PdfWrapper>
        <PdfPreview
          ref={ref}
          id="content-for-pdf"
          css={{position: 'absolute', top: -9999, left: -9999}}>
          <Flex alignItems="center" justifyContent="space-between" p={4}>
            <Image width={195} src={renofiLogoDarkIconSrc} />
            <Flex alignItems="center">
              <Image src={googleRating} />
              <Image src={billion} />
            </Flex>
            <Text as="em" fontSize="10">
              This is an advertisement
            </Text>
          </Flex>

          <EstimatePreviewBanner
            estimate={estimate}
            imageWidth={225}
            option={productMatch}
            showAddress
          />

          <Flex width={1} textAlign="left">
            <Box flex={0.5} p={40}>
              <EstimateLoanOption
                loanAmount={estimate.renovationCost}
                option={productMatch}
              />

              <EstimateRenoFiBenefits />

              <Flex alignItems="center" mb={5} mt={4}>
                <Image
                  id="dashboard-qr-code-url"
                  // onLoad={saveAsDataURL}
                  mr={1}
                  minWidth={110}
                  width={110}
                  height={110}
                  src={estimate?.scenarioDashboardLinkQrCodeUrl}
                />

                <Box
                  color={darkBlue}
                  fontWeight="700"
                  fontSize={34}
                  lineHeight="38px">
                  <Text>Scan or visit</Text>
                  <Link color="inherit" href={dashboardLink} target="_blank">
                    {dashboardLink?.replace(/^http([s]?):\/\//, '')}
                  </Link>
                  <Text>to learn more</Text>
                </Box>
              </Flex>
            </Box>

            <Box
              pb={5}
              css={{
                backgroundColor: '#F7FAFC',
                borderLeft: '1px solid #00000006',
              }}
              flex={0.5}>
              <EstimateReviews />
            </Box>
          </Flex>

          <Box
            css={{
              position: 'relative',
              backgroundColor: fadeGreen,
              color: darkGreen,
              fontSize: 14,
            }}>
            <Image
              css={{position: 'absolute', left: 0, bottom: '100%'}}
              src={footerBg}
            />
            <Text p={5}>
              NOT PERSONALIZED LOAN OFFER. Sample payment based on lowest
              interest rate offered by lender of 8.5% (as of 5/9/24) for a
              variable-rate HELOC with a 10-year draw period (interest-only
              monthly payments required) and 15-year repayment period (principal
              + interest monthly payments required), available to applicants
              with a credit score of at least 740, and assumes debt-to-income
              and combined loan-to-value ratios within lender guidelines and
              100% return of investment on renovation financing. Variable rate
              determined by prime rate plus lender’s margin. Rate and loan
              program terms subject to change without notice. Closing costs and
              fees apply.
            </Text>
          </Box>
        </PdfPreview>
      </PdfWrapper>
    </Box>
  );
};

export default PdfTab;
