import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {AUTHENTICATE_BY_LOGIN_TOKEN} from '../mutations';

export default function useAuthenticateByLoginToken({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [authenticateByLoginToken, {data, loading, error}] = useMutation(
    AUTHENTICATE_BY_LOGIN_TOKEN,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const user = data?.authenticateByLoginToken?.user;
  return {authenticateByLoginToken, user, loading, error};
}
