import {isUrl} from '@renofi/utils';

const REQUIRED_FIELDS = [
  'businessName',
  'city',
  'state',
  'streetAddress',
  'website',
  'zipCode',
];

export const areFieldsValid = (data = {}) => {
  if (!data || !Object.keys(data).length) {
    return false;
  }

  return REQUIRED_FIELDS.every((key) => {
    const value = data[key];
    const isValueGiven = Boolean(value);

    if (key === 'website') {
      return isValueGiven ? isUrl(value) : true;
    }
    return isValueGiven;
  });
};
