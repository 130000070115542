import React, {useContext} from 'react';

import PropTypes from 'prop-types';
import {capitalize} from 'lodash';
import {Box, Link} from 'rebass';

import {
  GET_CONTRACTOR_PROJECTS_V2,
  useSetDocumentType,
  useSignedUrl,
  useUpdateContractorProjectDocument,
} from '@renofi/api';
import {SelectField, Td, TrashIcon} from '@renofi/components';
import {formatIsoDate} from '@renofi/utils/src/date';
import {sendEvent} from '@renofi/analytics';
import {fadeGray, lightGray} from '@renofi/utils';

import AppContext from '../../context';

import {VALID_DOC_TYPES} from './constants';

const DocumentRow = ({id, isRemovable, objectName, onClickDelete, ...doc}) => {
  const {contractor} = useContext(AppContext);

  const {fetch} = useSignedUrl({lazy: true, variables: {objectName}});
  const {loading: isSettingType, setDocumentType} = useSetDocumentType();
  const {loading: isUpdating, updateContractorProjectDocument} =
    useUpdateContractorProjectDocument({
      refetchQueries: [
        {
          query: GET_CONTRACTOR_PROJECTS_V2,
        },
      ],
    });
  const hasNoType = !doc.documentType || doc.documentType === 'unclassified';
  const isLoading = isSettingType || isUpdating;

  const onClickView = async (e) => {
    e.preventDefault();
    sendEvent('Contractors/Financing/Project-Doc-Viewed');
    const rsp = await fetch();
    const url = rsp?.data?.signedUrl?.url;
    if (url) {
      window.open(url);
    }
  };

  const onChangeDocType = async (documentType) => {
    sendEvent('Contractors/Financing/Project-Doc-Type-Changed', {documentType});
    await setDocumentType({variables: {documentType, id}});

    await updateContractorProjectDocument({
      variables: {
        contractorId: contractor?.id,
        documentId: id,
        documentType,
      },
    });
  };

  return (
    <tr key={id}>
      <Td>{formatIsoDate(doc?.createdAt)}</Td>
      <Td>{formatIsoDate(doc?.owner)}</Td>
      <Td>
        <SelectField
          disabled={isLoading}
          data-testid={`document-type-${id}`}
          id={`document-type-${id}`}
          name={`document-type-${id}`}
          onChange={onChangeDocType}
          value={doc.documentType}>
          {hasNoType ? (
            <option value={doc.documentType}>Select document type</option>
          ) : null}
          {VALID_DOC_TYPES.map((docType) => {
            return (
              <option key={docType} value={docType}>
                {capitalize(docType?.replace(/_/gi, ' '))}
              </option>
            );
          })}
        </SelectField>
      </Td>
      <Td css={{maxWidth: [175, 250]}}>
        <Box
          as="div"
          css={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          <Link
            data-testid={`table_row_doc_${id}`}
            css={{cursor: 'pointer'}}
            title={doc.fileName}
            onClick={onClickView}>
            {doc.fileName}
          </Link>
        </Box>
      </Td>
      <Td css={{textAlign: 'center'}}>
        <TrashIcon
          disabled={!isRemovable}
          color={!isRemovable ? fadeGray : lightGray}
          onClick={onClickDelete(id)}
          role={`delete_file_${id}`}
        />
      </Td>
    </tr>
  );
};

DocumentRow.propTypes = {
  createdAt: PropTypes.string,
  documentType: PropTypes.string,
  isRemovable: PropTypes.bool,
  objectName: PropTypes.string,
  fileName: PropTypes.string,
  onClickDelete: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default DocumentRow;
