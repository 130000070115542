import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const AuthContainer = styled(Flex)(
  mediaQuery({
    width: ['calc(100vw - 32px)', 625],
    alignSelf: 'center',
    background: '#fff',
    borderRadius: 8,
  }),
);
