import React, {useContext} from 'react';

import {Box, Text} from 'rebass';

import {FileInput} from '@renofi/components';
import {useSupportedMimeTypes} from '@renofi/api';
import {blueGray, mediaQuery} from '@renofi/utils';

import AppContext from '../../context';
import {MAX_FILE_SIZE} from '../../constants';
import FileUploadError from '../FileUploadError';

import DocumentsTable from './DocumentsTable';

const DocumentUpload = ({
  documents,
  isSubmitting,
  isUploading,
  failed,
  onAcceptFiles,
  onRejectFiles,
  onClickDelete,
}) => {
  const {user} = useContext(AppContext);
  const {supportedMimeTypes} = useSupportedMimeTypes({lazy: !user.id});

  return (
    <Box>
      <FileInput
        accept={supportedMimeTypes}
        maxSize={MAX_FILE_SIZE}
        my={Boolean(failed?.length) ? 2 : 4}
        disabled={isSubmitting || isUploading}
        css={mediaQuery({height: [300, 200]})}
        p={0}
        text={
          <Text fontSize={16}>Drop files here to upload or browse files</Text>
        }
        onAcceptFiles={onAcceptFiles}
        onRejectFiles={onRejectFiles}
      />

      {Boolean(failed?.length) ? (
        <FileUploadError failedFiles={failed} />
      ) : null}

      <Box
        css={{
          borderRadius: '8px',
          border: `1px solid ${blueGray}`,
          overflow: 'hidden',
        }}>
        <DocumentsTable
          documents={documents}
          css={{tr: {border: 'none'}, border: 'none'}}
          onDelete={onClickDelete}
        />
      </Box>
    </Box>
  );
};

export default DocumentUpload;
