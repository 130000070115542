import {Flex} from 'rebass';
import {range, noop} from 'lodash';

import {Page} from './styled';

export default function Pagination({
  current = 1,
  full,
  max = 5,
  total = 1,
  onChange = noop,
  ...props
}) {
  const bufferedMax = max * 2;
  const start =
    current - max < 1
      ? 1
      : current + max > total
        ? total - bufferedMax
        : current - max;

  return (
    <Flex {...props}>
      {full && (
        <Page disabled={current < 2} onClick={() => onChange(1)}>
          First
        </Page>
      )}
      <Page disabled={current < 2} onClick={() => onChange(current - 1)}>
        Previous
      </Page>
      {range(
        start > 0 ? start : 1,
        (total > bufferedMax ? start + bufferedMax : total) + 1,
      ).map((page) => (
        <Page
          active={current === page}
          key={page}
          onClick={() => onChange(page)}>
          {page}
        </Page>
      ))}
      <Page disabled={current >= total} onClick={() => onChange(current + 1)}>
        Next
      </Page>
      {full && (
        <Page disabled={current >= total} onClick={() => onChange(total)}>
          Last
        </Page>
      )}
    </Flex>
  );
}
