import {useState} from 'react';

import {Flex, Box, Text} from 'rebass';

import {
  blueGray,
  fadeGreen,
  darkBlue,
  toCurrency,
  fadeBlue,
  mediaQuery,
} from '@renofi/utils';
import {Label, Badge} from '@renofi/components';

import {Layout} from '../../../components';
import {CLIENT_TYPES} from '../../../constants';
import getStatusLabel from '../../getStatusLabel';

import EditClientModal from './EditClientModal';

const ValueText = ({children}) => <Text color={darkBlue}>{children}</Text>;

const getClientAddress = (client, consolidatedEntity) => {
  switch (consolidatedEntity?.normalizedEntityType) {
    case CLIENT_TYPES.CONTRACTOR_CLIENT:
      return client?.propertyAddress;
    case CLIENT_TYPES.PROJECT:
      const propertyInfo = client?.borrowersAndPropertyInformation;
      const streetAddress =
        propertyInfo?.propertyStreetAddressOne ||
        propertyInfo?.propertyStreetAddressTwo;
      return `${streetAddress || ''}, ${propertyInfo?.propertyCity || ''}, ${propertyInfo?.propertyState || ''}`;
    default:
      return null;
  }
};

const ClientSummary = ({client, consolidatedEntity}) => {
  const [editting, setEditting] = useState(false);

  const address = getClientAddress(client, consolidatedEntity);
  const phoneNumber = client?.phoneNumber || client?.borrower?.phoneNumber;
  const email =
    client?.email || client?.borrowersAndPropertyInformation?.borrowerEmail;
  const projectCost =
    consolidatedEntity?.entityRenovationCost || client?.renovationCost;
  const projectDesription =
    client?.projectDetails || client?.additionalReferralInformation;

  return (
    <>
      <EditClientModal show={editting} onCancel={() => setEditting(false)} />
      <Layout.Panel contentCss={{paddingBottom: 0}}>
        <Layout.PanelTitleV2 width="100%" justifyContent="space-between">
          Summary
        </Layout.PanelTitleV2>
        <Layout.PanelInnerWrapper>
          <Flex
            flexDirection="column"
            padding={['28px 16px', '20px 40px']}
            css={{
              borderTop: `1px solid ${blueGray}`,
            }}>
            <Flex flexDirection={['column', 'row']}>
              <Box flex={1}>
                <Label small>Property address</Label>
                <ValueText>{address || '-'}</ValueText>
              </Box>
              <Flex
                mt={[24, 0]}
                flex={1}
                flexDirection={['column', 'row']}
                css={mediaQuery({gap: [24, 56]})}>
                <Box>
                  <Label small>Phone</Label>
                  <ValueText>{phoneNumber || '-'}</ValueText>
                </Box>
                <Box>
                  <Label small>Email</Label>
                  <ValueText>{email || '-'}</ValueText>
                </Box>
              </Flex>
            </Flex>
            <Flex mt={32} flexDirection={['column', 'row']}>
              {(consolidatedEntity?.entityProjectStatus ||
                consolidatedEntity?.entityLeadStatus) && (
                <Flex
                  flex={1}
                  flexDirection="column"
                  css={mediaQuery({gap: [16, 24]})}>
                  <Box>
                    <Badge xLarge color={fadeGreen} textColor={darkBlue}>
                      Financing
                    </Badge>
                  </Box>

                  <Box>
                    <Label small>Status</Label>
                    <ValueText>
                      {getStatusLabel(
                        consolidatedEntity?.entityProjectStatus ||
                          consolidatedEntity?.entityLeadStatus,
                      ) || '-'}
                    </ValueText>
                  </Box>

                  <Box>
                    <Label small>Project budget</Label>
                    <ValueText>
                      {toCurrency(projectCost, {
                        prefix: '$',
                      }) || '-'}
                    </ValueText>
                  </Box>
                </Flex>
              )}
              {consolidatedEntity?.entityDesignStatus && (
                <Flex
                  mt={[32, 0]}
                  flex={1}
                  flexDirection="column"
                  css={mediaQuery({gap: [16, 24]})}>
                  <Box>
                    <Badge xLarge color={fadeBlue} textColor={darkBlue}>
                      Design
                    </Badge>
                  </Box>

                  <Box>
                    <Label small>Status</Label>
                    <ValueText>
                      {getStatusLabel(consolidatedEntity?.entityDesignStatus) ||
                        '-'}
                    </ValueText>
                  </Box>
                </Flex>
              )}
            </Flex>
            <Box mt={32}>
              <Label small>Brief description of the project</Label>
              <ValueText>{projectDesription || '-'}</ValueText>
            </Box>
          </Flex>
        </Layout.PanelInnerWrapper>
      </Layout.Panel>
    </>
  );
};

export default ClientSummary;
