import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery, darkBlue} from '@renofi/utils';

export const EstimatePreviewBannerWrapper = styled(Flex)(
  mediaQuery({
    mt: 32,
    padding: ['27px 37px', '39px 50px 0 39px'],
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'flex-end'],
    backgroundColor: darkBlue,
    gap: [23, 34],
  }),
);

export const EstimateLoanOptionProp = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    width: '100%',
  }),
);

export const EstimatePreviewPanel = styled(Flex)({
  flexDirection: 'column',
  marginTop: 28,
  backgroundColor: '#F7FAFC',
  padding: 20,
  borderRadius: 8,
});
