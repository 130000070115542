import React from 'react';

import {Text} from 'rebass';

import {
  renofiLogoDarkIconSrc,
  renofiLogoLightIconSrc,
} from '@renofi/components';

import {LogoWrapper, LogoImage} from './styled';

const Logo = ({light, ...props}) => {
  const isRow = props.flexDirection === 'row';
  const logoImg = light ? renofiLogoLightIconSrc : renofiLogoDarkIconSrc;
  return (
    <LogoWrapper isRow={isRow} {...props}>
      <LogoImage src={logoImg} alt="RenoFi" />
      <Text
        mt={1}
        fontSize={isRow ? [21, 32] : 16}
        fontWeight={isRow ? 400 : 700}>
        for Contractors
      </Text>
    </LogoWrapper>
  );
};

export default Logo;
