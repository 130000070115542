import styled from '@emotion/styled';
import {Flex, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const UserGuideFlex = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'start'],
    gap: [20, 34],
    position: 'relative',
  }),
);

export const UserGuideFlexItem = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    alignItems: ['center', 'start'],
  }),
);

export const CloseButton = styled(Box)(
  mediaQuery({
    position: 'absolute',
    top: [-8, -15],
    right: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
);
