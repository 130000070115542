import {Flex, Text, Image} from 'rebass';

import studioShedLogo from '../../img/contractor-logos/studio-shed.svg';
import greatAdditions from '../../img/contractor-logos/great-additions.svg';
import harthLogo from '../../img/contractor-logos/harth.svg';
import jazzLogo from '../../img/contractor-logos/jazz-constructions.svg';

const TrustedBy = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    padding="150px 55px"
    css={{gap: 55}}>
    <Text fontSize={32} lineHeight="38px" maxWidth={400} textAlign="center">
      Trusted by leading contractors including
    </Text>

    {[studioShedLogo, greatAdditions, harthLogo, jazzLogo].map((logo, i) => (
      <Image key={i} src={logo} />
    ))}
  </Flex>
);

export default TrustedBy;
