const CopyIcon = ({width = 17, height = 9, color = '#00A0FF'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 17 9"
    fill="none">
    <path
      d="M7.66602 8.66671L4.33268 8.66671C3.1799 8.66671 2.19727 8.26046 1.38477 7.44796C0.572266 6.63546 0.166016 5.65282 0.166016 4.50004C0.166016 3.34726 0.572266 2.36462 1.38477 1.55212C2.19727 0.739624 3.1799 0.333374 4.33268 0.333374L7.66602 0.333374V2.00004H4.33268C3.63824 2.00004 3.04796 2.2431 2.56185 2.72921C2.07574 3.21532 1.83268 3.8056 1.83268 4.50004C1.83268 5.19449 2.07574 5.78476 2.56185 6.27087C3.04796 6.75699 3.63824 7.00004 4.33268 7.00004H7.66602L7.66602 8.66671ZM5.16602 5.33337V3.66671L11.8327 3.66671V5.33337L5.16602 5.33337ZM9.33268 8.66671V7.00004H12.666C13.3605 7.00004 13.9507 6.75699 14.4368 6.27087C14.923 5.78476 15.166 5.19449 15.166 4.50004C15.166 3.8056 14.923 3.21532 14.4368 2.72921C13.9507 2.2431 13.3605 2.00004 12.666 2.00004H9.33268V0.333374L12.666 0.333374C13.8188 0.333374 14.8014 0.739624 15.6139 1.55212C16.4264 2.36462 16.8327 3.34726 16.8327 4.50004C16.8327 5.65282 16.4264 6.63546 15.6139 7.44796C14.8014 8.26046 13.8188 8.66671 12.666 8.66671H9.33268Z"
      fill={color}
    />
  </svg>
);

export default CopyIcon;
