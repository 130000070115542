import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {AUTHENTICATE} from '../mutations';

export default function useAuthenticate({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [authenticate, {data, loading, error}] = useMutation(AUTHENTICATE, {
    onCompleted,
    refetchQueries,
  });

  const user = data?.authenticate?.user;
  return {authenticate, user, loading, error};
}
