import {Flex, Box, Image, Text} from 'rebass';

import {blueGray, gray} from '@renofi/utils';

const QuickStartOption = ({iconSrc, headline, description, children}) => {
  return (
    <Flex
      flexDirection={['column', 'row']}
      backgroundColor="#FFF"
      maxWidth={410}
      p={24}
      alignItems={['center', 'flex-start']}
      css={{gap: 24, border: `1px solid ${blueGray}`, borderRadius: '8px'}}>
      <Image size={[70, 50]} flexShrink={0} src={iconSrc} />
      <Box>
        <Text fontSize={20} lineHeight="24px" textAlign={['center', 'left']}>
          {headline}
        </Text>
        <Text
          fontSize={14}
          lineHeight="20px"
          color={gray}
          my={16}
          textAlign={['center', 'left']}>
          {description}
        </Text>
        {children}
      </Box>
    </Flex>
  );
};

export default QuickStartOption;
