import {useEffect} from 'react';

import {Flex, Image, Text} from 'rebass';
import {useLocation, useHistory} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {useRequestUserLogin, useRequestUserRegistration} from '@renofi/api';
import {useSessionStorage} from '@renofi/utils';
import {Alert, Button} from '@renofi/components';

import {AUTH_EMAIL_SESSION_KEY} from '../constants';
import paperPlaneSrc from '../img/paper-plane.svg';

const SignupSuccess = () => {
  const location = useLocation();
  const history = useHistory();
  const [{email}] = useSessionStorage(AUTH_EMAIL_SESSION_KEY, {});
  const {loading: isRequesting, requestUserLogin} = useRequestUserLogin();
  const {loading: isRegistering, requestUserRegistration} =
    useRequestUserRegistration();

  const isLogin = location.pathname === '/login/success';
  const accountCategory = 'contractor';

  useEffect(() => {
    if (!email) {
      history.push('/');
    }
  }, [email]);

  const onSubmit = async () => {
    if (isLogin) {
      await requestUserLogin({variables: {email}});
    } else {
      await requestUserRegistration({variables: {accountCategory, email}});
    }

    sendEvent('Contractors/Resend-Email-Clicked');
  };

  return (
    <Flex
      flexDirection="column"
      width={['100%', 510]}
      mx="auto"
      alignItems="center">
      <Image
        position="relative"
        marginRight="50%"
        width={446}
        src={paperPlaneSrc}
      />
      <Text mb={24} textAlign="center" fontSize={32} lineHeight="46px">
        {isLogin ? 'Check your email!' : 'Account created successfully!'}
      </Text>
      <Alert success css={{justifyContent: 'center', marginBottom: 32}}>
        {isLogin && (
          <Text textAlign="center">
            We’ve sent a sign in link to <strong>{email}</strong>
          </Text>
        )}
        {!isLogin && (
          <Text textAlign="center">
            We’ve sent an email to <strong>{email}</strong>
            <br />
            Simply click the button in the email to continue your registration.
          </Text>
        )}
      </Alert>
      <Button loading={isRequesting || isRegistering} onClick={onSubmit}>
        Resend email
      </Button>
    </Flex>
  );
};

export default SignupSuccess;
