export const LIMIT_VALUE = 25;

export const NO_STATUS = 'N/A';

export const STATUSES = [
  'unqualified',
  'first_consultation',
  'scanning',
  'concept_review',
  'design_review',
  'design_handover',
  'nurturing',
];
