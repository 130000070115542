import {useEffect, useState} from 'react';

import {Flex} from 'rebass';

import {useDebounce, toCurrency} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {useNotifications, Alert} from '@renofi/components';

import EstimateLegalWarning from '../EstimateLegalWarning';
import CheckIcon from '../CheckIcon';

import SubmissionSection from './SubmissionSection';
import ValuePreview from './ValuePreview';
import {ProductMatchAttr} from './styled';

const ProductMatch = ({
  estimateDetails,
  fetchProductMatches,
  fetchingProductMatches,
  productMatch,
}) => {
  const [fetchedProductMatches, setFetchedProductMatches] = useState(false);
  const denouncedRenovationCost = useDebounce(
    estimateDetails.renovationCost,
    500,
  );
  const debouncedEstimatedCurrentPropertyValue = useDebounce(
    estimateDetails.estimatedCurrentPropertyValue,
    500,
  );
  const addNotification = useNotifications();

  const formCompleted =
    Boolean(denouncedRenovationCost) &&
    denouncedRenovationCost >= 10000 &&
    Boolean(estimateDetails.propertyZipCode);

  useEffect(() => {
    if (formCompleted) {
      (async () => {
        try {
          await fetchProductMatches({
            variables: {
              zipCode: estimateDetails.propertyZipCode,
              loanAmount: estimateDetails.renovationCost,
              estimatedHomeValue: estimateDetails.estimatedCurrentPropertyValue,
            },
          });
          setFetchedProductMatches(true);
        } catch (e) {
          sendEvent('Contractors/Failed-To-Match-Products-For-Estimate', {
            message: e.message,
          });
          addNotification({
            type: 'error',
            message: 'There was a problem matching products for this estimate.',
          });
        }
      })();
    } else {
      setFetchedProductMatches(false);
    }
  }, [
    denouncedRenovationCost,
    debouncedEstimatedCurrentPropertyValue,
    estimateDetails.propertyZipCode,
  ]);

  return (
    <SubmissionSection
      step={3}
      label="Review & save financing estimate before sharing"
      css={{
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
      }}>
      {formCompleted && fetchedProductMatches && !productMatch && (
        <Alert icon css={{marginTop: 0, marginBottom: 24}}>
          There are no loan products matching the criteria entered above.
        </Alert>
      )}
      <Flex
        width="100%"
        flexDirection={['column', 'row']}
        alignItems="stretch"
        css={{gap: ['8px', '24px']}}>
        <ValuePreview
          data-testid="estimate-payment"
          label="Payment as low as"
          showAttributes={Boolean(productMatch)}
          loading={fetchingProductMatches}
          value={
            Boolean(productMatch) &&
            (productMatch?.minMonthlyPayment !== null
              ? `${toCurrency(productMatch?.minMonthlyPayment, {prefix: '$'})} / month`
              : '-')
          }
          defaultValue="$0 / month"
          flex={2}
          pointed>
          {productMatch?.product?.rateType === 'variable' && (
            <ProductMatchAttr>
              <CheckIcon /> Variable rate
            </ProductMatchAttr>
          )}
        </ValuePreview>
        <ValuePreview
          data-testid="estimate-loan-amount"
          label="Loan amount"
          showAttributes={Boolean(productMatch)}
          loading={fetchingProductMatches}
          value={
            Boolean(productMatch) &&
            `${toCurrency(estimateDetails.renovationCost, {prefix: '$'})}`
          }
          defaultValue="$0,000"
          flex={2}>
          <ProductMatchAttr>
            <CheckIcon /> Max borrowing power{' '}
            {toCurrency(productMatch?.maxLoanAmountOffered, {prefix: '$'})}
          </ProductMatchAttr>
          <ProductMatchAttr>
            <CheckIcon /> Based on After Renovation Value
          </ProductMatchAttr>
        </ValuePreview>
        <ValuePreview
          data-testid="estimate-loan-product"
          label="Loan product"
          showAttributes={Boolean(productMatch)}
          loading={fetchingProductMatches}
          value={productMatch?.product?.name}
          flex={2.5}>
          {productMatch?.product?.cashDisbursementType === 'full_upfront' && (
            <>
              <ProductMatchAttr>
                <CheckIcon /> Full loan amount available at closing
              </ProductMatchAttr>
              <ProductMatchAttr>
                <CheckIcon /> No draws and inspections
              </ProductMatchAttr>
            </>
          )}
          {!productMatch?.product?.refinanceRequired && (
            <ProductMatchAttr>
              <CheckIcon /> No refinancing required
            </ProductMatchAttr>
          )}
        </ValuePreview>
        <ValuePreview
          data-testid="estimate-terms"
          label="Terms up to"
          showAttributes={Boolean(productMatch)}
          loading={fetchingProductMatches}
          value={
            Boolean(productMatch) && `${productMatch?.product?.terms} Years`
          }
          flex={1.5}>
          <ProductMatchAttr>
            <CheckIcon /> {productMatch?.product?.drawPeriod} years draw
          </ProductMatchAttr>
          <ProductMatchAttr>
            <CheckIcon /> {productMatch?.product?.repaymentPeriod} years
            repayment
          </ProductMatchAttr>
        </ValuePreview>
      </Flex>
      <EstimateLegalWarning />
    </SubmissionSection>
  );
};

export default ProductMatch;
