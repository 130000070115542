import React, {useContext} from 'react';

import {useHistory} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {useLogin} from '@renofi/modules';
import {useScreenSize} from '@renofi/utils';

import AppContext from '../../../context';

import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const Navbar = () => {
  const {isLoggedIn, user} = useContext(AppContext);
  const {isDesktop} = useScreenSize();
  const history = useHistory();
  const {logout} = useLogin();
  const NavComponent = isDesktop ? NavbarDesktop : NavbarMobile;

  const onClickIntercom = (e) => {
    e.preventDefault();
    sendEvent(`Contractors/Intercom-Clicked`);
    if (Boolean(window.Intercom)) {
      window.Intercom('show');
    }
  };

  const onClickLogout = async (e) => {
    e.preventDefault();
    logout();

    sendEvent(`Contractors/Logout-Clicked`);
    history.push('/');
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <NavComponent
      onClickIntercom={onClickIntercom}
      onClickLogout={onClickLogout}
      user={user}
    />
  );
};

export default Navbar;
