export const VALID_DOC_TYPES = [
  'detailed_cost_estimate',
  'other',
  'renovation_contract',
  'renovation_plan',
];

export const CLIENT_STATUS_LABELS = {
  'Pre-qualified': ['converted', 'qualification', 'qualified'],
  'Uploading docs': ['uploading'],
  'Reviewing docs': ['proposal', 'ready_for_review'],
  Preparing: ['preparing'],
  'In progress': ['eligibility', 'reviewed', 'uw_queue', 'ru'],
  Applying: ['rrf_prep'],
  'With lender': ['with_lender'],
  Renovating: [
    'closed_with_lender',
    'renovation_in_progress',
    'construction_complete',
  ],
  Completed: ['completion_certificate'],
  Closed: ['closed', 'closed_lost', 'closed_lead_gen'],
  Funded: ['closed_won'],
  Postponed: ['closed_postponed'],
  Unqualified: ['unqualified'],
  Working: ['pending', 'working'],
  Nurturing: ['nurturing'],
  'First consultation': ['first_consultation'],
  Scanning: ['scanning'],
  'Concept review': ['concept_review'],
  'Design review': ['design_review'],
  'Design handover': ['design_handover'],
};
