import styled from '@emotion/styled/macro';
import {Box, Flex, Image} from 'rebass';

import {darkBlue, mediaQuery} from '@renofi/utils';

const A4_RATIO = 1.41;
const A4_WIDTH = 1080;
const A4_HEIGHT = A4_WIDTH * A4_RATIO;

export const LinkShareStepWrapper = styled(Flex)(({css}) => ({
  flex: 1,
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'start',
  backgroundColor: '#F7FAFC',
  borderRadius: 8,
  padding: 40,
  gap: 24,
  fontSize: 20,
  color: darkBlue,
  ...css,
}));

export const PdfWrapper = styled(Box)(
  mediaQuery({
    width: '100%',
    overflowX: ['auto', 'visible'],
    overflowY: 'visible',
  }),
);

export const PdfImage = styled(Image)(
  mediaQuery({
    maxWidth: ['calc(100% - 64px)', A4_WIDTH],
    width: ['calc(100% - 64px)', A4_WIDTH],
    height: ['100%', A4_HEIGHT],
    margin: [32, '40px auto'],
  }),
);

export const PdfPreview = styled(Box)(
  mediaQuery({
    maxWidth: A4_WIDTH,
    width: A4_WIDTH,
    height: A4_HEIGHT,
    margin: '40px auto',
    border: '1px solid #D8D8D8',
  }),
);
