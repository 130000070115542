import React, {useState} from 'react';

import TextField from '../TextField';

const PasswordField = ({disabled, onChange, value, ...props}) => {
  const [show, setShow] = useState(false);
  const type = show ? 'text' : 'password';

  return (
    <TextField
      disabled={disabled}
      icon={show ? 'hide' : 'show'}
      iconPosition="right"
      value={value}
      onChange={onChange}
      onClickIcon={() => setShow(!show)}
      type={type}
      {...props}
    />
  );
};

PasswordField.propTypes = {
  ...TextField.propTypes,
};

export default PasswordField;
