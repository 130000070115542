import styled from '@emotion/styled';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const AuthContainer = styled(Flex)(() =>
  mediaQuery({
    flexDirection: 'column',
    alignItems: ['center', 'start'],
    margin: 'auto',
    marginTop: [12, 28],
  }),
);
