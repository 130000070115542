import {Flex, Image, Text} from 'rebass';

import {toCurrency} from '@renofi/utils';

import calculatorSrc from '../img/calculator.svg';

import {EstimatePreviewBannerWrapper} from './styled';

const EstimatePreviewBanner = ({
  estimate,
  option,
  showAddress = false,
  imageWidth = 162,
}) => {
  const {product} = option || {};
  const loanAmount = estimate?.renovationCost || option?.loanAmount;

  return (
    <EstimatePreviewBannerWrapper>
      <Image src={calculatorSrc} width={imageWidth} />
      <Flex
        mb={[0, 30]}
        flexDirection="column"
        alignItems={['center', 'flex-start']}
        lineHeight="normal"
        color="#fff"
        css={{gap: 4}}>
        <Text fontSize={[16, 20]}>Payments starting as low as</Text>
        <Text fontSize={[40, 54]} fontWeight={700}>
          {toCurrency(option?.minMonthlyPayment, {prefix: '$'})} / month
        </Text>
        <Text fontSize={[16, 20]}>
          For a {toCurrency(loanAmount, {prefix: '$'})} {product?.name}{' '}
          {showAddress ? 'for your project at' : ''}
        </Text>
        {showAddress && estimate?.propertyAddress ? (
          <Text fontSize={[16, 20]}>{estimate?.propertyAddress}</Text>
        ) : null}
      </Flex>
    </EstimatePreviewBannerWrapper>
  );
};

export default EstimatePreviewBanner;
