import React from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {noop} from 'lodash';

import {red} from '@renofi/utils/src/colors';

import {BackWrapper} from './styled';

const BackArrow = ({color = red, size = 16, to, onClick = noop}) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) {
      history.push(to);
      return;
    }

    return onClick();
  };

  return (
    <BackWrapper size={size} onClick={handleClick}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 12.59 7.173 8 11.5 3.41 10.168 2 4.5 8l5.668 6z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </BackWrapper>
  );
};

BackArrow.propTypes = {
  to: PropTypes.string,
};

export default BackArrow;
