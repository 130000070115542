import {noop} from 'lodash';

import {useCompleteRegistration} from '@renofi/api';
import {useNotifications} from '@renofi/components';

import useCompleteAuth from './useCompleteAuth';
import {REGISTRATION_ERROR} from './constants';

export default () => {
  const {completeRegistration, loading} = useCompleteRegistration();
  const addNotification = useNotifications();
  const completeAuth = useCompleteAuth();

  const completeUserRegistration = async (
    token,
    onAuthSuccess = noop,
    onAuthError = noop,
  ) => {
    let rsp;
    try {
      rsp = await completeRegistration({variables: {token}});
      const {jwt, refreshToken, ...rest} =
        rsp?.data?.completeRegistration || {};

      if (!jwt || !refreshToken) {
        return addNotification(REGISTRATION_ERROR);
      }

      completeAuth({
        jwt,
        refreshToken,
        user: rest.user,
      });

      onAuthSuccess({rsp});
    } catch (err) {
      onAuthError({rsp});
      addNotification(REGISTRATION_ERROR);
    }

    return rsp;
  };

  return {loading, completeUserRegistration};
};
