import React from 'react';

import {Flex, Text} from 'rebass';

import {blue} from '@renofi/utils';
import {
  renofiLogoDarkIconSrc,
  renofiLogoLightIconSrc,
} from '@renofi/components';

import {LogoWrapper, LogoImage} from './styled';

const DesignLogo = ({light, ...props}) => {
  const logoImg = light ? renofiLogoLightIconSrc : renofiLogoDarkIconSrc;
  return (
    <LogoWrapper {...props}>
      <Flex alignItems="baseline">
        <LogoImage src={logoImg} alt="RenoFi" />
        <Text fontSize={44} ml={16} color={blue} fontWeight={700}>
          design
        </Text>
      </Flex>
      <Text
        mt={1}
        fontSize={16}
        fontWeight={300}
        lineHeight="40px"
        color={blue}
        css={{textTransform: 'uppercase'}}>
        for Contractors
      </Text>
    </LogoWrapper>
  );
};

export default DesignLogo;
