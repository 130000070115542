import React from 'react';

import {Flex} from 'rebass';

import {Layout} from '../components';
import {useMyAccount} from '../api';

import YourWebpages from './components/YourWebpages';
import MarketingAssets from './components/MarketingAssets';

const Marketing = () => {
  const {contractor} = useMyAccount();

  return (
    <Layout.AuthenticatedPage title="Marketing">
      <Flex flexDirection="column" css={{gap: 32}}>
        <YourWebpages contractor={contractor} />
        <MarketingAssets />
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default Marketing;
