import {Box, Text} from 'rebass';

import {Button, Label, Textarea, TextField} from '@renofi/components';
import {blue, useScreenSize} from '@renofi/utils';

import EstimateLoanOption from '../../EstimateLoanOption';
import EstimateRenoFiBenefits from '../../EstimateRenoFiBenefits';
import EstimateLegalWarning from '../../EstimateLegalWarning';
import EstimateReviews from '../../EstimateReviews';

const ComposeEmail = ({
  estimate,
  productMatch,
  subject,
  onChangeSubject,
  introduction,
  onChangeIntroduction,
  message,
  onChangeMessage,
}) => {
  const {isMobile} = useScreenSize();

  return (
    <Box p={[3, 4]}>
      <Text fontSize={18} color={blue}>
        Compose Email
      </Text>
      <Box mt={3}>
        <Label htmlFor="subject" small>
          Subject
        </Label>
        <TextField id="subject" onChange={onChangeSubject} value={subject} />
      </Box>

      <Box mt={24}>
        <Label htmlFor="introduction" small>
          Introduction
        </Label>
        <Textarea
          id="introduction"
          onChange={onChangeIntroduction}
          value={introduction}
        />
      </Box>

      <EstimateLoanOption
        loanAmount={estimate.renovationCost}
        option={productMatch}
      />

      <EstimateRenoFiBenefits />

      <EstimateLegalWarning />

      <Box py={24} textAlign="center">
        <Button
          small={isMobile}
          css={{userSelect: 'none', pointerEvents: 'none'}}>
          {isMobile
            ? 'Explore financing options'
            : 'Explore your financing options'}
        </Button>
        <Text my={20}>Or</Text>
        <Button
          small={isMobile}
          css={{
            userSelect: 'none',
            pointerEvents: 'none',
            paddingLeft: 8,
            paddingRight: 8,
          }}
          secondary>
          {isMobile
            ? 'Schedule call with an advisor'
            : 'Schedule a call with a RenoFI Advisor'}
        </Button>
      </Box>

      <EstimateReviews />

      <Box mt={24}>
        <Label htmlFor="message" small>
          Message
        </Label>
        <Textarea
          id="message"
          onChange={onChangeMessage}
          rows={12}
          value={message}
        />
      </Box>
    </Box>
  );
};

export default ComposeEmail;
