import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REMOVE_DOCUMENT} from '../mutations';

const IS_TEST = process.env.NODE_ENV === 'test';

export default function useRemoveDocument({
  onCompleted = noop,
  ...options
} = {}) {
  const refetchQueries = IS_TEST ? [] : options.refetchQueries || [];
  const [removeDocument, {data, loading, error}] = useMutation(
    REMOVE_DOCUMENT,
    {onCompleted, refetchQueries, options},
  );
  const response = data?.submitContractorProjectDocument;
  return {response, loading, removeDocument, error};
}
