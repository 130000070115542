import {useState} from 'react';

import {useScreenSize} from '@renofi/utils';
import {AddressField} from '@renofi/components';

import SubmissionSection from './SubmissionSection';
import ManualAddressEntryModal from './ManualAddressEntryModal';

const AddressSection = ({
  propertyAddress,
  onSearchValueChange,
  onAddressSelect,
}) => {
  const {isDesktop, isMobile} = useScreenSize();
  const [showAddressManualEntry, setShowAddressManualEntry] = useState(false);

  return (
    <SubmissionSection
      step={1}
      label={
        isDesktop
          ? 'Start by entering the property address'
          : 'Enter the property address'
      }
      css={{
        borderLeft: 'none',
      }}>
      <AddressField
        label={isDesktop && 'Address'}
        placeholder={isMobile ? 'Search for address' : ''}
        value={propertyAddress}
        onChange={onSearchValueChange}
        onSelect={onAddressSelect}
        onClickManual={() => setShowAddressManualEntry(true)}
      />
      {showAddressManualEntry && (
        <ManualAddressEntryModal
          show={showAddressManualEntry}
          onCancel={() => setShowAddressManualEntry(false)}
          onSubmit={onAddressSelect}
        />
      )}
    </SubmissionSection>
  );
};

export default AddressSection;
