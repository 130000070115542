import {Flex, Text, Link} from 'rebass';
import {useHistory} from 'react-router-dom';

import {toCurrency, formatDate} from '@renofi/utils';
import {CopyToClipboard} from '@renofi/components';

import {Tr, Td} from '../../../components';
import {stripStateAndZipCode} from '../../../utils';
import CheckIcon from '../CheckIcon';
import CopyIcon from '../CopyIcon';
import {trackShareableLinkCopied} from '../ShareEstimate/utils';

import EstimateStatus from './EstimateStatus';

const EstimateRow = ({estimate}) => {
  const history = useHistory();
  return (
    <Tr onClick={() => history.push(`/financing-estimates/${estimate.id}`)}>
      <Td>
        {estimate?.firstName
          ? `${estimate?.firstName} ${estimate?.lastName}`
          : '-'}
      </Td>
      <Td>{stripStateAndZipCode(estimate.propertyAddress)}</Td>
      <Td>{formatDate(new Date(estimate.createdAt))}</Td>
      <Td>
        {estimate.renovationCost
          ? `$${toCurrency(estimate.renovationCost)}`
          : '-'}
      </Td>
      <Td css={{paddingRight: '0'}}>
        <EstimateStatus estimate={estimate} />
      </Td>
      <Td css={{textAlign: 'center'}}>
        {estimate.scenarioDashboardLink ? (
          <CopyToClipboard
            textToCopy={estimate.scenarioDashboardLink}
            showToast={false}
            onClick={() => trackShareableLinkCopied(estimate)}
            successMessage={
              <Flex flexDirection="column" alignItems="center">
                <CheckIcon />
                <Text>Copied</Text>
              </Flex>
            }>
            <CopyIcon />
          </CopyToClipboard>
        ) : (
          '-'
        )}
      </Td>
      <Td css={{textAlign: 'center'}}>
        <Link
          href={`/financing-estimates/${estimate.id}`}
          css={{textDecoration: 'none'}}>
          Edit
        </Link>
      </Td>
    </Tr>
  );
};

export default EstimateRow;
