import React from 'react';

import {useLocation, matchPath} from 'react-router-dom';
import {Flex, Text} from 'rebass';

import {Button} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';
import {useContractorProjectsV2} from '@renofi/api';

import {Layout} from '../../components';
import useShowFinancingReferral from '../useShowFinancingReferral';

const PageTitle = () => {
  const {pathname} = useLocation();
  const {isMobile} = useScreenSize();
  const {setShowFinancingReferral} = useShowFinancingReferral();
  const {params = {}} =
    matchPath(pathname.split('?')[0], {
      path: '/financing/:projectId',
    }) || {};
  // Because child pages are a separte Switch instance, we don't
  // have visiblity of :projectId value that may be set.
  const {projectId} = params;
  const {projects} = useContractorProjectsV2();
  const project = projects?.find(({id}) => id === projectId);
  const isQuestionnaire = pathname.includes('/questionnaire');

  if (isQuestionnaire) {
    return (
      <Flex alignItems="center">
        <Layout.BackArrow size={32} to={`/financing/${projectId}`} />
        <Text role="page_title" fontSize={[32, 40]} as="h1">
          Due diligence questionnaire
        </Text>
      </Flex>
    );
  }
  return (
    <Flex alignItems="center">
      {Boolean(project) ? <Layout.BackArrow size={32} to="/financing" /> : null}
      <Flex
        width={1}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between">
        <Text role="page_title" fontSize={[32, 40]} as="h1">
          Your Clients
        </Text>

        <Button
          mt={isMobile ? 3 : 0}
          onClick={() => setShowFinancingReferral(true)}
          xSmall>
          Connect a client with RenoFi
        </Button>
      </Flex>
    </Flex>
  );
};

export default PageTitle;
