import {Box, Text} from 'rebass';

import {Alert} from '@renofi/components';

const FileUploadError = ({failedFiles}) => {
  return (
    <Alert mb={3} warning>
      <Text fontSize={16} fontWeight={700}>
        The following files failed to upload, please try again:
      </Text>
      <Box mt={2} pl={4}>
        <ol>
          {failedFiles.map(({file, fileName}) => {
            const bytes = file?.size || 0;
            const fileSizeKb = file?.size / 1000;
            const fileSizeMb = file?.size / 1e6;
            const isLessThan1Mb = bytes < 1e6;
            const suffix = isLessThan1Mb ? 'Kb' : 'Mb';

            return (
              <li key={fileName}>
                {fileName}
                {Boolean(file?.size) ? (
                  <Box ml={2} as="span">
                    ({(isLessThan1Mb ? fileSizeKb : fileSizeMb).toFixed(2)}{' '}
                    {suffix})
                  </Box>
                ) : null}
              </li>
            );
          })}
        </ol>
      </Box>
    </Alert>
  );
};

export default FileUploadError;
