import React from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const TrashIcon = ({
  disabled = false,
  onClick,
  color = lightGray,
  size = 24,
  ...props
}) => {
  return (
    <svg
      onClick={onClick && !disabled ? onClick : noop}
      style={{cursor: Boolean(onClick && !disabled) ? 'pointer' : 'auto'}}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70833 20.3333C4.70833 21.6625 5.79583 22.75 7.125 22.75H16.7917C18.1208 22.75 19.2083 21.6625 19.2083 20.3333V5.83333H4.70833V20.3333ZM7.68083 11.73L9.38458 10.0262L11.9583 12.5879L14.52 10.0262L16.2237 11.73L13.6621 14.2917L16.2237 16.8533L14.52 18.5571L11.9583 15.9954L9.39667 18.5571L7.69292 16.8533L10.2546 14.2917L7.68083 11.73ZM16.1875 2.20833L14.9792 1H8.9375L7.72917 2.20833H3.5V4.625H20.4167V2.20833H16.1875Z"
        fill={color}
      />
    </svg>
  );
};

export default TrashIcon;
