import {get} from 'lodash';
import {Box, Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useNotifications} from '@renofi/components';
import {logger} from '@renofi/utils';

export default ({eventName = 'Contractors/FileInput/FilesRejected'} = {}) => {
  const addNotification = useNotifications();

  return (rejectedFiles) => {
    if (!rejectedFiles?.length) {
      return;
    }

    const title = rejectedFiles.length > 1 ? 'these files' : 'this file';
    sendEvent(eventName, {rejectedFiles});
    logger.debug('files rejected', rejectedFiles);

    addNotification({
      type: 'error',
      css: {
        height: 'auto',
      },
      message: (
        <Box>
          <Text>There were problems uploading {title}:</Text>
          {rejectedFiles.map(({errors = [], file}) => {
            const reason = get(errors, [0, 'code'], 'Unknown error');
            return (
              <Flex mt={2} key={file.name}>
                <Box as="em">
                  {file.name} ({reason})
                </Box>
              </Flex>
            );
          })}
        </Box>
      ),
    });
  };
};
