import {useState} from 'react';

import {noop} from 'lodash';

import {Layout} from '../../../components';

import SubmissionForm from './SubmissionForm';
import SubmissionPreview from './SubmissionPreview';

const EstimateSubmission = ({title, estimate, onSubmit = noop}) => {
  const [editEnabled, setEditEnabled] = useState(false);

  return (
    <Layout.Panel contentCss={{paddingBottom: 0}}>
      <Layout.PanelTitleV2>{title}</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        {(!estimate || editEnabled) && (
          <SubmissionForm
            existingEstimate={estimate}
            onSubmit={(estimate) => {
              setEditEnabled(false);
              onSubmit(estimate);
            }}
          />
        )}
        {estimate && !editEnabled && (
          <SubmissionPreview
            estimate={estimate}
            onEdit={() => setEditEnabled(true)}
          />
        )}
      </Layout.PanelInnerWrapper>
    </Layout.Panel>
  );
};

export default EstimateSubmission;
