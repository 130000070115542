import {useMutation} from '@apollo/client';

import {UPDATE_CONTRACTOR} from '../mutations';

export default function useUpdateContractor() {
  const [updateContractor, {data, loading, error}] =
    useMutation(UPDATE_CONTRACTOR);
  const response = data?.updateContractor;
  return {updateContractor, response, loading, error};
}
