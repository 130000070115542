import React from 'react';

import {Box, Flex, Text} from 'rebass';

import {formatIsoDate, humanizeCase} from '@renofi/utils';

const QUESTIONNAIRE_STATUSES = ['submitted', 'started', 'not_started'];

const CddReviewTimestamps = ({
  questionnaire,
  overallStatus,
  statusTimestamp,
}) => {
  const {updatedAt} = questionnaire || {};
  const isStillQuestionnaire = QUESTIONNAIRE_STATUSES.includes(overallStatus);
  const prefix = isStillQuestionnaire ? 'Questionnaire' : 'Due diligence';

  return (
    <Flex alignItems="flex-end">
      <Box mr={4}>
        <Text fontSize={16} as="strong">
          {prefix} status
        </Text>
        <Text>
          {humanizeCase(overallStatus)}
          {statusTimestamp ? `: ${formatIsoDate(statusTimestamp)}` : ''}
        </Text>
      </Box>
      {isStillQuestionnaire ? (
        <Text>Last edited {formatIsoDate(updatedAt)}</Text>
      ) : null}
    </Flex>
  );
};

export default CddReviewTimestamps;
