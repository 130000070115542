import {Box, Flex, Text} from 'rebass';

import {Label, TextField} from '@renofi/components';
import {blueGray, darkGray, gray} from '@renofi/utils';

const EmailRecipients = ({estimate, cc, onChange, error}) => {
  return (
    <>
      <Flex mb={3} css={{gap: 8}} px={[3, 4]} alignItems="center">
        <Text width={25} textAlign="right" color={gray} fontSize={14}>
          To
        </Text>
        <Text color={darkGray} fontSize={16}>
          {estimate?.email || '—'}
        </Text>
      </Flex>

      <Flex
        css={{borderBottom: `1px solid ${blueGray}`, gap: 8}}
        px={[3, 4]}
        pb={24}
        alignItems="center">
        <Label
          htmlFor="cc"
          width={25}
          justifyContent="flex-end"
          color={gray}
          fontWeight="normal"
          fontSize={14}>
          CC
        </Label>

        <Box flex={1}>
          <TextField
            id="cc"
            flex={1}
            error={error}
            onChange={onChange}
            placeholder="CC email addresses"
            value={cc}
          />
        </Box>
      </Flex>
    </>
  );
};

export default EmailRecipients;
