import styled from '@emotion/styled';
import {Box, Flex, Text} from 'rebass';

import {Content, Header} from '@renofi/components/src/Modal/styled';
import {mediaQuery} from '@renofi/utils';
import {darkGray, darkGreen} from '@renofi/utils/src/colors';
import {Alert, Td as CommonTd, Th as CommonTh} from '@renofi/components';

const tableBorder = '1px solid #D8D8D8';

export const FlexContainer = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    flexDirection: ['column', 'row'],
    justifyContent: 'space-between',
  }),
);

export const Title = styled(Text)({
  fontSize: 24,
  fontWeight: 700,
});

export const modalCustomStyles = {
  [Header]: {
    background: 'white',
    borderBottom: tableBorder,
    color: darkGray,
  },
  [Content]: {
    padding: 0,
    display: 'flex',
    height: ['50%', '100%'],
    justifyContent: 'space-between',
  },
};

export const AssetContainer = styled(Box)(
  mediaQuery({
    background: '#F8F8F8',
    padding: 16,
    borderRadius: 16,
    flex: 1,
  }),
);

export const DownloadWrapper = styled(Box)(
  mediaQuery({
    position: 'relative',
    cursor: 'pointer',

    button: {
      position: 'absolute',
      top: 'calc(50% - 17px)', // xSmall button size is 34px
      left: 8,
      width: 'calc(100% - 16px)',
    },
  }),
);

export const IntroAlert = styled(Alert)(({variant}) => ({
  lineHeight: '24px',
  a: {
    color: variant === 'success' ? darkGreen : '#006099',
  },
}));

export const Td = styled(CommonTd)(
  mediaQuery({
    fontSize: [14, 16],
  }),
);

export const Th = styled(CommonTh)(
  mediaQuery({
    fontSize: [14, 16],
  }),
);

export const Tr = styled.tr(({disabled, noHover}) => ({
  ...(disabled
    ? {
        pointerEvents: 'none',
        opacity: 0.4,
      }
    : {}),
  ...(noHover
    ? {
        cursor: 'default !important',
        '&:hover': {
          td: {
            backgroundColor: '#fff !important',
          },
        },
      }
    : {}),
}));
