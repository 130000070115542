export const ADDRESS_CELL_WIDTH = 350;

export const FILTER_OPTIONS = {
  entityDesignStatus: [
    'unqualified',
    'first_consultation',
    'scanning',
    'concept_review',
    'design_review',
    'design_handover',
    'nurturing',
  ],

  entityLeadStatus: [
    'unqualified',
    'pending',
    'working',
    'nurturing',
    'converted',
    'qualified',
  ],

  entityProjectStatus: [
    [
      'closed',
      'closed_lead_gen',
      'closed_lost',
      'closed_postponed',
      'closed_with_lender',
      'closed_won',
    ],
    'completion_certificate',
    'construction_complete',
    'converted',
    'eligibility',
    'preparing',
    'proposal',
    'qualification',
    'ready_for_review',
    'renovation_in_progress',
    'reviewed',
    'rrf_prep',
    'ru',
    'uploading',
    'uw_queue',
    'with_lender',
  ],
};
