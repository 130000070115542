import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {noop} from 'lodash';

import {isEmail} from '@renofi/utils/src/validate';
import {TextField} from '@renofi/components';

const EmailField = ({
  value,
  invalidEmail,
  placeholder = 'Email address',
  onChange = noop,
  ...props
}) => {
  const [email, setEmail] = useState(value);

  useEffect(() => {
    if (!value) {
      setEmail('');
    }
  }, [value]);

  const handleChange = (email) => {
    setEmail(email);
    if (isEmail(email)) {
      onChange(email);
    }
  };

  return (
    <TextField
      error={
        email
          ? isEmail(email) && !invalidEmail
            ? null
            : 'Valid email required'
          : null
      }
      onChange={handleChange}
      style={{position: 'relative'}}
      placeholder={placeholder}
      value={email}
      {...props}
    />
  );
};

EmailField.propTypes = {
  invalidEmail: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default EmailField;
