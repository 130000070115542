import {Flex, Text, Button} from 'rebass';

import {gray} from '@renofi/utils';
import {
  FileTypeIcon,
  formatBytes,
} from '@renofi/components/src/UploadDocuments/utils';
import {TrashIcon} from '@renofi/components/src/UploadDocuments/img';

const UploadedFile = ({file, onRemove}) => {
  return (
    <Flex
      data-testid={`uploaded_file_${file.fileName}`}
      p={12}
      pr={24}
      mt={16}
      backgroundColor="#FAFAFA"
      justifyContent="space-between"
      css={{border: '1px solid #E9E9E9', borderRadius: 8}}>
      <Flex alignItems="center" css={{gap: 20}}>
        <FileTypeIcon fileName={file.fileName} />
        <Text>{file.fileName}</Text>
      </Flex>

      <Flex alignItems="center" css={{gap: 20}}>
        <Text color={gray}>{formatBytes(file.file.size)}</Text>
        <Button
          p={0}
          backgroundColor="transparent"
          css={{'&:hover': {cursor: 'pointer'}}}
          onClick={() => onRemove(file)}>
          <TrashIcon />
        </Button>
      </Flex>
    </Flex>
  );
};

export default UploadedFile;
