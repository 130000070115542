import {Flex, Text} from 'rebass';

import {ESTIMATE_STATUSES} from './constants';
import EstimateStatusIcon from './EstimateStatusIcon';

const getStatus = (estimate) => {
  if (estimate.scenarioId && estimate.totalClicks) {
    const totalClicks = estimate.totalClicks;
    return {
      status: ESTIMATE_STATUSES.VIEWED,
      label: `${totalClicks} view${totalClicks > 1 ? 's' : ''}`,
    };
  } else if (estimate.scenarioId) {
    return {
      status: ESTIMATE_STATUSES.COMPLETE,
      label: 'Estimate created',
    };
  } else {
    return {
      status: ESTIMATE_STATUSES.INCOMPLETE,
      label: 'Incomplete',
    };
  }
};

const EstimateStatus = ({estimate}) => {
  const status = getStatus(estimate);

  return (
    <Flex>
      <EstimateStatusIcon status={status.status} />
      <Text fontSize={14}>{status.label}</Text>
    </Flex>
  );
};

export default EstimateStatus;
