import {useQuery} from '@apollo/client';

import {GET_CONTRACTOR_ESTIMATE_BY_ID} from '../queries';

export default function useContractorEstimateById({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, loading, refetch} = useQuery(GET_CONTRACTOR_ESTIMATE_BY_ID, {
    fetchPolicy,
    ...options,
  });
  const estimate = data?.contractorEstimateById;

  return {estimate, loading, refetch};
}
