import {Text} from 'rebass';

import {gray} from '@renofi/utils';

const EstimateLegalWarning = () => {
  return (
    <Text fontSize={10} lineHeight="normal" color={gray} mt={24} mb={32}>
      NOT PERSONALIZED LOAN OFFER. Sample payment based on lowest interest rate
      offered by lender of 8.5% (as of 5/9/24) for a variable-rate HELOC with a
      10-year draw period (interest-only monthly payments required) and 15-year
      repayment period (principal + interest monthly payments required),
      available to applicants with a credit score of at least 740, and assumes
      debt-to-income, a zero balance first mortgage and combined loan-to-value
      ratios within lender guidelines and 100% return of investment on
      renovation financing. Variable rate determined by prime rate plus lender’s
      margin. Rate and loan program terms subject to change without notice.
      Closing costs and fees apply.
    </Text>
  );
};

export default EstimateLegalWarning;
