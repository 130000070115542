import {Flex, Box, Text} from 'rebass';

import {CheckIcon} from '@renofi/components';

import {EstimatePreviewPanel} from './styled';

const RENOFI_BENEFITS = [
  'Keep existing mortgage, without refinancing.',
  'Borrow up to 90% of the AFTER renovation value.',
  'Pay early without repayment penalties.',
  'Choose between fixed rate and variable rate home equity loan and HELOC options.',
];

const EstimateRenoFiBenefits = () => (
  <EstimatePreviewPanel>
    <Text fontSize={[24, 28]} mb={16}>
      Why choose a RenoFi loan?
    </Text>
    {RENOFI_BENEFITS.map((benefit, i) => (
      <Flex key={i} mb="4px" fontSize={14} css={{gap: 10}}>
        <Box flex="0 0 auto">
          <CheckIcon width={20} height={20} />
        </Box>
        {benefit}
      </Flex>
    ))}
  </EstimatePreviewPanel>
);

export default EstimateRenoFiBenefits;
