import React, {isValidElement} from 'react';

import {Text} from 'rebass';

import {PanelDark} from '@renofi/components';
import {darkGray, blueGray} from '@renofi/utils';

export const PanelTitle = ({children, ...props}) => (
  <Text
    role="card_title"
    mb={2}
    fontSize={[20, 24]}
    color={darkGray}
    fontWeight={700}
    {...props}>
    {children}
  </Text>
);

const Panel = ({children, css, title, ...props}) => {
  return (
    <PanelDark
      noShadow
      css={{boxShadow: 'none', border: `1px solid ${blueGray}`, ...css}}
      {...props}>
      {title ? (
        isValidElement(title) ? (
          title
        ) : (
          <PanelTitle>{title}</PanelTitle>
        )
      ) : null}
      {children}
    </PanelDark>
  );
};

export default Panel;
