import {useApolloClient} from '@apollo/client';

import useToggledQuery from '../../useToggledQuery';
import {GET_CURRENT_USER} from '../queries';

export default function useCurrentUser({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const client = useApolloClient();
  const {data, fetch, loading, refetch} = useToggledQuery(GET_CURRENT_USER, {
    lazy,
    fetchPolicy,
    ...options,
  });
  const user = data?.result;

  const removeUser = async () => {
    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        result: null,
      },
    });
  };

  return {fetch, loading, refetch, removeUser, user};
}
