import {useState, useEffect} from 'react';

import {Flex, Box, Text} from 'rebass';

import {blueGray} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';
import {Modal, TextField, Label, Button} from '@renofi/components';

const ManualAddressEntryModal = ({show, onSubmit, onCancel}) => {
  const [address, setAddress] = useState({});

  useEffect(() => {
    sendEvent('Contractors/Financing-Estimate-Manual-Address-Entry-Opened');
  }, []);

  const handleChange = (key) => (value) => {
    setAddress({
      ...address,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    sendEvent('Contractors/Address-Manually-Entered');
    onSubmit(address);
    onCancel();
  };

  return (
    <Modal
      hideCloseButton
      light
      show={show}
      contentCss={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      title={
        <Text width="100%" textAlign="left">
          Where is the project happening
        </Text>
      }>
      <Flex
        flexDirection="column"
        width="100%"
        padding={['30px 20px', '48px 40px']}
        css={{borderTop: `1px solid ${blueGray}`}}>
        <Label fontSize={14} htmlFor="streetAddress">
          Address
        </Label>
        <TextField
          id="streetAddress"
          name="streetAddress"
          value={address.streetAddress}
          onChange={handleChange('streetAddress')}
        />
        <Flex
          flexDirection={['column', 'row']}
          mt={32}
          width="100%"
          css={{gap: 24}}>
          <Box flex={1}>
            <Label fontSize={14} htmlFor="city">
              City
            </Label>
            <TextField
              id="city"
              name="city"
              value={address.city}
              onChange={handleChange('city')}
            />
          </Box>
          <Box flex={1}>
            <Label fontSize={14} htmlFor="county">
              County
            </Label>
            <TextField
              id="county"
              name="county"
              value={address.county}
              onChange={handleChange('county')}
            />
          </Box>
        </Flex>
        <Flex
          flexDirection={['column', 'row']}
          mt={32}
          width="100%"
          css={{gap: 24}}>
          <Box flex={1}>
            <Label fontSize={14} htmlFor="state">
              State
            </Label>
            <TextField
              id="state"
              name="state"
              value={address.state}
              onChange={handleChange('state')}
            />
          </Box>
          <Box flex={1}>
            <Label fontSize={14} htmlFor="zipCode">
              Zip code
            </Label>
            <TextField
              id="zipCode"
              name="zipCode"
              value={address.zipCode}
              onChange={handleChange('zipCode')}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        padding="16px 32px"
        justifyContent="flex-end"
        backgroundColor="#F7FAFC"
        css={{gap: 16}}>
        <Button xSmall secondary css={{width: 90}} onClick={onCancel}>
          Cancel
        </Button>
        <Button xSmall css={{width: 90}} onClick={handleSubmit}>
          Add
        </Button>
      </Flex>
    </Modal>
  );
};

export default ManualAddressEntryModal;
