import {useQuery} from '@apollo/client';

import {LINK_ANALYTICS_SUMMARIES} from '../queries/linkAnalyticsSummaries';

export default function useLinkAnalyticsSummaries({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, loading, refetch} = useQuery(LINK_ANALYTICS_SUMMARIES, {
    fetchPolicy,
    ...options,
  });
  const linkAnalyticsSummaries = data?.linkAnalyticsSummaries || [];

  return {linkAnalyticsSummaries, loading, refetch};
}
