import {useLazyQuery} from '@apollo/client';

import {INTERCOM_IDENTITY} from '../queries';

export default function useIntercomIdentity(options = {}) {
  const [fetch, {data, loading}] = useLazyQuery(INTERCOM_IDENTITY, options);

  return {
    fetch,
    loading,
    intercomIdentity: data?.result,
  };
}
