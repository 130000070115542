import React, {useState} from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const LogoutIcon = ({
  hoverColor = lightGray,
  onClick,
  color = lightGray,
  size = 24,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      onClick={onClick ? onClick : noop}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      width={size}
      height={size}
      viewBox="-4 -4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="first"
        d="m10.304 11.89-1.332 1.332L4.25 8.5l4.722-4.722 1.332 1.331-2.437 2.447H17v1.888H7.867l2.437 2.447ZM1.889 0H15.11C16.16 0 17 .85 17 1.889v3.778h-1.889V1.889H1.89V15.11H15.11v-3.778H17v3.778C17 16.15 16.16 17 15.111 17H1.89A1.894 1.894 0 0 1 0 15.111V1.89C0 .85.85 0 1.889 0Z"
        fill={hover ? hoverColor : color}></path>
      <path
        className="second"
        d="m10.304 11.89-1.332 1.332L4.25 8.5l4.722-4.722 1.332 1.331-2.437 2.447H17v1.888H7.867l2.437 2.446Z"
        fill={hover ? hoverColor : color}></path>
    </svg>
  );
};

export default LogoutIcon;
