import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const FilterIcon = ({color = lightGray, onClick, size = 16, ...props}) => {
  return (
    <svg
      onClick={onClick ? onClick : noop}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 12.6667H9.33333V11.3334H6.66667V12.6667ZM2 4.66669V6.00002H14V4.66669H2ZM4 9.33335H12V8.00002H4V9.33335Z"
        fill={color}
      />
    </svg>
  );
};

export default FilterIcon;
