import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REFER_CLIENT_FOR_DAAS} from '../mutations';

export default function useReferClientForDaas({
  awaitRefetchQueries = false,
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [referClientForDaas, {data, loading, error}] = useMutation(
    REFER_CLIENT_FOR_DAAS,
    {
      awaitRefetchQueries,
      onCompleted,
      refetchQueries,
      ...options,
    },
  );

  const response = data?.referClientForDaas;
  return {referClientForDaas, response, loading, error};
}
