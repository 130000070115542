export const EXAMPLE_ESTIMATE = {
  id: 1,
  contractorId: '00000000-0000-0000-0000-000000000000',
  email: 'test@renofi.dev',
  firstName: 'John',
  lastName: 'Smith',
  phoneNumber: '2125559966',
  propertyAddress: '234 Scranton Road, Boyers, PA',
  propertyZipCode: '16020',
  createdAt: '05/15/2024',
  updatedAt: '05/17/2024',
  renovationCost: 183000,
  scenarioId: '11111111-1111-1111-1111-111111111111',
  estimatedCurrentPropertyValue: 365234,
  scenarioDashboardLink: 'https://l.renofi.dev/Z6Zbe',
  scenarioDashboardLinkQrCodeUrl:
    'https://google.renofi.dev/assets/qr_code.png',
};

export const EXAMPLE_LOAN_OPTIONS = [
  {
    loanAmount: 100000,
    minMonthlyPayment: 1250,
    maxLoanAmountOffered: 500000,
    interestOnly: true,
    product: {
      drawPeriod: 10,
      name: 'RenoFi HELOC Loan',
      rateType: 'variable',
      repaymentPeriod: 15,
      terms: 15,
    },
  },
];

export const ESTIMATES_PER_PAGE = 30;
