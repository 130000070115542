import React from 'react';

import {Flex} from 'rebass';

import {Layout} from '../components';

import {DesignUserGuide, ClientSubmission, DesignRequests} from './components';

const DesignWithTabs = () => {
  return (
    <Layout.AuthenticatedPage title="Design">
      <Flex flexDirection="column" css={{gap: 32}}>
        <DesignUserGuide />
        <ClientSubmission />
        <DesignRequests />
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default DesignWithTabs;
