import {useState} from 'react';

import {Flex, Text, Box, Image, Link} from 'rebass';

import {blue, fadeBlue, copyToClipboard} from '@renofi/utils';
import {Button} from '@renofi/components';

import {ReactComponent as CopyIcon} from '../img/copy-icon.svg';

const WebPageCard = ({testId, title, description, img, url, children}) => {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleClick = () => {
    copyToClipboard(url);
    setLinkCopied(true);
    const timeout = setTimeout(() => {
      setLinkCopied(false);
      clearTimeout(timeout);
    }, 2000);
  };

  return (
    <Flex
      flex={1}
      width="100%"
      height="100%"
      flexDirection="column"
      alignItems="center">
      <Flex flexDirection="column" alignItems="center" px={16}>
        <Text color={blue} fontSize={28}>
          {title}
        </Text>
        <Text mt={12} textAlign="center" color={fadeBlue} maxWidth={369}>
          {description}
        </Text>
        <Box
          mt={20}
          css={{
            position: 'relative',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          maxWidth={514}
          backgroundColor="#FFF">
          {children}
          <Image src={img} height="100%" />
        </Box>
      </Flex>
      <Flex
        pt={24}
        pb={[32, 0]}
        px={16}
        backgroundColor="#FFF"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%">
        <Link
          mb={34}
          href={url}
          color={blue}
          target="_blank"
          textAlign="center">
          {url}
        </Link>
        <Button
          data-testid={testId}
          onClick={handleClick}
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            justifyContent: 'center',
          }}>
          <CopyIcon />
          {!linkCopied && 'Copy link to clipboard'}
          {linkCopied && 'Link copied to clipboard'}
        </Button>
      </Flex>
    </Flex>
  );
};

export default WebPageCard;
