import {Flex, Box, Text} from 'rebass';

import {darkBlue, toCurrency, mediaQuery, blueGray} from '@renofi/utils';
import {Button} from '@renofi/components';

const SubmissionPreview = ({estimate, onEdit}) => (
  <Flex
    padding="19px 32px"
    justifyContent="space-between"
    alignItems={['flex-start', 'center']}
    flexDirection={['column', 'row']}
    css={{borderTop: `1px solid ${blueGray}`}}>
    <Flex
      mb={[24, 0]}
      flexDirection={['column', 'row']}
      css={mediaQuery({gap: [24, 56]})}>
      <Box>
        <Text fontSize={14}>Property address</Text>
        <Text mt="8px" fontSize={18} color={darkBlue}>
          {estimate?.propertyAddress}
        </Text>
      </Box>
      <Box>
        <Text fontSize={14}>Est. Project Cost</Text>
        <Text mt="8px" fontSize={18} color={darkBlue}>
          ${toCurrency(estimate?.renovationCost)}
        </Text>
      </Box>
      {estimate?.estimatedCurrentPropertyValue && (
        <Box>
          <Text fontSize={14}>Est. Current Property Value</Text>
          <Text mt="8px" fontSize={18} color={darkBlue}>
            ${toCurrency(estimate?.estimatedCurrentPropertyValue)}
          </Text>
        </Box>
      )}
    </Flex>
    <Button secondary small xSmall onClick={onEdit}>
      Edit
    </Button>
  </Flex>
);

export default SubmissionPreview;
