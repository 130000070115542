import {Flex, Box, Text, Image, Link} from 'rebass';

import {darkBlue, fadeYellow, mediaQuery} from '@renofi/utils';
import {Alert} from '@renofi/components';

import {Layout} from '../../components';
import branchLinesSrc from '../img/branch-lines.svg';
import renofiComCobrandedSrc from '../img/renofi-com-cobranded.png';
import renofiDesignCobrandedSrc from '../img/renofi-design-cobranded.png';
import lightBulbSrc from '../img/lightbulb.svg';

import WebPageCard from './WebPageCard';

const YourWebpages = ({contractor}) => {
  return (
    <Layout.Panel css={{overflow: 'hidden'}} contentCss={{padding: '0'}}>
      <Flex
        flexDirection="column"
        alignItems="center"
        backgroundColor={darkBlue}
        pt={40}>
        <Text color="#FFF" fontSize={[32, 40]}>
          Your webpages
        </Text>
        <Image
          display={['none', 'block']}
          width={572}
          my={16}
          src={branchLinesSrc}
        />
        <Flex
          mt={[28, 0]}
          width="100%"
          justifyContent="space-around"
          alignItems="flex-end"
          css={mediaQuery({gap: [40, 0], flexDirection: ['column', 'row']})}>
          <WebPageCard
            testId="renofi-financing"
            title="Renovation Financing"
            description="Help your homeowners discover what financing options are available to them."
            img={renofiComCobrandedSrc}
            url={`https://pros.${process.env.REACT_APP_RENOFI_TLD}/pros/${contractor?.urlSlug}`}>
            <Box
              position="absolute"
              css={mediaQuery({
                position: 'absolute',
                top: [-1, '6px'],
                left: [40, 73],
              })}>
              <Text fontSize={['6px', 12]} fontWeight={900} color={darkBlue}>
                YOUR LOGO HERE
              </Text>
            </Box>
          </WebPageCard>
          <WebPageCard
            testId="renofi-design"
            title="Conceptual 3D designs"
            description="Connect your clients with one of our designers to get quick, 3D conceptual designs for their project."
            img={renofiDesignCobrandedSrc}
            url={`${process.env.REACT_APP_RENOFI_DESIGN_URL}${contractor?.urlSlug}`}>
            <Box
              position="absolute"
              css={mediaQuery({
                position: 'absolute',
                top: ['-2px', '3px'],
                left: [85, 134],
              })}>
              <Text fontSize={['6px', 12]} fontWeight={900} color={darkBlue}>
                YOUR LOGO HERE
              </Text>
            </Box>
          </WebPageCard>
        </Flex>
        <Flex
          padding={['24px 16px', '24px 32px 32px 32px']}
          justifyContent="center"
          size="100%"
          backgroundColor="#FFF">
          <Alert
            css={{backgroundColor: fadeYellow, border: 'none', maxWidth: 1214}}>
            <Flex alignItems={['start', 'center']} css={{gap: 16}}>
              <Image flexShrink={0} src={lightBulbSrc} />
              <Text textAlign="left" color={darkBlue}>
                <strong>Tip:</strong> link to these pages from your website,
                blog, social profiles or simply share your links directly with
                homeowners. This will ensure that clients that come from your
                pages will be attributed to your profile and will appear in your
                clients report. Email{' '}
                <Link color={darkBlue} href="mailto:learnmore@renofi.com">
                  learnmore@renofi.com
                </Link>{' '}
                if you have any questions.
              </Text>
            </Flex>
          </Alert>
        </Flex>
      </Flex>
    </Layout.Panel>
  );
};

export default YourWebpages;
