import React, {useState} from 'react';

import {Image, Text} from 'rebass';

import {ArrowIcon, ClickOutside, CopyToClipboard} from '@renofi/components';

import {EMAIL_ADDRESS, PHONE_NUMBER} from '../../../constants';
import {useContactUsCallbacks} from '../../../utils';

import alternateEmail from './alternate_email.svg';
import contactSupport from './contact_support.svg';
import intercom from './intercom.svg';
import phoneAndroid from './phone_android.svg';
import today from './today.svg';
import {DropDownWrapper, MenuItem, MenuWrapper} from './styled';

const ContactUs = ({user}) => {
  const [showMenu, setShowMenu] = useState(false);
  const {onClickCalendarLink, onClickEmail, onClickIntercom, onClickPhone} =
    useContactUsCallbacks({user});

  return (
    <ClickOutside onClickOutside={() => setShowMenu(false)}>
      <DropDownWrapper onClick={() => setShowMenu(!showMenu)}>
        <Image css={{opacity: 0.3}} src={contactSupport} />
        <Text role="contact_us" fontSize={18} ml={2} mr={3}>
          Contact us
        </Text>
        <ArrowIcon direction={showMenu ? 'up' : 'down'} size={12} />

        {showMenu ? (
          <MenuWrapper role="contact_us_options">
            <MenuItem onClick={onClickPhone}>
              <Image css={{width: 24, height: 24}} src={phoneAndroid} />
              Call {PHONE_NUMBER}
            </MenuItem>
            <MenuItem onClick={onClickCalendarLink}>
              <Image css={{width: 24, height: 24}} src={today} />
              Schedule a call
            </MenuItem>
            <MenuItem onClick={onClickEmail}>
              <Image css={{width: 24, height: 24}} src={alternateEmail} />
              {EMAIL_ADDRESS}

              <CopyToClipboard
                fontSize={12}
                textToCopy={EMAIL_ADDRESS}
                toastMessage="Email address copied!">
                Copy
              </CopyToClipboard>
            </MenuItem>
            <MenuItem onClick={onClickIntercom}>
              <Image css={{width: 24, height: 24}} src={intercom} />
              Chat
            </MenuItem>
          </MenuWrapper>
        ) : null}
      </DropDownWrapper>
    </ClickOutside>
  );
};

export default ContactUs;
