import {useMemo} from 'react';

import {useLocation} from 'react-router-dom';
import {Flex, Box, Text} from 'rebass';

import {lightGray, red} from '@renofi/utils';

import {ACCOUNT_SETUP_PATHS} from '../../constants';

import Logo from './Logo';

const OnboardingHeader = () => {
  const accountSetupPaths = Object.values(ACCOUNT_SETUP_PATHS);
  const steps = accountSetupPaths.length;

  const location = useLocation();
  const currentStep = useMemo(
    () => accountSetupPaths.findIndex((p) => p === location.pathname) + 1,
    [location.pathname],
  );

  const isAccountSetupPage = accountSetupPaths.includes(location.pathname);
  const progress = isAccountSetupPage
    ? `${(currentStep / steps) * 100 - 2}%`
    : 0;

  return (
    <Flex flexDirection="column">
      <Flex
        padding={['32px 19px 22px 19px', 32]}
        alignItems="baseline"
        justifyContent="space-between">
        <Logo flexDirection="row" />
        {isAccountSetupPage && (
          <Flex fontSize={[14, 16]}>
            <Text>Step {currentStep}</Text>
            &nbsp;
            <Text color={lightGray}>of {steps}</Text>
          </Flex>
        )}
      </Flex>
      <Box width="100%" height="1px" backgroundColor={lightGray}>
        <Box width={progress} height="100%" backgroundColor={red} />
      </Box>
    </Flex>
  );
};

export default OnboardingHeader;
