import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REQUEST_USER_REGISTRATION} from '../mutations';

export default function useRequestUserRegistration({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [requestUserRegistration, {data, loading, error}] = useMutation(
    REQUEST_USER_REGISTRATION,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const success = Boolean(data?.requestUserRegistration?.success);
  return {loading, error, requestUserRegistration, success};
}
