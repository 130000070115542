import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

export const ProductMatchAttr = styled(Flex)({
  alignItems: 'center',
  fontSize: 14,
  '& svg': {
    flexShrink: 0,
  },
});
