import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {UPDATE_CONTRACTOR_PROJECT_DOCUMENT} from '../mutations';

export default function useUpdateContractorProjectDocument({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [updateContractorProjectDocument, {data, loading, error}] = useMutation(
    UPDATE_CONTRACTOR_PROJECT_DOCUMENT,
    {onCompleted, refetchQueries, options},
  );
  const response = data?.updateContractorProjectDocument;
  return {response, loading, updateContractorProjectDocument, error};
}
