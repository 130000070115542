import {Box, Image} from 'rebass';

import {useScreenSize} from '@renofi/utils';

import defaultAssetsPreviewSrc from '../img/assets-preview.png';
import coBrandedAssetsPreviewSrc from '../img/co-branded-assets-preview.png';
import flyersArrowSrc from '../img/flyers-arrow.png';
import qrCodeArrowSrc from '../img/qr-code-arrow.png';
import flyersArrowMobSrc from '../img/flyers-arrow-mob.png';
import qrCodeArrowMobSrc from '../img/qr-code-arrow-mob.png';

const AssetsPreview = ({hasLogo}) => {
  const {isMobile} = useScreenSize();

  return (
    <Box mt={40} css={{position: 'relative'}}>
      {isMobile && (
        <>
          <Image
            src={flyersArrowMobSrc}
            css={{position: 'absolute', top: '10%'}}
          />
          <Image
            src={qrCodeArrowMobSrc}
            css={{position: 'absolute', right: 15, top: '15%'}}
          />
        </>
      )}
      {!isMobile && (
        <>
          <Image
            src={flyersArrowSrc}
            css={{position: 'absolute', top: '20%'}}
          />
          <Image
            src={qrCodeArrowSrc}
            css={{position: 'absolute', right: 0, top: '40%'}}
          />
        </>
      )}
      <Image
        src={hasLogo ? coBrandedAssetsPreviewSrc : defaultAssetsPreviewSrc}
      />
    </Box>
  );
};

export default AssetsPreview;
