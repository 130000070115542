import React, {useState} from 'react';

import {Box, Flex} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useRequestUserRegistration, useValidateEmail} from '@renofi/api';
import {Button, Form, useNotifications} from '@renofi/components';

import EmailField from '../EmailField';

const ERROR_MSG = 'There was a problem registering this email';

const accountCategory = 'contractor';

const UserRegistration = () => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState('');
  const addNotification = useNotifications();
  const {loading: isRegistering, requestUserRegistration} =
    useRequestUserRegistration();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const isDisabled = isRegistering || !email || invalidEmail;

  const onSubmit = async () => {
    let success = false;
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    try {
      const rsp = await requestUserRegistration({
        variables: {accountCategory, email},
      });
      success = Boolean(rsp?.data?.requestUserRegistration?.success);
      const eventName = success
        ? 'Contractors/User-Registation-Success'
        : 'Contractors/User-Registation-Fail';

      sendEvent(eventName, {email});
      addNotification({
        type: success ? 'success' : 'error',
        message: success
          ? 'Email registered; check your inbox to continue'
          : ERROR_MSG,
      });
    } catch (e) {
      sendEvent('Contractors/User-Registation-Error', {
        message: e.message,
        email,
      });
      addNotification({
        type: 'error',
        message: ERROR_MSG,
      });
    }
  };

  return (
    <Form id="user-registration" onSubmit={onSubmit}>
      <Flex p={3} alignItems="center" css={{gap: 16}} width={1}>
        <Box flex={0.75}>
          <EmailField
            id="email"
            value={email}
            invalidEmail={invalidEmail}
            onChange={(email) => {
              setEmail(email);
              setInvalidEmail(false);
            }}
          />
        </Box>
        <Button
          role="register_user_btn"
          disabled={isDisabled}
          loading={isRegistering || loadingValidateEmail}
          small>
          Register
        </Button>
      </Flex>
    </Form>
  );
};

export default UserRegistration;
