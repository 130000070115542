import styled from '@emotion/styled';
import {Flex, Image, Box} from 'rebass';

import {Table as CommonTable} from '@renofi/components';
import {mediaQuery, darkBlue} from '@renofi/utils';

import {
  MID_BREAKPOINT,
  NAVBAR_DESKTOP_MID_WIDTH,
  NAVBAR_DESKTOP_WIDTH,
} from './constants';

const tableBorder = '1px solid #D8D8D8';
const containerBg = {
  background:
    'linear-gradient(180deg, #F7FCFF 0%, #E9F5FD 60.33%, #FCFEFF 79.88%)',
  position: 'relative',

  ':after': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 79,
    content: '""',
    backgroundImage: 'url(/img/footer.svg)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: '100% 100%',
  },
};

export const LogoWrapper = styled(Flex)(({isRow, css}) =>
  mediaQuery({
    alignItems: isRow ? 'baseline' : 'center',
    flexDirection: isRow ? 'row' : 'column',
    gap: isRow ? [10, 14] : 0,
    ...css,
  }),
);

export const BackWrapper = styled(Box)(({size}) => ({
  display: 'inline-block',
  width: size,
  height: size,
  cursor: 'pointer',
  marginRight: 16,
}));

export const SignupContainer = styled(Flex)(
  () => ({
    width: '100%',
    flexDirection: 'column',
    minHeight: '100vh',
    ...containerBg,
  }),
  ({css}) => mediaQuery(css),
);

export const Container = styled(Flex)(
  () =>
    mediaQuery({
      width: '100%',
      flexDirection: ['column', 'row'],
      justifyContent: 'flex-start',
      paddingLeft: [0, NAVBAR_DESKTOP_WIDTH],
      minHeight: '100vh',
      ...containerBg,

      [MID_BREAKPOINT]: {
        paddingLeft: NAVBAR_DESKTOP_MID_WIDTH,
      },
    }),
  ({css}) => mediaQuery(css),
);

export const InnerContainer = styled(Flex)(
  () =>
    mediaQuery({
      width: '100%',
      flexDirection: 'column',
      padding: [20, '50px 100px'],
      paddingBottom: [102, 150],
      height: '100%',
      ...containerBg,
    }),
  ({css}) => mediaQuery(css),
);

export const LogoImage = styled(Image)(
  mediaQuery({
    width: [80, 120],
    height: 'auto',
    // paddingLeft: [0, 13],
  }),
);

export const Table = styled(CommonTable)(({css}) =>
  mediaQuery({
    borderLeft: tableBorder,
    borderRight: tableBorder,
    ...css,
  }),
);

export const SidePanel = styled(Flex)({
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: darkBlue,
  color: '#fff',
});

export const PanelTitleV2 = styled(Flex)(({css}) =>
  mediaQuery({
    alignItems: 'center',
    fontSize: 24,
    color: darkBlue,
    padding: '5px 17px',
    textAlign: ['center', 'left'],
    ...css,
  }),
);

export const PanelInnerWrapper = styled(Flex)({
  flexDirection: 'column',
  marginTop: 15,
  width: 'calc(100% + 30px)',
  transform: 'translateX(-15px)',
});

export const PanelCTAWrapper = styled(Flex)(({small}) =>
  mediaQuery({
    padding: small ? ['18px 14px', '16px 32px'] : ['18px 14px', '20px 30px'],
    justifyContent: 'flex-end',
    backgroundColor: '#D9E7F033',
  }),
);
