import {Flex, Text} from 'rebass';

import {gray, loanTerms, toCurrency} from '@renofi/utils';

import {EstimatePreviewPanel, EstimateLoanOptionProp} from './styled';

const EstimateLoanOption = ({loanAmount, option}) => (
  <EstimatePreviewPanel>
    <Text fontSize={28}>{option?.product?.name}</Text>
    <Flex
      mt={16}
      width="100%"
      flexDirection="column"
      fontSize={14}
      css={{gap: 4}}>
      <EstimateLoanOptionProp>
        <Text flex={1} color={gray}>
          Loan amount
        </Text>
        <Text flex={1}>${toCurrency(loanAmount)}</Text>
      </EstimateLoanOptionProp>
      <EstimateLoanOptionProp>
        <Text flex={1} color={gray}>
          Payments as low as
        </Text>
        <Text flex={1}>
          ${toCurrency(option?.minMonthlyPayment)} / month{' '}
          {option?.interestOnly && '(interest only payment)'}
        </Text>
      </EstimateLoanOptionProp>
      <EstimateLoanOptionProp>
        <Text flex={1} color={gray}>
          Max borrowing power
        </Text>
        <Text flex={1}>
          ${toCurrency(option?.maxLoanAmountOffered)} (based on After Renovation
          Value)
        </Text>
      </EstimateLoanOptionProp>
      <EstimateLoanOptionProp>
        <Text flex={1} color={gray}>
          Rate
        </Text>
        <Text flex={1}>{option?.product?.rateType}</Text>
      </EstimateLoanOptionProp>
      <EstimateLoanOptionProp>
        <Text flex={1} color={gray}>
          Terms up to
        </Text>
        <Text flex={1}>
          {loanTerms(
            option?.product?.drawPeriod,
            option?.product?.repaymentPeriod,
          )}{' '}
          Years{' '}
          <span>
            ({option?.product?.drawPeriod} year draw phase /{' '}
            {option?.product?.repaymentPeriod} repayment)
          </span>
        </Text>
      </EstimateLoanOptionProp>
    </Flex>
  </EstimatePreviewPanel>
);

export default EstimateLoanOption;
