import {useQuery} from '@apollo/client';

import {GET_CONSOLIDATED_ENTITY_BY_ID} from '../queries';

export default function useConsolidatedEntityById({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useQuery(
    GET_CONSOLIDATED_ENTITY_BY_ID,
    {
      fetchPolicy,
      ...options,
    },
  );
  const consolidatedEntity = data?.consolidatedEntityById;

  return {consolidatedEntity, fetch, loading, refetch};
}
