import {useState} from 'react';

import {Flex, Box, Text} from 'rebass';
import {pick} from 'lodash';

import {useValidateEmail} from '@renofi/api';
import {blue, gray, blueGray, formatPhoneNumber} from '@renofi/utils';
import {
  Alert,
  Label,
  TextField,
  PhoneField,
  Button,
  useNotifications,
  Checkbox,
} from '@renofi/components';
import {
  useReferClientForFinancing,
  GET_CONTRACTOR_ESTIMATES,
} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import {Layout, EmailField} from '../../../components';
import {useMyAccount} from '../../../api';
import {ESTIMATES_PER_PAGE} from '../../constants';

const LeadSubmission = ({estimate, onSubmit}) => {
  const {user, contractor} = useMyAccount();

  const estimateClient = pick(estimate, [
    'email',
    'firstName',
    'lastName',
    'phoneNumber',
  ]);
  const hasClient =
    estimateClient.email && estimateClient.firstName && estimateClient.lastName;
  const [clientDetails, setClientDetails] = useState(estimateClient || {});
  const [clientConsentObtained, setClientConsentObtained] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const addNotification = useNotifications();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const {referClientForFinancing, loading} = useReferClientForFinancing({
    refetchQueries: [
      {
        query: GET_CONTRACTOR_ESTIMATES,
        variables: {limit: ESTIMATES_PER_PAGE},
      },
    ],
  });

  const updated = Object.keys(estimateClient).some((key) => {
    if (key === 'phoneNumber') {
      return (
        formatPhoneNumber(clientDetails[key]) !==
        formatPhoneNumber(estimateClient[key], {stripCountryCode: true})
      );
    }
    return clientDetails[key] !== estimateClient[key];
  });

  const disabled =
    !(
      clientDetails.email &&
      clientDetails.firstName &&
      clientDetails.lastName
    ) ||
    (!hasClient && !clientConsentObtained) ||
    loading ||
    loadingValidateEmail ||
    invalidEmail ||
    !updated;

  const handleChange = (key) => (value) => {
    if (key === 'email') {
      setInvalidEmail(false);
    }
    setClientDetails({
      ...clientDetails,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    const {email, firstName, lastName, phoneNumber} = clientDetails;
    const {data: validationData} = await validateEmail({variables: {email}});

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    try {
      await referClientForFinancing({
        variables: {
          client: {
            id: estimate.id,
            email,
            firstName,
            lastName,
            phoneNumber,
          },
        },
      });

      sendEvent('Contractors/Financing-Estimate-Client-Details-Submitted', {
        ...clientDetails,
        propertyAddress: estimate?.propertyAddress,
        renovationCost: estimate?.renovationCost,
        contractor: contractor?.businessName,
        contractorContact: user?.email,
        clientConsentObtained,
      });
      onSubmit();
    } catch (e) {
      sendEvent(
        'Contractors/Submitting-Financing-Estimate-Client-Details-Failed',
        {
          message: e.message,
        },
      );
      addNotification({
        type: 'error',
        message: 'There was a problem submitting the client details.',
      });
    }
  };

  return (
    <Layout.Panel contentCss={{paddingBottom: 0}}>
      <Layout.PanelTitleV2>Who is this estimate for?</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Flex
          flexDirection="column"
          padding="20px 40px"
          css={{
            borderTop: `1px solid ${blueGray}`,
          }}>
          <Alert icon info>
            Complete and save this section before choosing a sharing option
            below.
          </Alert>
          <Box mt={24} maxWidth={675}>
            <Label fontSize={14} color={gray} htmlFor="email">
              Email
            </Label>
            <EmailField
              id="email"
              placeholder={null}
              value={clientDetails?.email}
              onChange={handleChange('email')}
              invalidEmail={invalidEmail}
            />
          </Box>
          <Flex
            mt={24}
            flexDirection={['column', 'row']}
            maxWidth={1026}
            css={{gap: 24}}>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="firstName">
                First name
              </Label>
              <TextField
                id="firstName"
                value={clientDetails?.firstName}
                onChange={handleChange('firstName')}
              />
            </Box>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="lastName">
                Last name
              </Label>
              <TextField
                id="lastName"
                value={clientDetails?.lastName}
                onChange={handleChange('lastName')}
              />
            </Box>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="phoneNumber">
                Phone number
              </Label>
              <PhoneField
                id="phoneNumber"
                topMessage="optional"
                stripCountryCode
                topMessageColor={blue}
                value={clientDetails?.phoneNumber}
                onChange={handleChange('phoneNumber')}
              />
            </Box>
          </Flex>
          {!hasClient && (
            <Label
              data-testid="lead-submission-checkbox"
              css={{cursor: 'pointer', marginTop: 24}}>
              <Box minWidth={32}>
                <Checkbox
                  checked={clientConsentObtained}
                  onChange={() =>
                    setClientConsentObtained(!clientConsentObtained)
                  }
                />
              </Box>
              <Text color={gray}>
                I have obtained my client's consent to share their contact info
                with RenoFi
              </Text>
            </Label>
          )}
        </Flex>
        <Layout.PanelCTAWrapper>
          <Button
            data-testid="client-details-submission-btn"
            small
            disabled={disabled}
            loading={loading || loadingValidateEmail}
            onClick={handleSubmit}>
            {!hasClient && 'Save'}
            {hasClient && 'Update'}
          </Button>
        </Layout.PanelCTAWrapper>
      </Layout.PanelInnerWrapper>
    </Layout.Panel>
  );
};

export default LeadSubmission;
