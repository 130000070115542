import React, {useContext, useMemo} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import {Image, Box, Flex, Text} from 'rebass';
import PropTypes from 'prop-types';

import {Badge, Button, Loader} from '@renofi/components';
import {humanizeCase, useScreenSize, blueGray, darkBlue} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {Layout} from '../../components';
import AppContext from '../../context';
import {
  getCddReviewStatusVariant,
  getQuestionnairePathFromStep,
} from '../utils';
import warningCircleSrc from '../img/warning-circle.svg';
import infoCircleSrc from '../img/info-circle.svg';

import CddReviewTimestamps from './CddReviewTimestamps';

const CddReviewStatus = ({isPersonal, props}) => {
  const {contractor, loading} = useContext(AppContext);
  const {isMobile} = useScreenSize();
  const history = useHistory();
  const {clientId} = useParams();

  const [gcddReview] = contractor?.gcddReviews || [];
  const {latestQuestionnaire} = contractor || {};
  const {returnedAt, submittedStep} = latestQuestionnaire || {};
  const isStarted = submittedStep > 0;
  const isQuestionnaireReturned = Boolean(returnedAt);
  const buttonText = isQuestionnaireReturned
    ? 'Edit'
    : isStarted
      ? 'Continue'
      : 'Get started';

  const [bgColor, textColor, overallStatus, statusTimestamp] = useMemo(
    () => getCddReviewStatusVariant({contractor, gcddReview}),
    [JSON.stringify(gcddReview)],
  );

  const onClickEdit = () => {
    sendEvent('Contractors/Financing/Project-Questionnaire-Opened', {
      cta: buttonText,
    });
    const startingStep = getQuestionnairePathFromStep(submittedStep);
    history.push(`/financing/${clientId}/questionnaire${startingStep}`);
  };

  if (loading) {
    return <Loader />;
  } else if (!latestQuestionnaire || (isPersonal && !gcddReview)) {
    return null;
  }

  return (
    <Layout.Panel {...props}>
      <Layout.PanelTitleV2
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        css={{gap: 12}}>
        Contractor due diligence
        {Boolean(gcddReview) ? (
          <Badge
            role="overall_status"
            large
            color={bgColor}
            size={12}
            textColor={textColor}>
            {humanizeCase(overallStatus)}
          </Badge>
        ) : null}
      </Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Flex
          flexDirection="column"
          padding={['28px 16px', '20px 40px']}
          css={{
            borderTop: `1px solid ${blueGray}`,
          }}>
          <Flex
            flexDirection={['column', 'row']}
            alignItems="center"
            css={{gap: 24}}>
            {!isQuestionnaireReturned && (
              <>
                <Image src={warningCircleSrc} />
                <Text color={darkBlue} textAlign={['center', 'left']}>
                  Homeowners seeking to finance a major home renovation project
                  with a RenoFi Loan must use RenoFi verified contractors. Take
                  a few minutes to complete a brief questionnaire to get
                  started.
                </Text>
              </>
            )}
            {isQuestionnaireReturned && (
              <>
                <Image src={infoCircleSrc} />
                <Box>
                  <Text color={darkBlue} mb={24} textAlign={['center', 'left']}>
                    Homeowners seeking to finance a major home renovation
                    project with a RenoFi Loan must use RenoFi verified
                    contractors.
                  </Text>
                  <CddReviewTimestamps
                    questionnaire={latestQuestionnaire}
                    overallStatus={overallStatus}
                    statusTimestamp={statusTimestamp}
                  />
                </Box>
              </>
            )}
          </Flex>

          <Flex
            order="revert"
            flexDirection={isMobile ? 'column-reverse' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'flex-end'}>
            <Button
              role="edit_questionnaire_btn"
              secondary={isQuestionnaireReturned}
              onClick={onClickEdit}
              css={{
                marginTop: isQuestionnaireReturned ? 12 : 32,
                marginLeft: [0, 94],
              }}>
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Layout.PanelInnerWrapper>
    </Layout.Panel>
  );
};

CddReviewStatus.propTypes = {
  isPersonal: PropTypes.bool,
};

export default CddReviewStatus;
