import {Flex, Box, Text} from 'rebass';
import {noop} from 'lodash';

import {
  SliderWrapper,
  Slider as StyledSlider,
  SliderValue,
} from '@renofi/components';
import {useSliderActions, gray} from '@renofi/utils';

import SliderButton from './SliderButton';

const DistanceSlider = ({
  value,
  children,
  min,
  max,
  step,
  decreaseStep,
  onChange = noop,
}) => {
  const {onDecrease, onIncrease, throttleChange} = useSliderActions({
    min,
    max,
    step,
    decreaseStep,
    value,
    onChange,
  });

  const sliderValue = value === max ? 'Nationwide' : `${value} miles`;

  return (
    <SliderWrapper fullWidth>
      <Flex justifyContent="space-between" alignItems="center" height={60}>
        <SliderButton onChange={onDecrease}>
          <Box pt="2px">-</Box>
        </SliderButton>

        <SliderValue css={{fontSize: 24}}>{sliderValue}</SliderValue>

        <SliderButton onChange={onIncrease}>+</SliderButton>
      </Flex>

      {children}

      <StyledSlider
        value={value}
        onChange={throttleChange}
        min={0}
        max={max}
        step={step}
        decreaseStep={decreaseStep}
      />

      <Flex css={{userSelect: 'none'}} mt="6px" justifyContent="space-between">
        <Text fontSize={12} color={gray}>
          {min} miles
        </Text>
        <Text fontSize={12} color={gray}>
          Nationwide
        </Text>
      </Flex>
    </SliderWrapper>
  );
};

export default DistanceSlider;
