import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REQUEST_USER_LOGIN} from '../mutations';

export default function useRequestUserLogin({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [requestUserLogin, {data, loading, error}] = useMutation(
    REQUEST_USER_LOGIN,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const success = Boolean(data?.requestUserLogin?.success);
  return {loading, error, requestUserLogin, success};
}
