import React from 'react';

import {Box} from 'rebass';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {InMemoryCache} from '@apollo/client';
import {ThemeProvider} from '@emotion/react';
import {pdfjs} from 'react-pdf';

import initPolyfills from '@renofi/utils/src/polyfills';
import {NotificationsProvider} from '@renofi/components';
import {AuthApolloClient} from '@renofi/modules';
import {breakpoint, initGooglePlaces} from '@renofi/utils';
import {FlagProvider} from '@renofi/utils/src/feature-flags';

import './index.css';
import {initAnalytics} from './analytics';
import {PUBLIC_PATHS} from './constants';
import {isAuthTokenPath} from './utils';
import App from './App';

// set up react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

initPolyfills();
initAnalytics();

if (process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
  initGooglePlaces(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
}

const cache = new InMemoryCache();

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

const theme = {
  breakpoints: [`${breakpoint}px`],
};

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: `contractors-app-${process.env.REACT_APP_ENV}`,
  environment: process.env.REACT_APP_ENV,
};

const onAuthError = ({history, isMutation}) => {
  if (isMutation) {
    return false;
  }

  if (
    !PUBLIC_PATHS.includes(history?.location?.pathname) &&
    !isAuthTokenPath(history?.location?.pathname)
  ) {
    history.push('/');
  }
};

root.render(
  <Router>
    <FlagProvider config={unleashConfig}>
      <Box>
        <NotificationsProvider>
          <AuthApolloClient
            analyticsPrefix="Contractors"
            cache={cache}
            onAuthError={onAuthError}
            onAuthSuccess={({history}) => {
              if (history?.location?.pathname === '/') {
                history.push('/financing');
              }
            }}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AuthApolloClient>
        </NotificationsProvider>
      </Box>
    </FlagProvider>
  </Router>,
);
