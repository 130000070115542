import React, {useContext} from 'react';

import {useHistory, useParams} from 'react-router-dom';

import {useContractorProjectsV2} from '@renofi/api';
import {Questionnaire as QuestionnaireApp} from '@renofi/modules';
import {Loader, Toggle} from '@renofi/components';
import {sendEvent} from '@renofi/analytics';
import {useUTMTags} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

import {setAnalyticsIdentify} from '../../App/utils';
import AppContext from '../../context';
import useIsQuestionnaireFinished from '../useIsQuestionnaireFinished';

const Questionnaire = () => {
  const {contractor, loading, refetchContractor, user} = useContext(AppContext);
  const {loading: loadingProjects, projects} = useContractorProjectsV2();
  const {setIsQuestionnaireFinished} = useIsQuestionnaireFinished();
  const {utmTags} = useUTMTags(UTM_COOKIE_KEYS.contractors);
  const history = useHistory();
  const {projectId} = useParams();
  const {latestQuestionnaire} = contractor || {};
  const project = projects.find((p) => p.id === projectId);
  const isLoading = loading || loadingProjects;

  const onFinish = async () => {
    const rsp = await refetchContractor();
    const questionnaire = rsp?.data?.contractorById?.latestQuestionnaire || {};
    const {
      id: questionnaireId,
      residentialHomeRemodelRole,
      residentialHomeRemodelBlueprintingOption,
      businessName,
      contactFirstName,
      contactLastName,
      email,
      phoneNumber,
      avgPriceOfProjects,
      avgNumberOfRenovationsPerYear,
    } = questionnaire;

    sendEvent('Contractors/Questionnaire/Finished', {
      questionnaireId,
      businessName,
      firstName: contactFirstName,
      lastName: contactLastName,
      email,
      phoneNumber,
      averageProjectSizeInDollars: avgPriceOfProjects,
      projectsToBeDoneInThisYear: avgNumberOfRenovationsPerYear,
      utmSource: utmTags.source,
    });
    setAnalyticsIdentify(user, {
      residentialHomeRemodelRole,
      residentialHomeRemodelBlueprintingOption,
    });

    history.push(`/financing/client/${projectId}`);
    setIsQuestionnaireFinished(true);
  };

  return (
    <>
      <Toggle show={isLoading}>
        <Loader />
      </Toggle>

      <Toggle show={!isLoading && Boolean(latestQuestionnaire)}>
        <QuestionnaireApp
          basePath="/financing/:projectId/questionnaire"
          onFinish={onFinish}
          project={project}
          questionnaire={latestQuestionnaire}
        />
      </Toggle>
    </>
  );
};

export default Questionnaire;
