import React, {useContext, useState} from 'react';

import {Flex, Image, Text} from 'rebass';
import {useHistory} from 'react-router-dom';

import {sendEvent} from '@renofi/analytics';
import {
  LogoutIcon,
  ArrowIcon,
  renofiLogoLightIconSrc,
  CloseIcon,
  ClickOutside,
} from '@renofi/components';
import {
  darkBlue,
  fadeBlue,
  formatName,
  isRenofiPreview,
  useScreenSize,
} from '@renofi/utils';
import {useLogin} from '@renofi/modules';

import AppContext from '../../../context';

import {
  Avatar,
  CloseMenu,
  DropDownWrapper,
  MenuItem,
  MenuTextItem,
  MenuWrapper,
  OverflowText,
  TOP_BAR_HEIGHT,
} from './styled';
import ContactUs from './ContactUs';

const IS_DEV = process.env.NODE_ENV === 'development';
const IS_PREVIEW = isRenofiPreview();

const TopBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const {isDesktop} = useScreenSize();
  const {contractor, isLoggedIn, user} = useContext(AppContext);
  const history = useHistory();
  const {logout} = useLogin();

  const name = formatName(user);
  const initials = [user?.firstName?.charAt(0), user?.lastName?.charAt(0)]
    .join('')
    .toUpperCase();

  const onClickLogout = async (e) => {
    e?.preventDefault();
    logout();

    sendEvent(`Contractors/Logout-Clicked`);
    history.push('/');
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexShrink={0}
        height={TOP_BAR_HEIGHT}
        backgroundColor={darkBlue}
        px={[20, 4]}
        css={{
          color: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 101,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
        }}>
        <Flex alignItems="center">
          <Image width={[87, 87]} src={renofiLogoLightIconSrc} />
          <Text css={{position: 'relative', top: 4}} fontSize={24} ml="10px">
            for Contractors
          </Text>
        </Flex>

        <Flex height={TOP_BAR_HEIGHT} alignItems="center" css={{gap: 32}}>
          {isDesktop ? <ContactUs user={user} /> : null}

          <ClickOutside onClickOutside={() => setShowMenu(false)}>
            <DropDownWrapper onClick={() => setShowMenu(!showMenu)}>
              {!isDesktop && showMenu ? (
                <CloseMenu>
                  <CloseIcon fill={fadeBlue} />
                </CloseMenu>
              ) : (
                <Avatar>{initials}</Avatar>
              )}
              {isDesktop ? (
                <>
                  <Text role="contractor_user" fontSize={18} mx={3}>
                    {name}
                  </Text>
                  <ArrowIcon direction={showMenu ? 'up' : 'down'} size={12} />
                </>
              ) : null}
              {showMenu ? (
                <MenuWrapper role="contractor_user_options">
                  {IS_DEV || IS_PREVIEW ? (
                    <MenuTextItem>
                      <OverflowText fontSize={14}>{user?.email}</OverflowText>
                      <OverflowText fontSize={14}>
                        {contractor?.businessName}
                      </OverflowText>
                    </MenuTextItem>
                  ) : null}
                  <MenuItem onClick={onClickLogout}>
                    <LogoutIcon color={isDesktop ? darkBlue : fadeBlue} />
                    Logout
                  </MenuItem>
                </MenuWrapper>
              ) : null}
            </DropDownWrapper>
          </ClickOutside>
        </Flex>
      </Flex>
    </>
  );
};

export default TopBar;
