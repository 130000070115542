import React, {useContext, useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Link, Text} from 'rebass';

import {Alert, Button, MODAL_ZINDEX, Modal} from '@renofi/components';
import {GET_CURRENT_USER, useAcceptAgreements} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import AppContext from '../../context';

const ToSLink = () => (
  <Link
    target="_blank"
    href="https://www.renofi.com/notices/renofi-for-contractors">
    Terms of Service
  </Link>
);

const TermsOfService = ({agreementsHaveChanged = false, onClose}) => {
  const {fetch: refreshCachedUser} = useContext(AppContext);
  const [error, setError] = useState(false);
  const {acceptAgreements, loading} = useAcceptAgreements({
    refetchQueries: [{query: GET_CURRENT_USER}],
  });

  const onClickAgree = async () => {
    setError(false);
    const variables = {
      electronicSignature: true,
      contractorTermsOfService: true,
    };
    try {
      await acceptAgreements({variables});
      await refreshCachedUser();
      onClose();
    } catch (err) {
      setError(true);
    }
    if (agreementsHaveChanged) {
      sendEvent(`Contractors/UpdatedTermsAgreed-Clicked`);
    } else {
      sendEvent(`Contractors/TermsAgreed-Clicked`);
    }
  };

  return (
    <Modal
      css={{zIndex: MODAL_ZINDEX + 2}}
      contentCss={{borderRadius: 16}}
      hideCloseButton
      onClose={onClose}
      show>
      <Flex p={2} css={{gap: 16}} flexDirection="column">
        <Text fontSize={20} fontWeight={700}>
          {agreementsHaveChanged
            ? 'RenoFi for Contractors Important Notification'
            : 'Welcome to RenoFi for Contractors'}
        </Text>

        {agreementsHaveChanged ? (
          <Alert fontSize={16} mb={2} info>
            Please review and agree our updated RenoFi for Contractors{' '}
            <ToSLink />.
          </Alert>
        ) : (
          <>
            <Alert fontSize={16} mb={2} info>
              We’re excited to welcome you to our free partner platform, but
              first we need you to review and agree to our terms.
            </Alert>
            <Text>
              I agree to use{' '}
              <Link
                target="_blank"
                href="https://www.renofi.com/notices/electronic-records-and-signatures">
                electronic records and signatures.
              </Link>
              , and I agree to RenoFi for Contractors <ToSLink />.
            </Text>
          </>
        )}

        {error ? (
          <Alert fontSize={16} error>
            There was a problem accepting the agreements; please try again
          </Alert>
        ) : null}
        <Box alignSelf="center">
          <Button
            disabled={loading}
            large
            loading={loading}
            onClick={onClickAgree}
            role="accept_agreements_btn">
            I agree
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};

TermsOfService.propTypes = {
  agreementsHaveChanged: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default TermsOfService;
