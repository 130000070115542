import {ArrowIcon} from '@renofi/components';
import {blue, lightGray} from '@renofi/utils';

import {Sorter} from './styled';

const SortArrow = ({label, onClick, sortBy, sortDirection, sortKey}) => {
  const getSortDirection = (key) => {
    if (key !== sortBy) {
      return 'up';
    }
    return sortDirection === 'asc' ? 'up' : 'down';
  };

  return (
    <Sorter onClick={onClick(sortKey)}>
      {label}
      <ArrowIcon
        style={{marginLeft: 8}}
        direction={getSortDirection(sortKey)}
        color={sortBy === sortKey ? blue : lightGray}
      />
    </Sorter>
  );
};

export default SortArrow;
