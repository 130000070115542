export const ACTIVE_GCDD_REVIEW_STATUSES = [
  'cancelled',
  'completed',
  'ordered',
  'returned',
];

export const EXAMPLE_CONTRACT =
  'https://www.renofi.com/resources/contract-example.ece5cb8c17e2814fa879ebb74978402de1ca89f03aefec4f488abea0f135258e.pdf';

export const EXAMPLE_ESTIMATE =
  'https://www.renofi.com/resources/detailed_cost_estimate_annotated.7481fbd29f0b731e5f62332f539da80f3bf37124ac8ec77bc4cb31cffb04ac4f.pdf';
