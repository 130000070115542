const CheckIcon = ({width = 20, height = 17, color = '#13C427'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 10"
    fill="none">
    <path
      d="M4.5 10.3667L0.5 6.36668L1.9 4.96668L4.5 7.56668L11.1 0.966675L12.5 2.36667L4.5 10.3667Z"
      fill={color}
    />
  </svg>
);

export default CheckIcon;
