import {useState, useRef, useEffect} from 'react';

import {Flex, Box, Text, Link, Image} from 'rebass';

import {Button, Loader, Alert} from '@renofi/components';
import {
  darkBlue,
  copyToClipboard,
  red,
  gray,
  green,
  useScreenSize,
} from '@renofi/utils';

import circleCopySrc from '../../img/circle-copy.svg';
import notificationBellSrc from '../../img/notification-bell.svg';
import optionsMockupSrc from '../../img/options-mockup.png';
import CheckIcon from '../CheckIcon';

import LinkShareStep from './LinkShareStep';
import {trackShareableLinkCopied} from './utils';

const ShareableLinkTab = ({dashboardLink, estimate}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const timeoutRef = useRef(null);
  const {isMobile} = useScreenSize();

  const loading = !dashboardLink;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleCopy = () => {
    trackShareableLinkCopied(estimate);
    copyToClipboard(estimate?.scenarioDashboardLink);
    setLinkCopied(true);
    timeoutRef.current = setTimeout(() => {
      setLinkCopied(false);
    }, 1500);
  };

  return (
    <Flex
      flexDirection="column"
      maxWidth={['100%', 1150]}
      mx="auto"
      padding={['30px 20px', 30]}>
      <Text fontSize={24} color={darkBlue} textAlign={['center', 'left']}>
        How it works
      </Text>
      <Flex
        mt={30}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="stretch"
        css={{gap: 40}}>
        <Box flex={1}>
          <LinkShareStep icons={[circleCopySrc]}>
            <Text textAlign="center" width="100%">
              1. Share via text message or email
            </Text>
            {!loading && (
              <>
                {!linkCopied && (
                  <Button small onClick={handleCopy}>
                    Copy link
                  </Button>
                )}
                {linkCopied && (
                  <Alert
                    success
                    css={{
                      width: 'auto',
                      fontWeight: 700,
                      borderRadius: 50,
                      marginTop: 0,
                    }}>
                    <Text display="inline-block" mr="8px">
                      LINK COPIED
                    </Text>
                    <CheckIcon color={green} />
                  </Alert>
                )}
                <Link
                  width="100%"
                  textAlign="center"
                  fontSize={16}
                  href={dashboardLink}
                  target="_blank"
                  css={{textDecoration: 'none'}}>
                  {dashboardLink}
                </Link>
              </>
            )}
            {loading && (
              <Flex flexDirection="column" alignItems="center">
                <Loader color={red} />
                <Text mt="8px" fontSize={14} textAlign="center" color={gray}>
                  Generating sharable link
                </Text>
              </Flex>
            )}
          </LinkShareStep>
        </Box>
        <Box flex={isMobile ? 1 : 2}>
          <LinkShareStep icons={[notificationBellSrc]} css={{paddingBottom: 0}}>
            <Box width={['100%', '80%']}>
              <Flex>
                2.
                <Text ml={2} textAlign={['center', 'left']}>
                  Get notified when your client uses the link to visit their
                  RenoFi financing page page.
                </Text>
              </Flex>
              <Image width="100%" mt={24} src={optionsMockupSrc} />
            </Box>
          </LinkShareStep>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ShareableLinkTab;
