import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import REFER_CLIENT_FOR_FINANCING from '../mutations/referClientForFinancing';

export default function useReferClientForFinancing({
  awaitRefetchQueries = false,
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [referClientForFinancing, {data, loading, error}] = useMutation(
    REFER_CLIENT_FOR_FINANCING,
    {
      awaitRefetchQueries,
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.referClientForFinancing;
  return {referClientForFinancing, response, loading, error};
}
