import {useEffect, useRef, useState} from 'react';

import {Box, Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useConsolidatedEntities} from '@renofi/api';
import {Pagination} from '@renofi/components';
import {blueGray, useScreenSize} from '@renofi/utils';

import {Layout} from '../../../components';

import {SearchField, Table, Th} from './styled';
import TableRow from './TableRow';
import FilterBy from './FilterBy';
import SortArrow from './SortArrow';
import {ADDRESS_CELL_WIDTH} from './constants';

const Clients = () => {
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('createdAt');
  const [filterBy, setFilterBy] = useState({});
  const [query, setQuery] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const timeoutId = useRef();
  const {isMobile} = useScreenSize();

  const {
    collection,
    fetch: fetchConsolidatedEntities,
    loading,
    metadata,
    previousData,
  } = useConsolidatedEntities({fetchPolicy: 'no-cache', lazy: true});
  const hasManyPages = metadata?.totalCount > metadata?.limitValue;
  const previous = previousData?.consolidatedEntities?.collection || [];

  useEffect(() => {
    fetchAllData();
  }, [filterBy, page, sortBy, sortDirection]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const onChangeSearch = (value) => {
    if (value) {
      setQuery(value);

      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(fetchAllData, 1000);
    }
  };

  const onClickSort = (key) => () => {
    setSortBy(key);

    if (sortBy === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
  };

  const fetchAllData = async () => {
    const variables = {
      filterBy,
      page,
      query,
      sortBy: {[sortBy]: sortDirection},
    };
    sendEvent('/Contractors/Clients/Fetch', variables);

    fetchConsolidatedEntities({variables});
  };

  return (
    <>
      <Layout.Panel
        title={
          <Text mt={1} mb={3} fontSize={24}>
            Your clients
          </Text>
        }>
        <Flex
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          css={{
            gap: 16,
            borderTop: `1px solid ${blueGray}`,
            borderBottom: `1px solid ${blueGray}`,
          }}
          justifyContent="space-between"
          backgroundColor={`${blueGray}20`}
          p={3}
          mx={-3}>
          <SearchField
            icon="search"
            iconCss={{borderRight: 0}}
            wrapperCss={{borderColor: blueGray}}
            onChange={onChangeSearch}
            placeholder="Search by name or address"
            value={query}
          />

          <FilterBy filterBy={filterBy} onChange={setFilterBy} />
        </Flex>

        <Box mx={-3}>
          <Table role="clients_table" bold horizontal>
            <thead>
              <tr>
                <Th>Client</Th>
                <Th>
                  <SortArrow
                    sortKey="createdAt"
                    label="Added"
                    onClick={onClickSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  />
                </Th>
                <Th style={{width: ADDRESS_CELL_WIDTH}}>Address</Th>
                <Th>
                  <SortArrow
                    sortKey="entityRenovationCost"
                    label="Project size"
                    onClick={onClickSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  />
                </Th>
                <Th>Status</Th>
                <Th>Service type</Th>
                <Th>
                  <SortArrow
                    sortKey="entityProjectActionStatus"
                    label="Action required"
                    onClick={onClickSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  />
                </Th>
              </tr>
            </thead>

            <tbody>
              {loading
                ? previous.map((entity) => (
                    <TableRow key={entity.id} loading={loading} />
                  ))
                : collection.map((entity) => (
                    <TableRow key={entity.id} entity={entity} />
                  ))}
            </tbody>
          </Table>
        </Box>
      </Layout.Panel>

      {hasManyPages ? (
        <Pagination
          current={metadata.currentPage}
          mt={3}
          justifyContent="center"
          onChange={setPage}
          total={metadata.totalPages}
        />
      ) : null}
    </>
  );
};

export default Clients;
