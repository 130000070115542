import {useQuery} from '@apollo/client';

import {GET_CONTRACTOR_ESTIMATES} from '../queries';

export default function useContractorEstimates({
  fetchPolicy = 'cache-first',
  ...options
} = {}) {
  const {data, loading, refetch} = useQuery(GET_CONTRACTOR_ESTIMATES, {
    fetchPolicy,
    ...options,
  });

  const res = data?.contractorEstimates;
  const estimates = res?.collection || [];
  const metadata = res?.metadata || {};

  return {estimates, metadata, loading, refetch};
}
