import {Flex, Box} from 'rebass';

import {Label, CheckboxLabel, Checkbox} from '@renofi/components';
import {blueGray} from '@renofi/utils/src/colors';

const ClientModalCheckbox = ({id, checked, name, onChange, children, css}) => (
  <Flex
    alignItems="center"
    css={{
      border: `1px solid ${blueGray}`,
      padding: 12,
      borderRadius: 8,
      ...css,
    }}>
    <Label css={{cursor: 'pointer'}}>
      <Box minWidth={32}>
        <Checkbox
          checked={checked}
          id={id}
          name={name}
          onChange={() => onChange(!checked)}
        />
      </Box>
      <CheckboxLabel fontSize={16}>{children}</CheckboxLabel>
    </Label>
  </Flex>
);

export default ClientModalCheckbox;
