import {useEffect} from 'react';

import {Box} from 'rebass';
import {useHistory, useParams} from 'react-router-dom';

import {red} from '@renofi/utils/src/colors';
import {sendEvent} from '@renofi/analytics';
import {useOTPRegistration} from '@renofi/modules';
import {Loader} from '@renofi/components';

import {AUTH_PATHS, ACCOUNT_SETUP_PATHS} from '../constants';

const CompleteRegistration = () => {
  const history = useHistory();
  const {token} = useParams();

  const {completeUserRegistration} = useOTPRegistration();
  const handleCompleteRegistration = () => {
    sendEvent('Contractors/Complete-Registration-Success');
    history.push(ACCOUNT_SETUP_PATHS.BUSINESS_DETAILS);
  };

  const handleCompleteRegistrationError = ({rsp}) => {
    sendEvent('Contractors/Complete-Registration-Error', rsp);
    history.push(AUTH_PATHS.SIGNUP);
  };

  useEffect(() => {
    (async () => {
      await completeUserRegistration(
        token,
        handleCompleteRegistration,
        handleCompleteRegistrationError,
      );
    })();
  }, []);

  return (
    <Box height="100%" css={{position: 'fixed', left: '50%', top: '50%'}}>
      <Loader color={red} size={1.5} />
    </Box>
  );
};

export default CompleteRegistration;
