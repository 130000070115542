import React, {useState, useMemo} from 'react';

import {Flex, Image, Text, Link} from 'rebass';
import * as Sentry from '@sentry/react';
import {filter} from 'lodash';

import {Modal, useNotifications, Button, Loader} from '@renofi/components';
import {
  GET_CONTRACTOR_PROJECTS_V2,
  useUploadMetadata,
  useContractorProjectsV2,
  useSubmitContractorProjectDocument,
  useRemoveDocument,
} from '@renofi/api';
import {
  blueGray,
  uploadFileToCloud,
  fadeGray,
  lightGray,
  darkBlue,
  gray,
} from '@renofi/utils';
import {sendEvent} from '@renofi/analytics';

import {useOnRejectedFiles} from '../../../utils';
import {Layout, DocumentUpload} from '../../../components';
import {modalCustomStyles} from '../styled';
import {getProjectDocuments} from '../../utils';
import {EXAMPLE_CONTRACT, EXAMPLE_ESTIMATE} from '../constants';

import uploadSrc from './img/upload.svg';

const FAILED_PATH = ['success', false];

const ProjectDocuments = ({projectId}) => {
  const {fetchFilesMetadata, loading: isUploading} = useUploadMetadata({
    lazy: true,
  });
  const {submitContractorProjectDocument, loading: isSubmitting} =
    useSubmitContractorProjectDocument();
  const {refetch: refetchProjects} = useContractorProjectsV2();
  const addNotification = useNotifications();
  const [failed, setFailed] = useState([]);
  const onRejectFiles = useOnRejectedFiles({
    eventName: 'Contractors/Financing/Project-UploadFailed',
  });

  const [deleteId, setDeleteId] = useState(false);

  const {loading, projects} = useContractorProjectsV2();
  const {loading: isRemoving, removeDocument} = useRemoveDocument({
    refetchQueries: [
      {
        query: GET_CONTRACTOR_PROJECTS_V2,
      },
    ],
  });

  const project = useMemo(
    () => (projectId ? projects?.find(({id}) => id === projectId) : null),
    [projectId, projects],
  );

  const propertyInfo = project?.borrowersAndPropertyInformation;
  const streetAddress =
    propertyInfo?.propertyStreetAddressOne ||
    propertyInfo?.propertyStreetAddressTwo;
  const fullAddress = `${streetAddress || ''}, ${propertyInfo?.propertyCity || ''}, ${propertyInfo?.propertyState || ''}`;

  const {documents: projectDocuments = []} = project || {};
  const documents = getProjectDocuments(projectDocuments);

  const onAcceptFiles = async (files = []) => {
    if (!files.length) {
      return false;
    }

    setFailed([]);
    const metadata = await fetchFilesMetadata({files});

    const cloudStorageFiles = await Promise.all(
      metadata.map(
        async (data) => {
          const {file, url} = data;
          try {
            const rsp = await uploadFileToCloud(url, file);
            const success = rsp.status >= 200 && rsp.status < 300;
            return {...data, success, rsp};
          } catch (err) {
            return {...data, err, success: false};
          }
        },
        [[], []],
      ),
    );

    // Separate the files that succeeded upload
    const uploadedFiles = filter(cloudStorageFiles, ['success', true]);

    const submittedFiles = await Promise.all(
      uploadedFiles.map(async (data) => {
        const {checksum, objectName, recordId: documentId} = data;
        const variables = {
          document: {checksum, documentId, objectName},
          projectId,
        };
        try {
          const rsp = await submitContractorProjectDocument({variables});
          sendEvent('Contractors/Financing/Project-Doc-Uploaded', {
            documentId,
            objectName,
          });
          return {
            ...rsp?.data?.submitContractorProjectDocument?.document,
            success: true,
          };
        } catch (err) {
          return {...data, err, success: false};
        }
      }),
    );

    refetchProjects();

    const finishedFiles = filter(submittedFiles, ['success', true]);
    if (Boolean(finishedFiles?.length)) {
      const messagePrefix =
        finishedFiles.length > 1
          ? `${finishedFiles.length} documents`
          : 'Document';
      addNotification({
        type: 'success',
        message: `${messagePrefix} successfully uploaded.`,
      });
    }

    // Finally, filter out those files that didn't upload / submit
    const failedItems = [
      ...filter(cloudStorageFiles, FAILED_PATH),
      ...filter(submittedFiles, FAILED_PATH),
    ];
    setFailed(failedItems);
    if (Boolean(failedItems?.length)) {
      Sentry.captureException(new Error('Documents upload error'), {
        extra: failedItems.map(({err, file}) => ({err, file})),
      });
    }
  };

  const onClickDelete = (documentId) => (e) => {
    if (isRemoving) {
      return false;
    }
    e.preventDefault();
    setDeleteId(documentId);
  };

  const onConfirmDelete = async () => {
    await removeDocument({variables: {id: deleteId}});
    sendEvent('Contractors/Financing/Project-Doc-Deleted');
    setDeleteId(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Layout.Panel contentCss={{paddingBottom: 0}}>
        <Layout.PanelTitleV2>
          Feasibility study and contract Review
        </Layout.PanelTitleV2>
        <Layout.PanelInnerWrapper>
          <Flex
            flexDirection="column"
            padding={['28px 16px', '20px 40px']}
            css={{
              borderTop: `1px solid ${blueGray}`,
            }}>
            <Flex mb={24} alignItems="center" css={{gap: 24}}>
              <Image flexShrink={0} size={70} src={uploadSrc} />
              <Text color={darkBlue} fontSize={16} lineHeight="19px">
                Please upload a copy of the contract, plans, blueprints (where
                applicable), and detailed cost estimates for the project{' '}
                {streetAddress ? `at ${fullAddress}` : ''} so we can begin our
                feasibility and contract review on the homeowner’s behalf. View
                example{' '}
                <Link href={EXAMPLE_ESTIMATE} target="_blank">
                  estimate
                </Link>{' '}
                and{' '}
                <Link target="_blank" href={EXAMPLE_CONTRACT}>
                  contract
                </Link>{' '}
                files for reference before uploading the actual files.
              </Text>
            </Flex>

            <DocumentUpload
              documents={documents}
              isSubmitting={isSubmitting}
              isUploading={isUploading}
              failed={failed}
              onAcceptFiles={onAcceptFiles}
              onRejectFiles={onRejectFiles}
              onClickDelete={onClickDelete}
            />
          </Flex>
        </Layout.PanelInnerWrapper>
      </Layout.Panel>
      {Boolean(deleteId) ? (
        <Modal
          css={modalCustomStyles}
          onClose={() => setDeleteId(null)}
          show
          title="Delete file">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            pt={50}
            textAlign="center">
            <Text>Are you sure you wish to delete this file?</Text>
            <Flex
              justifyContent={['space-between', 'flex-end']}
              alignItems="center"
              bg={fadeGray}
              width={[1, 1]}
              height={[86, 70]}
              mt={4}
              p={[18, 20]}>
              <Button
                bgColor="white"
                color={gray}
                borderColor={lightGray}
                mr={16}
                small
                onClick={() => setDeleteId(null)}>
                No
              </Button>

              <Button
                data-testid="confirm_delete_btn"
                disabled={isRemoving}
                loading={isRemoving}
                small
                onClick={onConfirmDelete}>
                Yes, delete
              </Button>
            </Flex>
          </Flex>
        </Modal>
      ) : null}
    </>
  );
};

export default ProjectDocuments;
