import useToggledQuery from '../../useToggledQuery';
import {GET_FINANCING_CLIENTS} from '../queries';

export default function useFinancingClients({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, fetch, loading, refetch} = useToggledQuery(
    GET_FINANCING_CLIENTS,
    {
      lazy,

      fetchPolicy,
      ...options,
    },
  );
  const {collection = [], metadata} = data?.financingClients || {};

  return {collection, fetch, loading, metadata, refetch};
}
