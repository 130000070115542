import useToggledQuery from '../../useToggledQuery';
import {SIGNED_URL} from '../queries';

export default function useSignedUrl({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(SIGNED_URL, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const signedUrl = data?.signedUrl;

  return {error, fetch, signedUrl, loading};
}
