import {useState, useContext, useMemo} from 'react';

import {Flex, Text, Image} from 'rebass';
import {useApolloClient} from '@apollo/client';
import {snakeCase} from 'lodash';
import {uploadDirect} from '@uploadcare/upload-client';
import * as Sentry from '@sentry/react';

import {sendEvent} from '@renofi/analytics';
import {
  GET_CONTRACTOR_BY_ID,
  useUpdateContractor,
  useSupportedMimeTypes,
} from '@renofi/api';
import {
  formatIsoDate,
  formatName,
  logger,
  lightGray,
  darkBlue,
  red,
  useScreenSize,
} from '@renofi/utils';
import {
  useNotifications,
  renofiLogoDarkIconSrc,
  FileInput,
  Button,
} from '@renofi/components';

import AppContext from '../../context';
import {useOnRejectedFiles} from '../../utils';
import {MAX_FILE_SIZE} from '../../constants';
import {ReactComponent as UploadIcon} from '../img/upload-icon.svg';

import {AssetsColWrapper, AssetsColTitle, AssetsColDescription} from './styled';

const IS_TEST = process.env.NODE_ENV === 'test';

const UploadLogo = () => {
  const {isMobile} = useScreenSize();
  const [removeTriggered, setRemoveTriggered] = useState(false);
  const [uploading, setUploading] = useState(false);

  const client = useApolloClient();
  const {contractor, user} = useContext(AppContext);
  const {supportedMimeTypes} = useSupportedMimeTypes({lazy: !user.id});
  const onRejectFiles = useOnRejectedFiles({
    eventName: 'Contractors/Cobranding/LogoUploadRejected',
  });
  const {updateContractor, loading} = useUpdateContractor();
  const addNotification = useNotifications();
  const isLoading = loading || uploading;
  const hasUploadedLogo = Boolean(contractor?.logoUrl);

  const imageMimeTypes = useMemo(() => {
    if (IS_TEST) return {'image/jpeg': ['.jpg']};

    return Object.keys(supportedMimeTypes).reduce((obj, key) => {
      const value = supportedMimeTypes[key];
      return key.includes('image') ? {...obj, [key]: value} : obj;
    }, {});
  }, [JSON.stringify(supportedMimeTypes)]);

  const onAcceptFiles = async ([file] = []) => {
    if (!file) {
      return false;
    }

    const {businessName, id: contractorId} = contractor;
    const date = formatIsoDate(new Date().toISOString(), {
      separator: '_',
      includeTime: true,
    });
    const fileName = `${snakeCase(businessName)}_${date}_${user?.id}`;

    const metadata = {
      businessName,
      uploadedBy: formatName(user),
      uploadedById: user?.id,
    };

    setUploading(true);
    try {
      const uploadedFile = await uploadDirect(file, {
        publicKey: process.env.REACT_APP_UPLOADCARE_LOGOS_KEY,
        metadata,
        fileName,
        baseCDN: process.env.REACT_APP_CDN_URL,
      });

      logger.debug('🖼️ File uploaded to UploadCare', uploadedFile);
      sendEvent('Contractors/Logo-Uploaded-To-Uploadcare', {
        metadata,
        fileName,
      });
      await saveLogoUrl(uploadedFile?.cdnUrl);
    } catch (err) {
      addNotification({
        type: 'error',
        message: 'There was a problem uploading this file. Please try again',
      });
      sendEvent('Contractors/Logo-Upload-Failed', {metadata, fileName});

      Sentry.captureException(new Error(err.message), {
        extra: {...metadata, fileName, contractorId, err},
      });
    } finally {
      setUploading(false);
    }
  };

  const onClickConfirmRemove = async (e) => {
    e.stopPropagation();
    await saveLogoUrl(null);
    setRemoveTriggered(false);
  };

  const onClickRemove = (e) => {
    e.stopPropagation();
    setRemoveTriggered(true);
  };

  const saveLogoUrl = async (logoUrl = null) => {
    const eventName = Boolean(logoUrl)
      ? 'Contractors/Logo-Updated'
      : 'Contractors/Logo-Removed';

    try {
      const {id} = contractor;
      const rsp = await updateContractor({
        variables: {contractor: {id, logoUrl}},
      });
      const contractorData = rsp?.data?.updateContractor?.contractor;

      if (contractorData) {
        sendEvent(eventName, {logoUrl});

        client.writeQuery({
          query: GET_CONTRACTOR_BY_ID,
          variables: {id: updateContractor.id},
          data: {
            contractorById: contractorData,
          },
        });
      }
    } catch (err) {
      sendEvent('Contractors/Failed-To-Update-Logo', {logoUrl});
      addNotification({
        type: 'error',
        message:
          'There was a problem saving your logo to your account. Please try again',
      });
    }
  };

  return (
    <AssetsColWrapper>
      <AssetsColTitle css={{fontSize: 32}}>
        Want to co-brand your assets?
      </AssetsColTitle>
      <AssetsColDescription>
        Upload your logo below and we’ll automatically co-brand your webpages
        and marketing materials.
      </AssetsColDescription>
      <Image width={156} mt={28} src={renofiLogoDarkIconSrc} />
      <Text my={28} color={lightGray} fontSize={40}>
        +
      </Text>
      <FileInput
        onAcceptFiles={onAcceptFiles}
        onRejectFiles={onRejectFiles}
        accept={imageMimeTypes}
        maxSize={MAX_FILE_SIZE}
        disabled={isLoading}
        multiple={false}
        icon={false}
        text={
          <>
            {!hasUploadedLogo && (
              <Flex flexDirection="column" alignItems="center">
                <UploadIcon />
                <Text
                  mt={24}
                  fontSize={20}
                  textAlign="center"
                  width="100%"
                  color={darkBlue}
                  fontWeight={900}>
                  {isMobile ? 'Upload' : 'Drop'} your logo here
                </Text>
                <Text
                  mt="8px"
                  fontSize={20}
                  textAlign="center"
                  width="100%"
                  color={darkBlue}>
                  to co-brand your marketing assets before sharing.
                </Text>
                {isMobile && (
                  <Button xSmall secondary css={{marginTop: 36, width: 'auto'}}>
                    Upload logo
                  </Button>
                )}
              </Flex>
            )}
            {hasUploadedLogo && (
              <Flex flexDirection="column" alignItems="center">
                <Text
                  mt="8px"
                  fontSize={20}
                  textAlign="center"
                  width="100%"
                  color={lightGray}>
                  Your logo
                </Text>
                <Image height={160} my={40} src={contractor?.logoUrl} />
                {!removeTriggered && (
                  <Button
                    secondary
                    xSmall={isMobile}
                    onClick={onClickRemove}
                    css={{width: 'auto'}}>
                    Remove logo
                  </Button>
                )}
                {removeTriggered && (
                  <Button
                    bgColor="#fff"
                    borderColor={red}
                    xSmall={isMobile}
                    color={red}
                    onClick={onClickConfirmRemove}
                    css={{width: 'auto'}}>
                    Confirm remove logo
                  </Button>
                )}
              </Flex>
            )}
          </>
        }
        contentCss={{
          backgroundColor: 'rgba(229, 245, 255, 0.6)',
          padding: ['28px 16px', hasUploadedLogo ? '58px' : '95px 68px'],
          '&:hover': {backgroundColor: '#E5F5FF'},
        }}
      />
    </AssetsColWrapper>
  );
};

export default UploadLogo;
