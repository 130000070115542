// TODO check which contractor field is dedicated to this question
export const BUSINESS_TYPES = [
  {
    label: 'Design-Builder',
    value: 'design_builder',
  },
  {
    label: 'General Contractor',
    value: 'general_contractor',
  },
  {
    label: 'Sub-contractor',
    value: 'sub_contractor',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PROJECT_TYPES = [
  {
    label: 'Kitchen',
    value: 'kitchen',
  },
  {
    label: 'Primary bathroom/bedroom',
    value: 'primary_bathroom_or_bedroom',
  },
  {
    label: 'Change existing floor plan',
    value: 'changing_existing_floor_plan',
  },
  {
    label: 'Addition',
    value: 'addition',
  },
  {
    label: 'ADU',
    value: 'accessory_dwelling_unit',
  },
  {
    label: 'Basement',
    value: 'basement',
  },
  {
    label: 'Mudroom',
    value: 'mudroom',
  },
  {
    label: 'Patio/Deck',
    value: 'patio_or_deck',
  },
  {
    label: 'Pool',
    value: 'pool',
  },
  {
    label: 'Exterior',
    value: 'exterior',
  },
  {
    label: 'Roofing',
    value: 'roofing',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const PROJECTS_TO_BE_DONE_THIS_YEAR = [
  {
    label: 'Less than 10',
    value: 5,
  },
  {
    label: '10 - 15',
    value: 15,
  },
  {
    label: '16 - 20',
    value: 20,
  },
  {
    label: '21 - 30',
    value: 30,
  },
  {
    label: '31 - 50',
    value: 50,
  },
  {
    label: 'More than 50',
    value: 75,
  },
];

export const AVG_PROJECT_SIZE = [
  {
    label: 'Less than $20K',
    value: 10000,
  },
  {
    label: '$20K - $50K',
    value: 50000,
  },
  {
    label: '$51K - $80K',
    value: 80000,
  },
  {
    label: '$81K - $100K',
    value: 100000,
  },
  {
    label: '$101K - $150K',
    value: 150000,
  },
  {
    label: '$151K - $200K',
    value: 200000,
  },
  {
    label: '$201K - $500K',
    value: 500000,
  },
  {
    label: 'More than $500K',
    value: 750000,
  },
];
