import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {COMPLETE_REGISTRATION} from '../mutations';

export default function useCompleteRegistration({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [completeRegistration, {data, loading, error}] = useMutation(
    COMPLETE_REGISTRATION,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const user = data?.user;
  return {completeRegistration, user, loading, error};
}
