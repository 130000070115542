import styled from '@emotion/styled';
import {Box} from 'rebass';

import {SelectField as CommonSelect} from '@renofi/components';

export const RoundedBox = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '12px',
  padding: '20px',
});

export const SelectField = styled(CommonSelect)({
  '& + svg': {
    display: 'none',
  },
});
