import {useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {Flex, Text} from 'rebass';

import {useContractorEstimateById} from '@renofi/api';
import {Loader} from '@renofi/components';
import {red} from '@renofi/utils';
import {useChannel} from '@renofi/utils/src/pusher';

import {Layout} from '../components';

import {EstimateSubmission, LeadSubmission, ShareEstimate} from './components';

const EstimateDetails = () => {
  const {estimateId} = useParams();
  const {
    estimate,
    loading,
    refetch: refetchEstimate,
  } = useContractorEstimateById({
    variables: {contractorEstimateId: estimateId},
  });
  const [estimateCompleted, setEstimateCompleted] = useState(false);

  useChannel({
    channelName: `contractor_scenario_created--estimate=${estimateId}`,
    onUpdate: () => refetchEstimate(),
  });

  useEffect(() => {
    if (estimate?.scenarioId) {
      setEstimateCompleted(true);
    }
  }, [estimate?.scenarioId]);

  return (
    <Layout.AuthenticatedPage
      title={
        <>
          {!loading && (
            <Flex role="page_title" alignItems="baseline">
              <Layout.BackArrow size={24} to="/financing-estimates" />
              <Text fontSize={[32, 40]} lineHeight="normal" fontWeight={700}>
                {estimate?.propertyAddress}
              </Text>
            </Flex>
          )}
        </>
      }>
      {!loading && (
        <Flex flexDirection="column" css={{gap: 31}}>
          <EstimateSubmission
            title="Address & Financing details"
            estimate={estimate}
          />
          <LeadSubmission
            estimate={estimate}
            onSubmit={() => setEstimateCompleted(true)}
          />
          <ShareEstimate
            estimate={estimate}
            estimateCompleted={estimateCompleted}
          />
        </Flex>
      )}
      {loading && (
        <Flex>
          <Loader color={red} />
        </Flex>
      )}
    </Layout.AuthenticatedPage>
  );
};

export default EstimateDetails;
