import {makeVar, useReactiveVar} from '@apollo/client';
import {without} from 'lodash';

import {STATUSES} from './constants';

// Reactive variables
const designStatusesVar = makeVar(STATUSES);

export default function useDesignStatuses() {
  const designStatuses = useReactiveVar(designStatusesVar);

  const setDesignStatuses = (status) => {
    const designStatuses = designStatusesVar();
    if (designStatuses.includes(status)) {
      designStatusesVar(without(designStatuses, status));
    } else {
      designStatusesVar(designStatuses.concat(status));
    }
  };

  return {
    designStatuses,
    setDesignStatuses,
  };
}
