import {useState} from 'react';

import {Flex, Box, Text} from 'rebass';

import {
  Modal,
  Label,
  TextField,
  PhoneField,
  AddressField,
  CurrencyField,
  StatesField,
  Textarea,
  Button,
  Checkbox,
  CheckboxLabel,
} from '@renofi/components';
import {blueGray, gray} from '@renofi/utils';

import {Layout, EmailField} from '../../../components';

const SubmissionCheckbox = (id, checked, name, onChange, children) => (
  <Flex
    alignItems="center"
    css={{border: `1px solid ${blueGray}`, padding: 12, borderRadius: 8}}>
    <Label css={{cursor: 'pointer'}}>
      <Box minWidth={32}>
        <Checkbox
          checked={checked}
          id={id}
          name={name}
          onChange={() => onChange(!checked)}
        />
      </Box>
      <CheckboxLabel fontSize={16}>{children}</CheckboxLabel>
    </Label>
  </Flex>
);

const EditClientModal = ({show, onCancel}) => {
  const [client, _etClient] = useState({});
  return (
    <Modal
      light
      show={show}
      hideCloseButton
      contentCss={{width: 740, paddingBottom: 0}}>
      <Layout.PanelTitleV2>Summary</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Flex
          flexDirection="column"
          padding={['28px 16px', '32px 40px']}
          css={{
            borderTop: `1px solid ${blueGray}`,
          }}>
          <Text fontSize={18} mb={24}>
            Client details
          </Text>
          <Flex css={{gap: 24}}>
            <Box flex={1}>
              <Label small htmlFor="firstName">
                First name
              </Label>
              <TextField id="firstName" value={client?.firstName} />
            </Box>
            <Box flex={1}>
              <Label small htmlFor="lastName">
                Last name
              </Label>
              <TextField id="lastName" value={client?.firstName} />
            </Box>
          </Flex>
          <Flex mt={24} css={{gap: 24}}>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="email">
                Email
              </Label>
              <EmailField id="email" placeholder={null} value={client?.email} />
            </Box>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="phoneNumber">
                Phone number
              </Label>
              <PhoneField
                id="phoneNumber"
                topMessage="optional"
                stripCountryCode
                value={client?.phoneNumber}
              />
            </Box>
          </Flex>

          <Text fontSize={18} mt={32} mb={24}>
            Project details
          </Text>
          <AddressField
            label="Address"
            placeholder="Search for address"
            value={client.propertyAddress}
            onChange={() => {}}
          />
          <Flex mt={24} css={{gap: 24}}>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="propertyBudget">
                Property budget
              </Label>
              <CurrencyField
                icon
                id="propertyBudget"
                value={client?.propertyBudget}
              />
            </Box>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="propertyState">
                Property state
              </Label>
              <StatesField
                id="propertyState"
                topMessage="optional"
                stripCountryCode
                value={client?.propertyState}
              />
            </Box>
          </Flex>
          <Box width="100%" mt={32}>
            <Label fontSize={14} color={gray} htmlFor="projectDetails">
              Enter a brief description of the project
            </Label>
            <Textarea
              height={143}
              id="projectDetails"
              value={client?.projectDetails}
            />
          </Box>
          <SubmissionCheckbox
            mt={32}
            id="consentGiven"
            name="consentGiven"
            checked={client?.consentGiven}>
            I have obtained my client’s consent to share their contact info with
            RenoFi.
          </SubmissionCheckbox>
          <SubmissionCheckbox
            mt={32}
            id="hasInterestInFinancing"
            name="hasInterestInFinancing"
            checked={client?.hasInterestInFinancing}>
            I would also like RenoFi to contact this client about financing
            options for their renovation.
          </SubmissionCheckbox>
        </Flex>
        <Layout.PanelCTAWrapper small>
          <Button secondary xSmall onClick={onCancel}>
            Cancel
          </Button>
          <Button xSmall css={{marginLeft: 16}}>
            Save details
          </Button>
        </Layout.PanelCTAWrapper>
      </Layout.PanelInnerWrapper>
    </Modal>
  );
};

export default EditClientModal;
