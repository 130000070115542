import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {SET_DOCUMENT_TYPE} from '../mutations';

const IS_TEST = process.env.NODE_ENV === 'test';

export default function useSetDocumentType({
  onCompleted = noop,
  ...options
} = {}) {
  const refetchQueries = IS_TEST ? [] : options?.refetchQueries || [];
  const [setDocumentType, {data, loading, error}] = useMutation(
    SET_DOCUMENT_TYPE,
    {onCompleted, refetchQueries, options},
  );
  const response = data?.setDocumentType;
  return {response, loading, setDocumentType, error};
}
