import {Flex} from 'rebass';
import {useHistory} from 'react-router-dom';

import {Layout} from '../components';

import {
  EstimateSubmission,
  SavedEstimates,
  EstimatesUserGuide,
} from './components';

const EstimatesDashboard = () => {
  const history = useHistory();

  return (
    <Layout.AuthenticatedPage title="Financing estimates">
      <Flex flexDirection="column" css={{gap: 31}}>
        <EstimatesUserGuide />
        <EstimateSubmission
          title="Create and share a new financing estimate"
          onSubmit={(estimate) => {
            history.push(`/financing-estimates/${estimate.id}`);
          }}
        />
        <SavedEstimates />
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default EstimatesDashboard;
