import {Flex, Box, Text} from 'rebass';

import {blue} from '@renofi/utils';

const ValuePreview = ({
  label,
  value,
  defaultValue = '-',
  children,
  pointed,
  showAttributes = false,
  loading = false,
  ...props
}) => (
  <Flex flexDirection="column" {...props}>
    <Text fontSize={14} mb={'8px'}>
      {label}
    </Text>
    <Box
      backgroundColor="#D9E7F033"
      padding={['16px 20px', '16px 0 40px 20px']}
      flex={1}
      css={{
        borderRadius: '8px',
        border: pointed ? `1px solid ${blue}` : 'none',
      }}>
      <Text
        mb={12}
        fontSize={28}
        fontWeight={pointed ? 700 : 400}
        color="black"
        minHeight={30}
        css={
          loading && {
            borderRadius: 4,
            opacity: 0.4,
            background:
              'linear-gradient(90deg, rgba(115, 115, 115, 0.20) 5.31%, rgba(217, 217, 217, 0.10) 93.72%)',
          }
        }>
        {!loading && <>{value || defaultValue}</>}
      </Text>
      {showAttributes && !loading && children}
    </Box>
  </Flex>
);

export default ValuePreview;
