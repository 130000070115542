import {lightYellow, lightGreen} from '@renofi/utils';

import {ESTIMATE_STATUSES} from './constants';

const EstimateStatusIcon = ({status}) => {
  const {INCOMPLETE, VIEWED} = ESTIMATE_STATUSES;

  const color = status === INCOMPLETE ? lightYellow : lightGreen;
  const fill = status === VIEWED ? color : 'transparent';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 10 11"
      fill="none"
      style={{marginRight: '8px'}}>
      <circle
        cx="5"
        cy="5.83325"
        r="4"
        fill={fill}
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default EstimateStatusIcon;
