import {Flex, Text} from 'rebass';

import {fadeGreen, darkGreen} from '@renofi/utils';

export default () => (
  <Flex
    backgroundColor={fadeGreen}
    justifyContent="center"
    alignItems="center"
    padding={37}>
    <Text color={darkGreen}>
      © 2024 Renovation Finance LLC DBA RenoFi Licensing - NMLS # 1802847 /
      2412747{' '}
    </Text>
  </Flex>
);
