import {Flex, Text} from 'rebass';
import styled from '@emotion/styled/macro';

import {darkBlue, gray, mediaQuery} from '@renofi/utils';

export const AssetsColWrapper = styled(Flex)(({css}) =>
  mediaQuery({
    flex: 1,
    padding: ['32px 16px', 40],
    flexDirection: 'column',
    alignItems: 'center',
    ...css,
  }),
);

export const AssetsColTitle = styled(Text)(({css}) =>
  mediaQuery({
    fontSize: [32, 40],
    lineHeight: 'normal',
    color: darkBlue,
    textAlign: 'center',
    ...css,
  }),
);

export const AssetsColDescription = styled(Text)(({css}) =>
  mediaQuery({
    maxWidth: 540,
    marginTop: 16,
    color: gray,
    fontSize: [16, 20],
    textAlign: 'center',
    ...css,
  }),
);
