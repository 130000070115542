import styled from '@emotion/styled/macro';

import {Table as CommonTable, Th as CommonTh} from '@renofi/components';

export const Table = styled(CommonTable)({
  fontSize: 14,
  borderBottom: 'none',
  '& td': {
    border: 'none',
  },
  '& tr:last-child, tr:last-child td': {
    borderBottom: 'none',
  },
});

export const Th = styled(CommonTh)({
  fontWeight: '600',
  backgroundColor: '#D9E7F033',
  padding: '21px 24px 21px 32px',
  border: 'none',
  textAlign: 'left',
  whiteSpace: 'nowrap',
});

export const Tr = styled.tr({
  '&:hover': {
    backgroundColor: '#D9E7F033',
    cursor: 'pointer',
  },
});

export const Td = styled.td(({css}) => ({
  padding: '21px 24px 21px 32px',
  maxWidth: 300,
  textAlign: 'left',
  ...css,
}));
