import {useRef, useState} from 'react';

import {capitalize, keys, omit} from 'lodash';
import {Flex, Text} from 'rebass';

import {
  ClickOutside,
  CloseIcon,
  FilterIcon,
  SelectField,
} from '@renofi/components';
import {blue, humanizeCase, lightGray} from '@renofi/utils';

import {FilterButton, FilterDropdown} from './styled';
import {FILTER_OPTIONS} from './constants';

const NULLISH = 'None';

const toSentence = (str) =>
  capitalize(
    humanizeCase(str)
      .replace(/entity/i, '')
      .trim(),
  );

const FilterBy = ({filterBy, onChange}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const filterByKeys = keys(filterBy);
  const isActive = Boolean(filterByKeys.length);

  const onChangeFilter = (filterKey) => (v) => {
    const value = v.split(',');
    const isRemoved = v === NULLISH;

    onChange((state) =>
      isRemoved ? omit(state, [filterKey]) : {...state, [filterKey]: value},
    );
  };

  const onClickClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange({});
  };

  return (
    <div style={{position: 'relative'}} ref={ref}>
      <FilterButton
        open={open}
        alignItems="center"
        onClick={() => setOpen(true)}>
        <FilterIcon color={isActive ? blue : lightGray} />
        <Text ml={3}>
          Filter results
          {isActive ? ` (${filterByKeys.length})` : null}
        </Text>

        {isActive ? (
          <CloseIcon style={{marginLeft: 8}} onClick={onClickClear} />
        ) : null}
      </FilterButton>

      {open ? (
        <ClickOutside ref={ref} onClickOutside={() => setOpen(false)}>
          <FilterDropdown>
            {keys(FILTER_OPTIONS).map((key) => {
              const data = FILTER_OPTIONS[key];
              const title = toSentence(key);
              const currentValue = filterBy[key] || '';

              return (
                <Flex
                  my={3}
                  px={2}
                  justifyContent="space-between"
                  alignItems="center"
                  key={key}>
                  <Text fontSize={14}>{title}</Text>

                  <SelectField
                    css={{width: 200}}
                    onChange={onChangeFilter(key)}
                    value={currentValue}>
                    <option value={null}>{NULLISH}</option>
                    {data.map((v) => {
                      const isArray = Array.isArray(v);
                      const label = isArray ? v[0] : v;

                      return (
                        <option key={v} value={v}>
                          {humanizeCase(label)}
                        </option>
                      );
                    })}
                  </SelectField>
                </Flex>
              );
            })}
          </FilterDropdown>
        </ClickOutside>
      ) : null}
    </div>
  );
};

export default FilterBy;
