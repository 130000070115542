import {Flex, Box} from 'rebass';

import {blueGray} from '@renofi/utils';
import {Label, Checkbox, CheckboxLabel} from '@renofi/components';

const SubmissionCheckbox = ({
  id,
  name,
  checked,
  onChange,
  children,
  ...props
}) => (
  <Flex
    alignItems="center"
    css={{border: `1px solid ${blueGray}`, padding: 12, borderRadius: 8}}
    {...props}>
    <Label css={{cursor: 'pointer'}}>
      <Box minWidth={32}>
        <Checkbox
          checked={checked}
          id={id}
          name={name}
          onChange={() => onChange(!checked)}
        />
      </Box>
      <CheckboxLabel fontSize={16}>{children}</CheckboxLabel>
    </Label>
  </Flex>
);

export default SubmissionCheckbox;
