import {sendEvent} from '@renofi/analytics';

import {launchChilipiper} from '../analytics';
import {EMAIL_ADDRESS, PHONE_NUMBER} from '../constants';

export default function useContactUsCallbacks({user}) {
  const onClickPhone = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    sendEvent('Contractors/ContactUs/PhoneNumber-Clicked');
    window.location.href = `tel:${PHONE_NUMBER.replace(/-/gi, '')}`;
  };

  const onClickEmail = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    sendEvent('Contractors/ContactUs/Email-Clicked');
    window.location.href = `mailto:${EMAIL_ADDRESS}`;
  };

  const onClickIntercom = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    sendEvent('Contractors/ContactUs/Intercom-Clicked');
    if (Boolean(window.Intercom)) {
      window.Intercom('show');
    }
  };

  const onClickCalendarLink = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (user) {
      sendEvent('Contractors/ContactUs/Chilipiper-Clicked');
      launchChilipiper({user});
    }
  };

  return {
    onClickCalendarLink,
    onClickEmail,
    onClickIntercom,
    onClickPhone,
  };
}
