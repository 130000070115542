import {Flex, Box, Text} from 'rebass';

import {lightGray, red, useScreenSize} from '@renofi/utils';
import {CurrencyField, Label, InfoIcon} from '@renofi/components';

import SubmissionSection from './SubmissionSection';

const ProjectValuesSection = ({
  renovationCost,
  setRenovationCost,
  propertyAddress,
  estimatedCurrentPropertyValue,
  setEstimatedCurrentPropertyValue,
  homeValueAvailable,
}) => {
  const {isDesktop} = useScreenSize();

  return (
    <SubmissionSection
      step={2}
      label={
        isDesktop ? 'Enter estimated project cost' : 'Enter financial details'
      }
      css={{
        borderLeft: 'none',
        borderRight: 'none',
      }}>
      <Flex width="100%" flexDirection={['column', 'row']}>
        <Box flex={1 / 2} width={['100%', 'auto']}>
          <Label fontSize={14} color={lightGray} htmlFor="renovationCost">
            Estimated project cost
          </Label>
          <CurrencyField
            icon
            id="renovationCost"
            name="renovationCost"
            value={renovationCost}
            onChange={setRenovationCost}
          />
          {renovationCost && renovationCost < 10000 && (
            <Flex mt={10} alignItems="center">
              <div style={{flex: '1 0 auto'}}>
                <InfoIcon color={red} size={16} />
              </div>
              <Text mt={1} ml="8px" fontSize={14} color={red}>
                Renofi loans are suitable for projects costing $10,000 or more.
              </Text>
            </Flex>
          )}
        </Box>
        {propertyAddress && homeValueAvailable && (
          <Box flex={1 / 2} ml={[0, 32]} mt={[32, 0]} width={['100%', 'auto']}>
            <Label fontSize={14} color={lightGray} htmlFor="propertyValue">
              Estimated current property value
            </Label>
            <CurrencyField
              icon
              id="propertyValue"
              name="propertyValue"
              value={estimatedCurrentPropertyValue}
              onChange={setEstimatedCurrentPropertyValue}
            />
          </Box>
        )}
      </Flex>
    </SubmissionSection>
  );
};

export default ProjectValuesSection;
