import {useEffect} from 'react';

import {useHistory, useLocation} from 'react-router-dom';

export default () => {
  const history = useHistory();
  const {search} = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const projectId = params.get('projectId');

    if (projectId) {
      history.push(`/financing/${projectId}`);
    }
  }, []);
};
