import {useEffect, useState, useRef} from 'react';

import {useLocation} from 'react-router-dom';
import {Flex, Box, Image, Text} from 'rebass';

import {QuoteIcon} from '@renofi/components';
import {lightBlue, red} from '@renofi/utils';

import {AUTH_PATHS} from '../../constants';

import {TESTIMONIALS, DESIGN_TESTIMONIALS} from './constants';
import DesignLogo from './DesignLogo';

const Testimonials = () => {
  const location = useLocation();

  const isDesignTestimonial = [
    AUTH_PATHS.LOGIN,
    AUTH_PATHS.LOGIN_SUCCESS,
  ].includes(location.pathname);
  const testimonials = isDesignTestimonial ? DESIGN_TESTIMONIALS : TESTIMONIALS;

  const testimonialId = useRef(0);
  const [testimonial, setTestimonial] = useState(testimonials[0]);

  const {isClient, img, quote, author, details} = testimonial;

  useEffect(() => {
    const interval = setInterval(() => {
      if (testimonialId.current === testimonials.length - 1) {
        testimonialId.current = 0;
        setTestimonial(testimonials[0]);
        return;
      }

      testimonialId.current++;
      setTestimonial(testimonials[testimonialId.current]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      padding={74}
      css={{gap: 35}}>
      {isDesignTestimonial && <DesignLogo light />}
      <Flex flexDirection="column" alignItems="center" css={{gap: 25}}>
        {isClient && <Image size={89} src={img} />}
        {!isClient && <Image src={img} />}
        <Flex alignItems="start" justifyContent="space-between">
          <QuoteIcon color="#fff" />
          <Text
            fontSize={20}
            fontStyle="italic"
            lineHeight="24px"
            textAlign="center"
            maxWidth={346}>
            {quote}
          </Text>
          <QuoteIcon color="#fff" css={{transform: 'rotate(180deg)'}} />
        </Flex>
        <Text
          fontSize={24}
          lineHeight="28px"
          textAlign="center"
          color={lightBlue}>
          {author}
        </Text>

        <Text
          fontSize={16}
          fontStyle="italic"
          lineHeight="28px"
          textAlign="center">
          {details}
        </Text>
      </Flex>
      <Flex css={{gap: 12}}>
        {testimonials.map((_, i) => (
          <Box
            key={i}
            size={12}
            backgroundColor={i === testimonialId.current ? red : '#D9D9D9'}
            css={{borderRadius: '100%'}}
          />
        ))}
      </Flex>
    </Flex>
  );
};
export default Testimonials;
