import React, {useContext, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Box, Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {hasUrlProtocol, useLocalStorage} from '@renofi/utils';
import {AddressField, Button, Form, Label, TextField} from '@renofi/components';
import {DEFAULT_PLACE_FIELDS} from '@renofi/utils/src/places';
import {getStateFromZipCode, isUrl} from '@renofi/utils';

import AppContext from '../context';
import {Layout} from '../components';
import {ACCOUNT_SETUP_PATHS} from '../constants';
import {getContractorCacheKey} from '../utils';

import {RoundedBox} from './styled';
import {areFieldsValid} from './utils';

const BusinessDetails = () => {
  const {user} = useContext(AppContext);

  const history = useHistory();
  const [contractor, cacheContractor] = useLocalStorage(
    getContractorCacheKey(user?.id),
    {},
  );

  const [searchValue, setSearchValue] = useState('');
  const [showManual, setShowManual] = useState(
    contractor.businessName ? true : false,
  );
  const [businessDetails, setBusinessDetails] = useState({
    businessName: contractor.businessName,
    streetAddress: contractor.streetAddress,
    city: contractor.city,
    zipCode: contractor.zipCode,
    state: contractor.state,
    website: contractor.website,
  });
  const disabled = !areFieldsValid(businessDetails);

  const onChangeValue = (key) => (value) => {
    if (key === 'website') {
      return setBusinessDetails((s) => ({
        ...s,
        [key]: value.replace(/ /g, ''),
      }));
    }
    if (key === 'zipCode') {
      const {code: state} = getStateFromZipCode(value) || {};
      return setBusinessDetails((s) => ({...s, [key]: value, state}));
    }
    setBusinessDetails((s) => ({...s, [key]: value}));
  };

  const onClickManual = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowManual(true);
  };

  const onSubmit = async () => {
    const {website, ...rest} = businessDetails;
    const hasProtocol = Boolean(!website || hasUrlProtocol(website));
    const formattedBusinessDetails = {
      ...rest,
      website: hasProtocol ? website : `http://${website}`,
    };
    cacheContractor({...contractor, ...formattedBusinessDetails});

    sendEvent('Contractors/Business-Details-Added');
    history.push(ACCOUNT_SETUP_PATHS.BUSINESS_INFO);
  };

  const onSelectAddress = (address, place) => {
    const {city, stateCode: state, streetAddress, zipCode} = address;
    const {name: businessName, website} = place;

    setBusinessDetails({
      businessName,
      city,
      state,
      streetAddress,
      website,
      zipCode,
    });
    setShowManual(true);
  };

  return (
    <Layout.AuthenticatedPage
      fullScreen
      width="100%"
      css={{maxWidth: 678, margin: '0 auto 0 auto'}}>
      <Text
        fontSize={[32, 36]}
        textAlign={['center', 'left']}
        lineHeight={['38px', '46px']}>
        Complete your account setup by providing a few details.
      </Text>

      <RoundedBox mt={3}>
        <AddressField
          autocomplete={false}
          label={'Search for business name and location'}
          onChange={setSearchValue}
          onClickManual={onClickManual}
          onSelect={onSelectAddress}
          placeFields={[...DEFAULT_PLACE_FIELDS, 'types', 'website']}
          placeTypes={[
            'electrician',
            'general_contractor',
            'painter',
            'plumber',
            'roofing_contractor',
          ]}
          value={searchValue}
        />
      </RoundedBox>

      {showManual ? (
        <RoundedBox mt={3}>
          <Form onSubmit={onSubmit}>
            <Flex mt={4} css={{gap: 24}}>
              <Box flex={1}>
                <Label small htmlFor="businessName">
                  Business name
                </Label>
                <TextField
                  id="businessName"
                  name="businessName"
                  onChange={onChangeValue('businessName')}
                  placeholder="Business name"
                  value={businessDetails?.businessName}
                />
              </Box>
            </Flex>
            <Flex my={4} css={{gap: 24}}>
              <Box flex={1}>
                <Label small htmlFor="streetAddress">
                  Address
                </Label>
                <TextField
                  id="streetAddress"
                  name="streetAddress"
                  onChange={onChangeValue('streetAddress')}
                  placeholder="Street address"
                  value={businessDetails?.streetAddress}
                />
              </Box>
            </Flex>
            <Flex my={4} css={{gap: 24}} flexDirection={['column', 'row']}>
              <Box flex={[1, 0.5]}>
                <Label small htmlFor="city">
                  City
                </Label>
                <TextField
                  id="city"
                  name="city"
                  onChange={onChangeValue('city')}
                  placeholder="City"
                  value={businessDetails?.city}
                />
              </Box>
              <Box flex={[1, 0.5]}>
                <Label small htmlFor="zipCode">
                  Zip code
                </Label>
                <TextField
                  id="zipCode"
                  name="zipCode"
                  onChange={onChangeValue('zipCode')}
                  placeholder="Zip code"
                  value={businessDetails?.zipCode}
                />
              </Box>
            </Flex>
            <Flex my={4}>
              <Box flex={[1, 0.7]}>
                <Label small htmlFor="website">
                  Website
                </Label>
                <TextField
                  error={
                    Boolean(
                      businessDetails?.website &&
                        !isUrl(businessDetails?.website),
                    )
                      ? 'Invalid URL given'
                      : null
                  }
                  id="website"
                  name="website"
                  onChange={onChangeValue('website')}
                  placeholder="website"
                  value={businessDetails?.website}
                />
              </Box>
            </Flex>

            <Box>
              <Button role="submit_manual_onboarding" disabled={disabled}>
                Next
              </Button>
            </Box>
          </Form>
        </RoundedBox>
      ) : null}
    </Layout.AuthenticatedPage>
  );
};

export default BusinessDetails;
