import React, {useEffect} from 'react';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {useUnleashContext} from '@renofi/utils/src/feature-flags';
import {useIntercomIdentity} from '@renofi/api';
import {Toast} from '@renofi/components';
import {useUTMTags, useUpdateUnleashContext} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

import {useMyAccount, useProjectRedirectById} from '../api';
import {Layout, AuthLogin} from '../components';
import {ONBOARDING_PATH, ACCOUNT_SETUP_PATHS} from '../constants';
import Design from '../Design';
import Financing from '../Financing';
import FinancingEstimates from '../FinancingEstimates';
import Marketing from '../Marketing';
import Login, {CompleteLogin} from '../Login';
import Signup, {SignupV2, CompleteRegistration} from '../Signup';
import CheckEmail from '../CheckEmail';
import BusinessInfo from '../BusinessInfo';
import MyAccountContext from '../context';
import BusinessContact from '../BusinessContact';
import BusinessDetails from '../BusinessDetails';
import QuickStart from '../QuickStart';

import {identifyAnalytcs} from './utils';

const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const {user, ...providerValue} = useMyAccount();
  const {fetch} = useIntercomIdentity();
  const isLoggedIn = providerValue?.isLoggedIn;
  const {persistUTMTags} = useUTMTags(UTM_COOKIE_KEYS.renofi);

  const updateContext = useUnleashContext();
  useUpdateUnleashContext(updateContext);

  useProjectRedirectById();

  useEffect(() => {
    persistUTMTags();
  }, []);

  useEffect(() => {
    (async () => {
      updateContext({nodeEnv: process.env.NODE_ENV});

      if (!user?.id) {
        return;
      }

      const rsp = IS_PRODUCTION ? await fetch() : {};
      const intercomIdentity = rsp?.data?.result;
      // @TODO We may want to include staging at some point

      identifyAnalytcs({intercomIdentity, user});
    })();
  }, [user?.id]);

  useEffect(() => {
    if (
      !Object.values(ACCOUNT_SETUP_PATHS).includes(location.pathname) &&
      providerValue?.isOnboardingRequired
    ) {
      history.push(ONBOARDING_PATH);
    }
  }, [providerValue?.isOnboardingRequired]);

  return (
    <MyAccountContext.Provider value={{...providerValue, user}}>
      {/* {!providerValue.isContractor && providerValue.isLoggedIn ? (
        <Alert warning>Note: user category is not contractor</Alert>
      ) : null} */}
      <Layout.Container>
        <Switch>
          <Route exact path="/register">
            <SignupV2 />
          </Route>

          <Route exact path="/register/success">
            <CheckEmail />
          </Route>

          <Route exact path="/register/:token">
            <CompleteRegistration />
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>

          <Route exact path="/password-login">
            <AuthLogin />
          </Route>

          <Route exact path="/login/success">
            <CheckEmail />
          </Route>

          <Route exact path="/login/:token">
            <CompleteLogin />
          </Route>

          <Route exact path="/business-info">
            <BusinessInfo />
          </Route>

          <Route exact path="/welcome">
            <QuickStart />
          </Route>

          <Route exact path="/">
            {isLoggedIn ? <Redirect to="/welcome" /> : <Login />}
          </Route>

          <Route path="/marketing">
            <Marketing />
          </Route>

          <Route path="/design/:currentTab?/:currentSubTab?">
            <Design />
          </Route>

          <Route path="/financing">
            <Financing />
          </Route>

          <Route path="/financing-estimates">
            <FinancingEstimates />
          </Route>

          <Route exact path={ACCOUNT_SETUP_PATHS.BUSINESS_DETAILS}>
            <BusinessDetails />
          </Route>

          <Route path="/signup">
            <Signup />
          </Route>

          <Route path="/business-contact">
            <BusinessContact />
          </Route>

          <Redirect to="/" />
        </Switch>
      </Layout.Container>

      <Toast />
    </MyAccountContext.Provider>
  );
};

export default App;
