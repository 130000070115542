import styled from '@emotion/styled';
import {Flex, Text} from 'rebass';

import {darkBlue, blueGray, lightGreen, mediaQuery} from '@renofi/utils';

export const TOP_BAR_HEIGHT = 68;

export const Avatar = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    borderRadius: 12,
    height: 40,
    backgroundColor: lightGreen,
    color: darkBlue,
    fontSize: 14,
    fontWeight: 'bold',
  }),
);

export const DropDownWrapper = styled(Flex)(
  mediaQuery({
    cursor: 'pointer',
    alignItems: 'center',
    position: 'relative',
    height: 'calc(100% - 8px)',
    borderRadius: 15,
    padding: [0, '8px 16px'],
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.05)',
    },
  }),
);

export const MenuWrapper = styled.ul(
  mediaQuery({
    position: ['fixed', 'absolute'],
    zIndex: 2,
    right: 0,
    top: [TOP_BAR_HEIGHT, `calc(${TOP_BAR_HEIGHT}px - 8px)`],

    width: ['100vw', 350],
    border: ['none', `1px solid ${blueGray}`],
    borderRadius: [0, 16],
    boxShadow: [
      '0px 4px 4px 0px rgba(33, 47, 73, 0.20)',
      '0px 1px 4px 0px rgba(33, 47, 73, 0.1)',
    ],
    listStyle: 'none',
    userSelect: 'none',
  }),
);

export const MenuTextItem = styled.li(
  mediaQuery({
    textAlign: 'left',
    alignItems: 'center',
    gap: 12,
    fontSize: 18,
    padding: 16,

    background: [darkBlue, 'white'],
    borderTop: ['1px solid #ffffff10', `1px solid ${blueGray}`],
    color: ['#ffffff60', `${darkBlue}60`],
    cursor: 'auto',

    'svg, img': {
      opacity: 0.2,
    },

    '&:first-of-type': {
      borderTopLeftRadius: [0, 16],
      borderTopRightRadius: [0, 16],
    },

    '&:last-of-type': {
      borderBottomLeftRadius: [0, 16],
      borderBottomRightRadius: [0, 16],
    },
  }),
);

export const OverflowText = styled(Text)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const MenuItem = styled(MenuTextItem)(
  mediaQuery({
    display: 'flex',

    'svg, img': {
      opacity: 0.2,
    },

    '&:active': {
      svg: {
        opacity: 1,
      },
    },

    '&:hover': {
      backgroundColor: ['transparent', '#EFF9FF'],
      color: ['#fff', darkBlue],
      'svg, img': {
        opacity: 1,
      },
    },
  }),
);

export const CloseMenu = styled(Flex)(
  mediaQuery({
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    borderRadius: 12,
    height: 40,
    border: '1px solid rgba(217, 231, 240, 0.20)',
    color: darkBlue,
    fontSize: 14,
    fontWeight: 'bold',
  }),
);
