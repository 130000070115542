import {useApolloClient} from '@apollo/client';

import {GET_CURRENT_USER} from '@renofi/api';

import {initAuthTokenRefresh, setCookieByDomain} from './utils';

export default () => {
  const client = useApolloClient();

  const completeAuth = async ({user, jwt, refreshToken}) => {
    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        result: user,
      },
    });
    // fetch();
    // Manually refresh (from cache) so the update from ^^ gets flushed into hook.

    setCookieByDomain('jwt', jwt);
    setCookieByDomain('refreshToken', refreshToken);
    initAuthTokenRefresh(client);
  };

  return completeAuth;
};
