const IS_DEPLOYED_ENV =
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'staging';

export default function initIntercom({user, intercomIdentity}) {
  const intercomId = process.env.REACT_APP_INTERCOM_ID;
  const extraParams = intercomIdentity
    ? {
        email: user.email,
        user_hash: intercomIdentity,
      }
    : {};

  if (!user?.id || !intercomId || !IS_DEPLOYED_ENV) {
    return;
  }

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${intercomId}`;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: intercomId,
    ...extraParams,
  });
}
