import {useState} from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils';

const AddNoteIcon = ({
  hoverColor = lightGray,
  onClick,
  color = lightGray,
  size = 20,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <svg
      onClick={onClick ? onClick : noop}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none">
      <mask
        id="mask0_2844_816"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={size}
        height={size}>
        <rect
          y="0.5"
          width="20"
          height="20"
          fill={hover ? hoverColor : color}
        />
      </mask>
      <g mask="url(#mask0_2844_816)">
        <path
          d="M4.16667 18C3.70833 18 3.31597 17.8368 2.98958 17.5104C2.66319 17.184 2.5 16.7917 2.5 16.3333V4.66667C2.5 4.20833 2.66319 3.81597 2.98958 3.48958C3.31597 3.16319 3.70833 3 4.16667 3H15.8333C16.2917 3 16.684 3.16319 17.0104 3.48958C17.3368 3.81597 17.5 4.20833 17.5 4.66667V10.25C17.2361 10.125 16.9653 10.0174 16.6875 9.92708C16.4097 9.83681 16.125 9.77083 15.8333 9.72917V4.66667H4.16667V16.3333H9.20833C9.25 16.6389 9.31597 16.9306 9.40625 17.2083C9.49653 17.4861 9.60417 17.75 9.72917 18H4.16667ZM4.16667 16.3333V4.66667V9.72917V9.66667V16.3333ZM5.83333 14.6667H9.22917C9.27083 14.375 9.33681 14.0903 9.42708 13.8125C9.51736 13.5347 9.61806 13.2639 9.72917 13H5.83333V14.6667ZM5.83333 11.3333H10.9167C11.3611 10.9167 11.8576 10.5694 12.4062 10.2917C12.9549 10.0139 13.5417 9.82639 14.1667 9.72917V9.66667H5.83333V11.3333ZM5.83333 8H14.1667V6.33333H5.83333V8ZM15 19.6667C13.8472 19.6667 12.8646 19.2604 12.0521 18.4479C11.2396 17.6354 10.8333 16.6528 10.8333 15.5C10.8333 14.3472 11.2396 13.3646 12.0521 12.5521C12.8646 11.7396 13.8472 11.3333 15 11.3333C16.1528 11.3333 17.1354 11.7396 17.9479 12.5521C18.7604 13.3646 19.1667 14.3472 19.1667 15.5C19.1667 16.6528 18.7604 17.6354 17.9479 18.4479C17.1354 19.2604 16.1528 19.6667 15 19.6667ZM14.5833 18H15.4167V15.9167H17.5V15.0833H15.4167V13H14.5833V15.0833H12.5V15.9167H14.5833V18Z"
          fill={hover ? hoverColor : color}
        />
      </g>
    </svg>
  );
};

export default AddNoteIcon;
