import React, {useContext, useEffect} from 'react';

import {Flex} from 'rebass';
import {useHistory} from 'react-router-dom';

import {Label} from '@renofi/components';

import {Layout, UserRegistration} from '../components';
import AppContext from '../context';

import {AuthContainer} from './styled';

const Signup = () => {
  const {isLoggedIn} = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      return history.push('/');
    }
  }, [isLoggedIn]);

  return (
    <Flex flexDirection="column" width={1} height="100vh">
      <AuthContainer flexDirection="column" mt={5} py={3}>
        <Layout.Logo my={3} />
        <Label mx={3}>Sign up by giving us your email address</Label>
        <UserRegistration />
      </AuthContainer>
    </Flex>
  );
};

export default Signup;
