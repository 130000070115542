import styled from '@emotion/styled';
import {Image, Flex, Text} from 'rebass';
import {NavLink} from 'react-router-dom';

import {mediaQuery} from '@renofi/utils';
import {darkBlue, red} from '@renofi/utils/src/colors';
import {MODAL_ZINDEX} from '@renofi/components';

import {
  MID_BREAKPOINT,
  NAVBAR_DESKTOP_MID_WIDTH,
  NAVBAR_DESKTOP_WIDTH,
} from '../constants';

const BORDER_STYLE = ['1px solid rgba(0, 96, 153, 0.15)', 'none'];
const FONT_STYLES = {
  color: darkBlue,
  cursor: 'pointer',
  lineHeight: '22px',
  textDecoration: 'none',
  fontSize: [20, 18],
  fontWeight: [300, 300],
};
const SVG_STYLES = {
  svg: {
    transition: 'all 200ms ease-out',
    marginTop: -4,
    width: 36,
    marginRight: 4,

    filter: 'grayscale(1)',
    opacity: 0.25,
  },
  '&.active, &:hover': {
    svg: {
      filter: 'grayscale(0)',
      opacity: 1,
    },
  },
};

export const Logo = styled(Image)(
  mediaQuery({
    width: [80, 120],
    height: 'auto',
    // paddingLeft: [0, 13],
  }),
);

export const DesktopSubLine = styled(Text)({
  fontSize: 12,
  [MID_BREAKPOINT]: {
    display: 'none',
  },
});

export const DesktopWrapper = styled.div(
  mediaQuery({
    position: 'fixed',
    height: '100vh',

    paddingBottom: 60,
    width: NAVBAR_DESKTOP_WIDTH,
    minWidth: NAVBAR_DESKTOP_WIDTH,
    paddingLeft: 8,
    paddingRight: 8,
    // top: 0,
    left: 0,
    zIndex: 100,
    background: 'white',
    borderRight: '1px solid #00609926',
    marginRight: 0,
    borderBottom: BORDER_STYLE,
    overflow: 'auto',
    transition: 'all 200ms',

    [MID_BREAKPOINT]: {
      width: NAVBAR_DESKTOP_MID_WIDTH,
      minWidth: NAVBAR_DESKTOP_MID_WIDTH,
    },
  }),
  ({isOpen}) =>
    mediaQuery({
      height: [isOpen ? '100vh' : 70, '100vh'],
    }),
);

export const Navigation = styled.nav(
  mediaQuery({
    marginTop: [0, 16],
    width: ['100%', 'auto'],
  }),
);

export const NavbarDesktopLink = styled(NavLink)(() =>
  mediaQuery({
    ':first-of-type': {
      borderTop: BORDER_STYLE,
    },
    borderBottom: BORDER_STYLE,
    borderRadius: 8,

    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: 8,
    paddingTop: 16,
    paddingBottom: 16,
    background: 'white',
    opacity: [0.8, 0.6],
    transition: 'all 200ms',

    ...FONT_STYLES,
    ...SVG_STYLES,

    '&.active': {
      background: '#EFF9FF',
      opacity: 1,
    },

    [MID_BREAKPOINT]: {
      display: 'block',
      paddingBottom: 8,
      marginBottom: 8,
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '18px',
    },
  }),
);

export const MoreOptionsMobile = styled(Flex)(
  mediaQuery({
    position: 'fixed',
    bottom: 70,
    left: 0,
    width: '100%',
    background: darkBlue,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1981)',
    zIndex: MODAL_ZINDEX - 2,
    flexDirection: 'column',
    color: 'white',
  }),
);

export const BottomNavMobile = styled(Flex)(
  mediaQuery({
    position: 'fixed',
    bottom: 0,
    left: 0,
    justifyContent: 'space-evenly',
    width: '100%',
    height: 70,
    background: darkBlue,
    zIndex: MODAL_ZINDEX - 1,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1981)',
  }),
);

export const NavbarMobileLink = styled(NavLink)(
  mediaQuery({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 75,
    padding: 8,

    ...FONT_STYLES,
    fontSize: 13,
    color: '#FDFFFF60',
    path: {
      fill: '#FDFFFF',
      opacity: 0.2,
    },

    '&.active': {
      background: '#FDFFFF10',
      borderBottom: `3px solid ${red}`,
      color: '#FDFFFF',
      path: {
        opacity: 1,
      },
    },
  }),
);

export const MoreOptionsMobileLink = styled.span(
  mediaQuery({
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    width: '100%',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #ffffff10',
    gap: 16,

    ...FONT_STYLES,
    fontSize: 18,
    color: '#FDFFFF',

    path: {
      opacity: 1,
    },
  }),
);
