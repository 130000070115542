import {Box, Text, Image} from 'rebass';

import reviewImgSrc from '../img/google-review.svg';

import {EstimatePreviewPanel} from './styled';

// TODO check how to add dynamic reviews to email
const REVIEWS = [
  {
    author: 'Joshua Steiner',
    content: `RenoFi simplifies the entire loan process, in my case a HELOC.
        They do an amazing job of using modern technology to reduce all
        of the paper shuffling and effort required in scheduling meetings.
        When there were some issues with the underwriter, RenoFi took amazing
        ownership ensuring my concerns were completely resolved. I will definitely
        be using RenoFi again in the future.`,
  },
  {
    author: 'Andy Navratil',
    content: `RenoFi enabled us to finance our dream renovation considering the value of our proposed renovation.
      Their process is smooth and straightforward, and their advisors are available to assist you along the way.
      Highly recommend getting a quote from them before borrowing from somewhere else.`,
  },
];

const EstimateReviews = () => (
  <>
    <Text fontSize={24} textAlign="center" mt={41}>
      RenoFi has helped thousands of homeowners across the United States.
    </Text>
    <EstimatePreviewPanel css={{gap: 24}}>
      {REVIEWS.map((review, i) => (
        <Box key={i}>
          <Text>{review.content}</Text>

          <Text mt={16} fontWeight={700}>
            {review.author}
          </Text>
          <Image mt="4px" src={reviewImgSrc} />
        </Box>
      ))}
    </EstimatePreviewPanel>
  </>
);

export default EstimateReviews;
