import React from 'react';

import {noop} from 'lodash';

import {lightGray} from '@renofi/utils/src/colors';

const CopyIcon = ({onClick, color = lightGray, size = 16}) => {
  return (
    <svg
      onClick={onClick ? onClick : noop}
      style={{cursor: Boolean(onClick) ? 'pointer' : 'auto'}}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5263 6.31592H3.15788C2.32063 6.31684 1.51794 6.64984 0.925922 7.24187C0.333903 7.8339 0.000908221 8.6366 0 9.47384V16.8422C0.000908224 17.6795 0.333903 18.4822 0.925923 19.0742C1.51794 19.6662 2.32063 19.9992 3.15788 20.0002H10.5263C11.3636 19.9992 12.1662 19.6662 12.7583 19.0742C13.3503 18.4822 13.6833 17.6795 13.6842 16.8422V9.47384C13.6833 8.6366 13.3503 7.8339 12.7583 7.24187C12.1663 6.64984 11.3636 6.31684 10.5263 6.31592ZM11.5789 16.8422C11.5787 17.1213 11.4677 17.3889 11.2703 17.5863C11.073 17.7836 10.8054 17.8946 10.5263 17.8949H3.15788C2.87879 17.8946 2.61121 17.7836 2.41386 17.5863C2.21652 17.3889 2.10553 17.1213 2.10526 16.8422V9.47384C2.10553 9.19475 2.21652 8.92717 2.41387 8.72983C2.61121 8.53248 2.87879 8.42149 3.15788 8.42121H10.5263C10.8054 8.42148 11.073 8.53247 11.2703 8.72982C11.4677 8.92717 11.5787 9.19475 11.5789 9.47384V16.8422Z"
        fill={color}
      />
      <path
        d="M16.8417 0H9.47331C8.63607 0.000909893 7.83338 0.333907 7.24136 0.925927C6.64934 1.51795 6.31634 2.32064 6.31543 3.15788V4.21051C6.31543 4.34874 6.34266 4.48562 6.39556 4.61333C6.44845 4.74104 6.52599 4.85709 6.62374 4.95483C6.72148 5.05258 6.83752 5.13011 6.96523 5.18301C7.09294 5.23591 7.22982 5.26314 7.36805 5.26314C7.50629 5.26314 7.64317 5.23591 7.77088 5.18301C7.89859 5.13011 8.01463 5.05258 8.11237 4.95483C8.21012 4.85709 8.28765 4.74104 8.34055 4.61333C8.39345 4.48562 8.42068 4.34874 8.42068 4.21051V3.15788C8.42096 2.87879 8.53195 2.61121 8.72929 2.41387C8.92664 2.21652 9.19422 2.10553 9.47331 2.10526H16.8417C17.1208 2.10554 17.3884 2.21653 17.5857 2.41387C17.7831 2.61122 17.8941 2.87879 17.8944 3.15788V10.5263C17.8941 10.8054 17.7831 11.073 17.5857 11.2703C17.3884 11.4677 17.1208 11.5787 16.8417 11.5789H15.7891C15.5099 11.5789 15.2422 11.6898 15.0448 11.8872C14.8474 12.0847 14.7365 12.3524 14.7365 12.6316C14.7365 12.9107 14.8474 13.1785 15.0448 13.3759C15.2422 13.5733 15.5099 13.6842 15.7891 13.6842H16.8417C17.679 13.6833 18.4817 13.3503 19.0737 12.7583C19.6657 12.1662 19.9987 11.3636 19.9996 10.5263V3.15788C19.9987 2.32064 19.6657 1.51795 19.0737 0.925927C18.4817 0.333907 17.679 0.000909893 16.8417 0Z"
        fill={color}
      />
    </svg>
  );
};
export default CopyIcon;
