import React from 'react';

import {Flex, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {Alert, Button, Modal} from '@renofi/components';

import useIsQuestionnaireFinished from '../useIsQuestionnaireFinished';
import useShowFinancingReferral from '../useShowFinancingReferral';

import {modalCustomStyles} from './styled';

const QuestionnaireFinishedModal = () => {
  const {isQuestionnaireFinished, setIsQuestionnaireFinished} =
    useIsQuestionnaireFinished();
  const {setShowFinancingReferral} = useShowFinancingReferral();

  const onClose = () => {
    sendEvent('Contractors/QuestionnaireFinished/Cancel');
    setIsQuestionnaireFinished(false);
  };

  const onSubmit = () => {
    sendEvent('Contractors/QuestionnaireFinished/Financing-Referral-Form-Open');
    setIsQuestionnaireFinished(false);
    setShowFinancingReferral(true);
  };

  if (!isQuestionnaireFinished) {
    return null;
  }

  return (
    <Modal
      css={modalCustomStyles}
      onClose={onClose}
      show
      title="Thank you! Here’s what happens next...">
      <Flex css={{gap: 16}} flexDirection="column" alignItems="center" p={3}>
        <Alert info>
          Our team is working with the homeowner to complete preparation of
          their loan application. We will reach out with any further questions.
        </Alert>
        <Text>
          We welcome you to spread the word about RenoFi to your clients. Help
          them discover the smartest ways to finance bigger projects!
        </Text>
        <Button role="refer_homeowner_btn" onClick={onSubmit} small>
          Refer a homeowner
        </Button>
      </Flex>
    </Modal>
  );
};

export default QuestionnaireFinishedModal;
