import {useState, useContext, useMemo} from 'react';

import {Flex, Box, Text} from 'rebass';
import {omit, isNil} from 'lodash';

import {
  GET_DAAS_CLIENTS,
  useReferClientForDaas,
  useValidateEmail,
} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';
import {blueGray, blue, gray, isPhone} from '@renofi/utils';
import {
  Alert,
  Label,
  TextField,
  StatesField,
  PhoneField,
  Textarea,
  Button,
  useNotifications,
} from '@renofi/components';

import AppContext from '../../context';
import {Layout, EmailField} from '../../components';
import useDesignStatuses from '../useDesignStatuses';
import {LIMIT_VALUE} from '../constants';

import SubmissionCheckbox from './SubmissionCheckbox';

const FieldWrapper = ({children}) => (
  <Box width={['100%', 326]}>{children}</Box>
);

const EMPTY_DATA = {
  consentGiven: undefined,
  email: '',
  firstName: '',
  hasInterestInFinancing: false,
  lastName: '',
  phoneNumber: '',
  projectDetails: '',
  propertyState: '',
};
const ERROR_MSG = 'Field is required';
const IS_TEST = process.env.NODE_ENV === 'test';

const ClientSubmission = () => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [client, setClient] = useState(EMPTY_DATA);
  const [isDirty, setIsDirty] = useState(false);

  const {contractor} = useContext(AppContext);
  const {designStatuses} = useDesignStatuses();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const {loading, referClientForDaas} = useReferClientForDaas({
    refetchQueries: IS_TEST
      ? []
      : [
          {
            query: GET_DAAS_CLIENTS,
            variables: {
              contractorId: contractor?.id,
              limit: LIMIT_VALUE,
              status: designStatuses,
            },
          },
        ],
  });
  const addNotification = useNotifications();

  const errors = useMemo(() => {
    const clientKeys = Object.keys(client);
    if (!isDirty) {
      return [clientKeys];
    }

    return clientKeys.reduce((arr, key) => {
      const value = client[key];
      switch (key) {
        case 'consentGiven':
          return isNil(value) || !value ? arr.concat(key) : arr;
        case 'hasInterestInFinancing':
          return isNil(value) ? arr.concat(key) : arr;
        case 'phoneNumber':
          return Boolean(value) && !isPhone(value) ? arr.concat(key) : arr;
        default:
          return !value ? arr.concat(key) : arr;
      }
    }, []);
  }, [JSON.stringify(client), isDirty]);

  const onBlur = () => setIsDirty(true);

  const onChangeValue = (key) => (value) => {
    if (key === 'email') {
      setInvalidEmail(false);
    }
    setClient((s) => ({...s, [key]: value}));
  };

  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({
      variables: {email: client?.email},
    });

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    const {id: contractorId} = contractor;
    const variables = {client: omit(client, ['consentGiven']), contractorId};
    sendEvent('Contractors/Design-Referral-Form-Submit', variables);

    try {
      await referClientForDaas({variables});

      setInvalidEmail(false);
      setIsDirty(false);
      setClient(EMPTY_DATA);
    } catch (err) {
      addNotification({
        type: 'error',
        message: 'There was an issue sending this homeowner. Please try again',
      });
    }
  };

  return (
    <Layout.Panel css={{width: '100%'}} contentCss={{paddingBottom: 0}}>
      <Layout.PanelTitleV2>
        Request 3D Conceptual Designs for your client
      </Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Flex
          flexDirection="column"
          p={[16, 32]}
          css={{borderTop: `1px solid ${blueGray}`}}>
          <Alert info icon css={{margin: 0}}>
            Use the form below to request 3D Conceptual Designs for a client.
            You can track the status of the request in the table below.
          </Alert>

          <Text mt={[24, 32]} mb={16} fontSize={18} color={blue}>
            Enter the client’s details below
          </Text>

          <Flex css={{gap: 24}} flexWrap="wrap">
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="firstName">
                First name
              </Label>
              <TextField
                id="firstName"
                error={
                  isDirty && errors.includes('firstName') ? ERROR_MSG : null
                }
                value={client?.firstName}
                onChange={onChangeValue('firstName')}
                onBlur={onBlur}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="lastName">
                Last name
              </Label>
              <TextField
                id="lastName"
                error={
                  isDirty && errors.includes('lastName') ? ERROR_MSG : null
                }
                value={client?.lastName}
                onChange={onChangeValue('lastName')}
                onBlur={onBlur}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="propertyState">
                State
              </Label>
              <StatesField
                id="propertyState"
                customError
                error={
                  isDirty && errors.includes('propertyState') ? ERROR_MSG : null
                }
                value={client?.propertyState}
                onChange={onChangeValue('propertyState')}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="email">
                Email
              </Label>
              <EmailField
                id="email"
                disabled={loading || loadingValidateEmail}
                error={isDirty && errors.includes('email') ? ERROR_MSG : null}
                placeholder={null}
                value={client?.email}
                onChange={onChangeValue('email')}
                onBlur={onBlur}
                invalidEmail={invalidEmail}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label fontSize={14} color={gray} htmlFor="phoneNumber">
                Phone number
              </Label>
              <PhoneField
                id="phoneNumber"
                topMessage="optional"
                stripCountryCode
                error={
                  client?.phoneNumber && errors.includes('phoneNumber')
                    ? 'Incorrect phone number'
                    : null
                }
                topMessageColor={blue}
                value={client?.phoneNumber}
                onChange={onChangeValue('phoneNumber')}
                onBlur={onBlur}
              />
            </FieldWrapper>
            <Box width={['100%', 678]}>
              <Label fontSize={14} color={gray} htmlFor="projectDetails">
                Enter a brief description of the project
              </Label>
              <Textarea
                height={143}
                id="projectDetails"
                error={
                  isDirty && errors.includes('projectDetails')
                    ? ERROR_MSG
                    : null
                }
                value={client?.projectDetails}
                onChange={onChangeValue('projectDetails')}
                onBlur={onBlur}
              />
            </Box>

            <SubmissionCheckbox
              id="consentGiven"
              name="consentGiven"
              checked={client?.consentGiven}
              onChange={onChangeValue('consentGiven')}>
              I have obtained my client’s consent to share their contact info
              with RenoFi.
            </SubmissionCheckbox>

            <SubmissionCheckbox
              id="hasInterestInFinancing"
              name="hasInterestInFinancing"
              checked={client?.hasInterestInFinancing}
              onChange={onChangeValue('hasInterestInFinancing')}>
              I would also like RenoFi to contact this client about financing
              options for their renovation.
            </SubmissionCheckbox>
          </Flex>
        </Flex>
        <Layout.PanelCTAWrapper>
          <Button
            disabled={loading || Boolean(errors?.length)}
            loading={loading}
            onClick={onSubmit}>
            Request designs
          </Button>
        </Layout.PanelCTAWrapper>
      </Layout.PanelInnerWrapper>
    </Layout.Panel>
  );
};

export default ClientSubmission;
