import {useMemo} from 'react';

import {sortBy} from 'lodash';

import {useContractorProjectsV2, useFinancingClients} from '@renofi/api';

export default () => {
  const financing = useFinancingClients({lazy: true, variables: {limit: 50}});
  const financingClients = financing?.collection || [];
  const {
    fetch: fetchProjects,
    loading,
    projects,
    refetch,
  } = useContractorProjectsV2({
    lazy: true,
  });

  const data = useMemo(() => {
    return sortBy([...projects, ...financingClients], ['updatedAt']).reverse();
  }, [JSON.stringify(financingClients), JSON.stringify(projects)]);

  return {
    data,
    fetchProjects,
    projects,
    loading,
    refetch,
  };
};
