import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {REQUEST_PASSWORD_RESET} from '../mutations';

export default function useRequestPasswordReset({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [requestPasswordReset, {loading, error}] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onCompleted,
      refetchQueries,
    },
  );

  return {requestPasswordReset, loading, error};
}
