import useToggledQuery from '../../useToggledQuery';
import {GET_CONSOLIDATED_ENTITIES} from '../queries';

export default function useConsolidatedEntities({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, fetch, loading, refetch, previousData} = useToggledQuery(
    GET_CONSOLIDATED_ENTITIES,
    {
      lazy,

      fetchPolicy,
      ...options,
    },
  );
  const {collection = [], metadata} = data?.consolidatedEntities || {};

  return {collection, fetch, loading, metadata, previousData, refetch};
}
