import {Flex, Text} from 'rebass';

import {blue, blueGray} from '@renofi/utils';

const SubmissionSection = ({step, label, children, css, ...props}) => (
  <Flex
    backgroundColor="white"
    flex={1}
    flexDirection="column"
    padding={[16, 32]}
    css={{
      border: `1px solid ${blueGray}`,
      ...css,
    }}
    {...props}>
    <Flex>
      <Text flexShrink={0} mb={[16, 24]}>
        Step {step}.{' '}
      </Text>
      <Text ml={12} display="inline" color={blue}>
        {label}
      </Text>
    </Flex>
    {children}
  </Flex>
);

export default SubmissionSection;
