import {useContext} from 'react';

import {Flex, Text, Link} from 'rebass';

import {darkBlue, blue, copyToClipboard} from '@renofi/utils';
import {Alert, CopyIcon} from '@renofi/components';

import AppContext from '../../context';
import {getUrlBySlug} from '../utils';
import {UserGuide} from '../../components';
import userGuideImg from '../img/renofi_design_call.png';

const FirstStep = ({url}) => (
  <Flex flexDirection="column" css={{gap: 10}}>
    <Text fontSize={16}>
      Share the link to your <Link href="/marketing">co-branded</Link> RenoFi
      Design webpage with your clients.
    </Text>
    <Alert
      info
      onClick={() => copyToClipboard(url)}
      css={{'svg path': null, margin: 0, '&:hover': {cursor: 'pointer'}}}>
      <Flex alignItems="center">
        <CopyIcon size={20} color={blue} />
        <Text ml="8px" fontSize={16} color={blue}>
          Click to copy the link
        </Text>
      </Flex>
    </Alert>
    <Link href={url} target="_blank">
      {url}
    </Link>
  </Flex>
);

const DesignUserGuide = () => {
  const {contractor} = useContext(AppContext);
  const contractorUrl = getUrlBySlug({slug: contractor.urlSlug});

  const GUIDE_STEPS = [
    <FirstStep url={contractorUrl} />,
    <Text>
      Request a consultation call on behalf of a client using the form below.
    </Text>,
  ];
  return (
    <UserGuide
      headline="Getting started with RenoFi Designs"
      description="Perfect for homeowners who want to renovate but don't yet have clarity of scope and/or budget for their project."
      buttonText="Find out more about renofi designs"
      onClick={() =>
        window.open(process.env.REACT_APP_RENOFI_DESIGN_PREVIEW_URL, '_blank')
      }
      imgSrc={userGuideImg}
      stepsHeading={
        <Text fontSize={24} color={darkBlue}>
          Two ways to start using our Design service.
        </Text>
      }
      steps={GUIDE_STEPS}
    />
  );
};

export default DesignUserGuide;
