const PDFIcon = ({size = 20, color = '#212F49'}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none">
    <mask
      id="mask0_1500_25057"
      style={{maskType: 'alpha'}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={size}
      height={size}>
      <rect width={size} height={size} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1500_25057)">
      <path
        d="M15.0004 17.5003L18.3337 14.167L17.1671 13.0003L15.8337 14.3337V10.8545H14.1671V14.3337L12.8337 13.0003L11.6671 14.167L15.0004 17.5003ZM11.6671 20.0003V18.3337H18.3337V20.0003H11.6671ZM5.00041 16.667C4.54207 16.667 4.14971 16.5038 3.82332 16.1774C3.49693 15.851 3.33374 15.4587 3.33374 15.0003V3.33366C3.33374 2.87533 3.49693 2.48296 3.82332 2.15658C4.14971 1.83019 4.54207 1.66699 5.00041 1.66699H10.8337L15.8337 6.66699V9.18783H14.1671V7.50033H10.0004V3.33366H5.00041V15.0003H10.0004V16.667H5.00041Z"
        fill={color}
      />
    </g>
  </svg>
);

export default PDFIcon;
