import {Flex, Text, Image} from 'rebass';

import {darkBlue, gray, useScreenSize} from '@renofi/utils';
import {Button, CloseIcon} from '@renofi/components';

import {Layout} from '..';

import {UserGuideFlex, UserGuideFlexItem, CloseButton} from './styled';

const PreviewButton = ({children, onClick}) => (
  <Button secondary xSmall css={{marginTop: 20}} onClick={onClick}>
    {children}
  </Button>
);

const UserGuide = ({
  headline,
  description,
  buttonText,
  onClick,
  imgSrc,
  stepsHeading,
  steps = [],
  closeable,
  closed,
  onClose,
}) => {
  const {isMobile} = useScreenSize();

  if (closed) return null;

  return (
    <>
      <Layout.Panel contentCss={{padding: [16, 32]}}>
        <UserGuideFlex>
          <UserGuideFlexItem
            mt={[24, 0]}
            maxWidth={336}
            textAlign={['center', 'left']}
            flex={1}>
            <Text color={darkBlue} fontSize={[28, 32]} lineHeight="normal">
              {headline}
            </Text>
            {description && <Text mt={20}>{description}</Text>}
            {!isMobile && (
              <PreviewButton onClick={onClick}>{buttonText}</PreviewButton>
            )}
          </UserGuideFlexItem>
          <UserGuideFlexItem>
            <Image height={235} src={imgSrc} />
            {isMobile && (
              <PreviewButton onClick={onClick}>{buttonText}</PreviewButton>
            )}
          </UserGuideFlexItem>
          <UserGuideFlexItem mt={[20, 0]} flex={1}>
            {stepsHeading}
            <Flex flexDirection="column" alignItems="start">
              {steps.map((step, i) => (
                <Text display="flex" key={i} mt={['16px', '12px']}>
                  <Text
                    display="inline"
                    fontWeight={[700, 400]}
                    color={[darkBlue, gray]}
                    mr={['10px', '8px']}>
                    {i + 1}.
                  </Text>
                  {step}
                </Text>
              ))}
            </Flex>
          </UserGuideFlexItem>
          {closeable && (
            <CloseButton onClick={onClose} role="close">
              <CloseIcon size={14} fill={gray} />
            </CloseButton>
          )}
        </UserGuideFlex>
      </Layout.Panel>
    </>
  );
};

export default UserGuide;
